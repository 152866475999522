
import { ScrollIndicatorPage } from './scroll';
import Image from "react-image-enlarger";
import { useState } from 'react';
import { ArrowRightCircleIcon,ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next';
import './imager.css';
import '../browser/dark.css';
import { CheckIcon } from '@heroicons/react/20/solid'
import {Link} from 'react-router-dom';
import { Glide } from 'react-glide';
import 'react-glide/lib/reactGlide.css'
import ImageZoom from 'react-medium-image-zoom';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import styled from 'styled-components';
import Modal from 'react-modal';

////////////////PRODUCT PACKAGE DESING IMAGES////////////////
import product_packaging_image_1 from './images/portfolio/PRODUCT PACKAGING/1.png'
import product_packaging_image_2 from './images/portfolio/PRODUCT PACKAGING/2.jpg'
import product_packaging_image_3 from './images/portfolio/PRODUCT PACKAGING/3.jpg'
import product_packaging_image_4 from './images/portfolio/PRODUCT PACKAGING/4.jpg'
import product_packaging_image_5 from './images/portfolio/PRODUCT PACKAGING/5.png'
import product_packaging_image_6 from './images/portfolio/PRODUCT PACKAGING/6.jpg'
import product_packaging_image_7 from './images/portfolio/PRODUCT PACKAGING/7.jpg'
import product_packaging_image_8 from './images/portfolio/PRODUCT PACKAGING/8.jpg'
import product_packaging_image_9 from './images/portfolio/PRODUCT PACKAGING/9.jpg'
import product_packaging_image_10 from './images/portfolio/PRODUCT PACKAGING/10.jpg'
import product_packaging_image_11 from './images/portfolio/PRODUCT PACKAGING/11.jpg'
import product_packaging_image_12 from './images/portfolio/PRODUCT PACKAGING/12.jpg'
import product_packaging_image_13 from './images/portfolio/PRODUCT PACKAGING/13.jpg'
import product_packaging_image_14 from './images/portfolio/PRODUCT PACKAGING/14.jpg'
import product_packaging_image_15 from './images/portfolio/PRODUCT PACKAGING/15.jpg'
import product_packaging_image_16 from './images/portfolio/PRODUCT PACKAGING/16.jpg'
import product_packaging_image_17 from './images/portfolio/PRODUCT PACKAGING/17.jpg'
import product_packaging_image_18 from './images/portfolio/PRODUCT PACKAGING/18.jpg'
import product_packaging_image_19 from './images/portfolio/PRODUCT PACKAGING/19.jpg'
import product_packaging_image_20 from './images/portfolio/PRODUCT PACKAGING/20.jpg'
import product_packaging_image_21 from './images/portfolio/PRODUCT PACKAGING/21.jpg'
import product_packaging_image_22  from './images/portfolio/PRODUCT PACKAGING/22.png'
import product_packaging_image_23 from './images/portfolio/PRODUCT PACKAGING/23.jpg'
import product_packaging_image_24 from './images/portfolio/PRODUCT PACKAGING/24.jpg'
import product_packaging_image_25 from './images/portfolio/PRODUCT PACKAGING/25.jpg'
import product_packaging_image_26 from './images/portfolio/PRODUCT PACKAGING/26.jpg'
import product_packaging_image_27 from './images/portfolio/PRODUCT PACKAGING/27.jpg'
import product_packaging_image_28 from './images/portfolio/PRODUCT PACKAGING/28.jpg'
import product_packaging_image_29 from './images/portfolio/PRODUCT PACKAGING/29.jpg'
import product_packaging_image_30 from './images/portfolio/PRODUCT PACKAGING/30.jpg'
import product_packaging_image_31 from './images/portfolio/PRODUCT PACKAGING/31.jpg'
import product_packaging_image_32 from './images/portfolio/PRODUCT PACKAGING/32.jpg'
import product_packaging_image_33 from './images/portfolio/PRODUCT PACKAGING/33.jpg'
import product_packaging_image_34 from './images/portfolio/PRODUCT PACKAGING/34.jpg'
import product_packaging_image_35 from './images/portfolio/PRODUCT PACKAGING/35.jpg'
import product_packaging_image_36 from './images/portfolio/PRODUCT PACKAGING/36.jpg'
import product_packaging_image_37 from './images/portfolio/PRODUCT PACKAGING/37.jpg'
import product_packaging_image_38 from './images/portfolio/PRODUCT PACKAGING/38.jpg'
import product_packaging_image_39 from './images/portfolio/PRODUCT PACKAGING/39.jpg'
import product_packaging_image_40 from './images/portfolio/PRODUCT PACKAGING/40.png'
import product_packaging_image_41 from './images/portfolio/PRODUCT PACKAGING/41.jpg'
import product_packaging_image_42 from './images/portfolio/PRODUCT PACKAGING/42.jpg'
import product_packaging_image_43 from './images/portfolio/PRODUCT PACKAGING/43.jpg'
import product_packaging_image_44 from './images/portfolio/PRODUCT PACKAGING/44.jpg'
import product_packaging_image_45 from './images/portfolio/PRODUCT PACKAGING/45.jpg'
import product_packaging_image_46 from './images/portfolio/PRODUCT PACKAGING/46.jpg'
import product_packaging_image_47 from './images/portfolio/PRODUCT PACKAGING/47.jpg'
import product_packaging_image_48 from './images/portfolio/PRODUCT PACKAGING/48.jpg'
import product_packaging_image_49 from './images/portfolio/PRODUCT PACKAGING/49.jpg'
import product_packaging_image_50 from './images/portfolio/PRODUCT PACKAGING/50.jpg'
import product_packaging_image_51 from './images/portfolio/PRODUCT PACKAGING/51.jpg'
import product_packaging_image_52 from './images/portfolio/PRODUCT PACKAGING/52.jpg'
import product_packaging_image_53 from './images/portfolio/PRODUCT PACKAGING/53.jpg'
import product_packaging_image_54 from './images/portfolio/PRODUCT PACKAGING/54.jpg'
import product_packaging_image_55 from './images/portfolio/PRODUCT PACKAGING/55.jpg'
import product_packaging_image_56 from './images/portfolio/PRODUCT PACKAGING/56.jpg'
import product_packaging_image_57 from './images/portfolio/PRODUCT PACKAGING/57.jpg'
import product_packaging_image_58 from './images/portfolio/PRODUCT PACKAGING/58.jpg'
import product_packaging_image_59 from './images/portfolio/PRODUCT PACKAGING/59.jpg'
import product_packaging_image_60 from './images/portfolio/PRODUCT PACKAGING/60.jpg'
import product_packaging_image_61 from './images/portfolio/PRODUCT PACKAGING/61.jpg'
import product_packaging_image_62 from './images/portfolio/PRODUCT PACKAGING/62.jpg'
import product_packaging_image_63 from './images/portfolio/PRODUCT PACKAGING/63.jpg'
import product_packaging_image_64 from './images/portfolio/PRODUCT PACKAGING/64.png'
import product_packaging_image_65 from './images/portfolio/PRODUCT PACKAGING/65.jpg'
import product_packaging_image_66 from './images/portfolio/PRODUCT PACKAGING/66.jpg'
import product_packaging_image_67 from './images/portfolio/PRODUCT PACKAGING/67.jpg'
import product_packaging_image_68 from './images/portfolio/PRODUCT PACKAGING/68.jpg'
import product_packaging_image_69 from './images/portfolio/PRODUCT PACKAGING/69.jpg'
import product_packaging_image_70 from './images/portfolio/PRODUCT PACKAGING/70.jpg'
import product_packaging_image_71 from './images/portfolio/PRODUCT PACKAGING/71.jpg'
import product_packaging_image_72 from './images/portfolio/PRODUCT PACKAGING/72.jpg'
import product_packaging_image_73 from './images/portfolio/PRODUCT PACKAGING/73.png'
import product_packaging_image_74 from './images/portfolio/PRODUCT PACKAGING/74.jpg'
import product_packaging_image_75 from './images/portfolio/PRODUCT PACKAGING/75.jpg'
import product_packaging_image_76 from './images/portfolio/PRODUCT PACKAGING/76.jpg'
import product_packaging_image_77 from './images/portfolio/PRODUCT PACKAGING/77.jpg'
import product_packaging_image_78 from './images/portfolio/PRODUCT PACKAGING/78.jpg'
import product_packaging_image_81 from './images/portfolio/packaging/81.jpg'
import product_packaging_image_82 from './images/portfolio/packaging/82.jpg'
import product_packaging_image_83 from './images/portfolio/packaging/83.jpg'
import product_packaging_image_84 from './images/portfolio/packaging/84.jpg'
import product_packaging_image_85 from './images/portfolio/packaging/85.jpg'
import product_packaging_image_86 from './images/portfolio/packaging/86.jpg'
import product_packaging_image_87 from './images/portfolio/packaging/87.jpg'


const FullScreenImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  background-color: transparent;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 3px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: black;
  cursor: pointer;
`;

const includedFeatures = [
  'Source file',
  'Commercial use',
  'Conduct research',
  'Data points included',
  'Revisions',
  'Delivery Time'

]
const features = [
  {
    name: 'Amazon product photography editing',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Background removal/changing',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Product image retouch',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Unique product Infographics',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Removing dust or any kind of scratches/spots',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Before/After Images',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Infographics images (shows the product`s features)',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Professional product listing for AMAZON, EBAY, ALIBABA',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Lifestyle photo editing ',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Add logo,Badges,Custom icon etc.',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Comparison charts',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Photo retouching/editing ',
    icon: ArrowRightCircleIcon,
  },
]

function Packaging_Design()
{
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState(null);
  const [showReviewProductPhotography,setshowReviewProductPhotography]=useState(false);
  const changer =()=>
  {
    if(showReviewProductPhotography===true){
        setshowReviewProductPhotography(false);
        return;
    }
    else{
    setshowReviewProductPhotography(true);
    return;
  }
}
  const handleImageClick = (event) => {
    setSelectedImageSrc(event.target.src);
    setModalIsOpen(true);
  }
  
  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent default behavior of the right-click event
  };
  const handleCloseModal = () => {
    setModalIsOpen(false);
  }

  const { t } = useTranslation('translation');
  const { t: currencyT } = useTranslation('currency');


    return(
        <>
        <ScrollIndicatorPage/>
 
        <ScrollIndicatorPage/>
<Modal
  isOpen={modalIsOpen}
  onRequestClose={handleCloseModal}
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    content: {
    //   position: 'absolute',
      top: '55%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '100%',
      maxHeight: '100%',
      width: '639.5px', // added
      height: '433.5px', // added
      padding: '0px',
      border: 'none',
      borderRadius: '0',
      background: 'transparent' // added

    },
  }}
  
>
  <CloseButton  onClick={handleCloseModal}>X</CloseButton>
  <FullScreenImage onContextMenu={handleContextMenu} src={selectedImageSrc} />
</Modal>

        <div className="bg-white py-12 sm:py-18">
          
      <div className="mx-auto max-w-8xl px-0 lg:px-0">
        <div className="mx-auto max-w-5xl lg:text-center">
        <h2 className="text-base font-semibold leading-7 text-orange-500">Grow<span className='black-color'>phics</span></h2>
          <p className="mt-0 text-3xl font-bold tracking-tight text-orange-400 sm:text-4xl">
            {t("Packaging Design")}  
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600 justifier">
          {t("Product_Packaging_Service")}
          </p>
        </div>
        <div className="mx-auto mt-4 max-w-2xl sm:mt-20 lg:mt-20 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-orange-400">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>

    <div className="bg-changer py-24 sm:py-8" id="PP">
      <br/>
    <div className="mx-auto max-w-7xl px-8 lg:px-18">
  <h2 className="text-center portfolio_headings font-semibold leading-8 text-orange-400">
  {t("PRODUCT PACKAGING DESIGN")}
  </h2>

  <br></br>
  {/* <p className='text-orange-400 font-semibold'> {t("PRODUCT PACKAGING DESIGN Description")}</p> */}
          {/* EDITING PP */}
  <br/>
  <button onClick={()=>changer()} className="color_code_button inline-block px-7 py-3 font-medium text-sm leading-snug uppercase rounded shadow-md  hover:shadow-lg  active:shadow-lg transition duration-150 ease-in-out">
      {showReviewProductPhotography ? <><span>{t("Show Less")}</span> </>: <span>{t("Show All")}</span>}
    </button>
  <div className="mx-auto ">
<br/>
<div className="flex items-center justify-center w-full h-full py-48 sm:py-8 px-4 bg-gray-900 container_border">
{/* FOR WEB */}
{showReviewProductPhotography ?
<>
<div className='flex flex-col pp_height overflow-x-auto mb-4 '>
<span>Scroll Down to view more</span>
 <div className="mt-2 grid grid-cols-1 gap-x-2 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8 ">
    {products.map((product) => (
      <div key={product.id} className="group relative">
        <div className="min-h-40 aspect-h-24 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-full">
          <img onContextMenu={handleContextMenu}
              onClick={handleImageClick}
            src={product.src}
            className="h-full w-full object-cover object-center lg:h-full lg:w-full"
          />
        </div>
      </div>
    ))}
  </div>
  </div>
</>
:
<>
<CarouselProvider className="lg:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={10} visibleSlides={4} step={1} infinite={true}>
  <div className="w-full relative flex items-center justify-center">
  {!modalIsOpen && (
    <>
      <ButtonBack 
                    role="button" 
                    aria-label="slide backward" 
                    className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                    id="prev"
                  >
                      <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                  </ButtonBack>
      </>
  )}
  
      <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">

          <Slider>
              <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                  <Slide index={0}>
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src={product_packaging_image_1}
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>

                  <Slide index={1}>
                      
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src={product_packaging_image_86}
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>

                  <Slide index={2}>                                       
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src={product_packaging_image_3}
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>
                  <Slide index={3}>                                       
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src={product_packaging_image_4}
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>
                  <Slide index={4}>                                       
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src={product_packaging_image_5}
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>
                  <Slide index={5}>                                       
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src={product_packaging_image_87}
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>
                  <Slide index={6}>                                       
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src={product_packaging_image_7}
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>
                  <Slide index={7}>                                       
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src={product_packaging_image_8}
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>
                  <Slide index={8}>                                       
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src={product_packaging_image_9}
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>
                  <Slide index={9}>                                       
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src={product_packaging_image_10}
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>
        
              </div>
          </Slider>
      </div>
      
      {!modalIsOpen && (
    <>
   <ButtonNext role="button" aria-label="slide forward" 
                    className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                  >
                      <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      
                  </ButtonNext>
      </>
  )}

  </div>
  

</CarouselProvider>
{/* FOR TAB */}
<CarouselProvider className="lg:hidden md:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={12} visibleSlides={2} step={1} infinite={true}>
<div className="w-full relative flex items-center justify-center">
       {!modalIsOpen && (
    <>
      <ButtonBack 
                    role="button" 
                    aria-label="slide backward" 
                    className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                    id="prev"
                  >
                      <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                  </ButtonBack>
      </>
  )}
      <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
          <Slider>
              <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                  <Slide index={0}>
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/171664213/original/929084264b492316c64db559bd958386dfe7eb5c/do-amazon-product-photography-editing-product-infographic.png"
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>

                  <Slide index={1}>
                      
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs3/171664213/original/c3837cc7268a8645530273b3f301d044c3a01a5c/do-amazon-product-photography-editing-product-infographic.png"
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>

                  <Slide index={2}>                                       
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src="https://fiverr-res.cloudinary.com/images/t_smartwm/t_gig_pdf_gallery_view_ver4,q_auto,f_auto/attachments/delivery/asset/8585d444974aec39b18e811cb1cbba6b-1679825598/Preview-Eldotrado-Pdf/do-amazon-product-photography-editing-product-infographic.pdf"
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>
        
              </div>
          </Slider>
      </div>
       {!modalIsOpen && (
    <>
   <ButtonNext role="button" aria-label="slide forward" 
                    className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                  >
                      <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      
                  </ButtonNext>
      </>
  )}
  </div>
</CarouselProvider>
{/* FOR MOBILE */}
<CarouselProvider className="block md:hidden " naturalSlideWidth={20} isIntrinsicHeight={true} totalSlides={3} visibleSlides={1} step={1} infinite={true}>
<div className="w-full relative flex items-center justify-center">
       {!modalIsOpen && (
    <>
      <ButtonBack 
                    role="button" 
                    aria-label="slide backward" 
                    className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                    id="prev"
                  >
                      <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                  </ButtonBack>
      </>
  )}
      <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
          <Slider>
              <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                  <Slide index={0}>
                      <div className="flex flex-shrink-0 relative w-full sm:w-full">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/171664213/original/929084264b492316c64db559bd958386dfe7eb5c/do-amazon-product-photography-editing-product-infographic.png"
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>

                  <Slide index={1}>
                      
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs3/171664213/original/c3837cc7268a8645530273b3f301d044c3a01a5c/do-amazon-product-photography-editing-product-infographic.png"
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>

                  <Slide index={2}>                                       
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src="https://fiverr-res.cloudinary.com/images/t_smartwm/t_gig_pdf_gallery_view_ver4,q_auto,f_auto/attachments/delivery/asset/8585d444974aec39b18e811cb1cbba6b-1679825598/Preview-Eldotrado-Pdf/do-amazon-product-photography-editing-product-infographic.pdf"
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>
        
              </div>
          </Slider>
      </div>
       {!modalIsOpen && (
    <>
   <ButtonNext role="button" aria-label="slide forward" 
                    className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                  >
                      <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      
                  </ButtonNext>
      </>
  )}
  </div>
</CarouselProvider>
</>


}
</div>

</div>
</div>
      <br></br>
    </div>
    <div className="bg-white py-24 sm:py-8 mobile-packages-hide">
    <h2 className="text-3xl font-bold tracking-tight text-orange-400 sm:text-4xl text-center">Compare packages</h2>
              <br/>
              <br/>
              <br/>
      <div className=" mx-auto max-w-7xl px-8 lg:px-18"></div>
    <div className="text-black compare-table">
  <table>
    <thead>
      <tr>
        <th>Package</th>
        <th className='thx'>Basic</th>
        <th className='thx'>Standard</th>
        <th className='thx'>Premium</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td></td>
        <td>36.25$</td>
        <td>51.79$</td>
        <td>77.68$</td>
      </tr>
      <tr>
        <td></td>
        <td><b>SILVER (SINGLE-SIDED <br/>BOX DESIGN)</b></td>
        <td><b>GOLD  (LABEL DESIGN)</b></td>
        <td><b>DIAMOND (5-7 IMAGES <br/>INFO-GRAPHICS)</b></td>

      </tr>
      <tr>
        <td></td>
        <td>Source File (AI,PSD)+<br/>Dielines + Print-<br/> Ready + Unlimited<br/> Revision + JPG/PNG</td>
        <td>Source File (AI,PSD)+<br/>Dielines + Print-<br/> Ready + Unlimited<br/> Revision + JPG/PNG</td>
        <td>Source File (AI,PSD)+<br/>Dielines + Print-<br/> Ready + Unlimited<br/> Revision + Full <br/> Product Wrap +<br/>JPG/PNG</td>
        
      </tr>
      <tr>
        <td>Full product wrapping</td>
        <td><CheckIcon className="h-6 w-5 flex-none text-grey-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-grey-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
      </tr>
      <tr>
        <td>Print-ready</td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
      </tr>
      <tr>
        <td>Dielines</td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
      </tr>
      <tr>
        <td>3D mockup</td>
        <td><CheckIcon className="h-6 w-5 flex-none text-grey-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
      </tr>
      <tr>
        <td>Source file</td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
      </tr>

      <tr>
        <td>Revisions</td>
        <td>Unlimited</td>
        <td>Unlimited</td>
        <td>Unlimited</td>
      </tr>
      <tr>
        <td>Delivery Time</td>
        <td>1 day</td>
        <td>1 day</td>
        <td>
        <div>
  <label className="inline-flex">
    <input type="radio" className="form-radio h-4 w-4 text-orange-400" name="deliveryTime" value="1 day" />
    <span className="ml-2 text-sm font-medium text-gray-700">1 day ( + 62.14$)</span>
  </label>
  <br/>
  <label className="inline-flex">
    <input type="radio" className="form-radio h-4 w-4 text-orange-400" name="deliveryTime" value="2 days" />
    <span className="ml-2 text-sm font-medium text-gray-700">2 days</span>
  </label>
</div>
        </td>
      </tr>
      <tr>
        <td></td>
        <td><button className='btn bg-orange-400 text-white hover:bg-orange-500'>Select</button></td>
        <td><button className='btn bg-orange-400 text-white hover:bg-orange-500'>Select</button></td>
        <td><button className='btn bg-orange-400 text-white hover:bg-orange-500'>Select</button></td>
   
      </tr>
    </tbody>
  </table>
</div>
</div>

    <div className="bg-white py-24 sm:py-8 big-packages">
      <div className="mx-auto max-w-7xl px-8 lg:px-18">
      <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="hover1 mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Basic</h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
            Source File (AI,PSD)+ Dielines + Print-Ready + Unlimited Revision + JPG/PNG            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-orange-400">What’s included</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.includes("Source file") && (
                <li className="flex gap-x-3">
                <CheckIcon className="h-6 w-5 flex-none text-gray-500" aria-hidden="true" />
                Full product wrapping
              </li>
              )}
              {includedFeatures.includes("Commercial use") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Print-ready                </li>
              )}
              {includedFeatures.includes("Commercial use") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-grey-500" aria-hidden="true" />
                  3D mockup
                </li>
              )}
              {includedFeatures.includes("Conduct research") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Dielines
                </li>
              )}
              {includedFeatures.includes("Revisions") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Revision : <span className='text-orange-400 text-bold'>Unlimited</span>
                </li>
              )}
              {includedFeatures.includes("Delivery Time") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Delivery Time : <span className='text-orange-400 text-bold'>1 day</span>
                </li>
              )}
                          </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">Pay once, own it forever</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                <span className="text-5xl font-bold tracking-tight text-gray-900">36.25</span>
                  <span className="text-5xl font-semibold leading-6 tracking-wide text-gray-600">$</span>
                </p>
                <Link
                  to="/payment"
                  className="mt-10 block w-full rounded-md bg-orange-400 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Buy
                </Link>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  Invoices and receipts available for easy company reimbursement
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="hover1 mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-8 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Standard</h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
            Source File (AI,PSD)+ Dielines + Print-Ready + Unlimited Revision + JPG/PNG            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-orange-400">What’s included</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
                {includedFeatures.includes("Source file") && (
                <li className="flex gap-x-3">
                <CheckIcon className="h-6 w-5 flex-none text-gray-500" aria-hidden="true" />
                Full product wrapping
              </li>
              )}
              {includedFeatures.includes("Commercial use") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Print-ready                </li>
              )}
              {includedFeatures.includes("Commercial use") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  3D mockup
                </li>
              )}
              {includedFeatures.includes("Conduct research") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Dielines
                </li>
              )}
              {includedFeatures.includes("Revisions") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Revision : <span className='text-orange-400 text-bold'>Unlimited</span>
                </li>
              )}
              {includedFeatures.includes("Delivery Time") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Delivery Time : <span className='text-orange-400 text-bold'>1 day</span>
                </li>
              )}
                          </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">Pay once, own it forever</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">	51.79</span>
                  <span className="text-5xl font-semibold leading-6 tracking-wide text-gray-600">$</span>
                </p>
                <Link
                  to="/payment"
                  className="mt-10 block w-full rounded-md bg-orange-400 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Buy
                </Link>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  Invoices and receipts available for easy company reimbursement
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="hover1 mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-8 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Premium</h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
            Source File (AI,PSD)+ Dielines + Print-Ready + Unlimited Revision + Full Product Wrap + JPG/PNG            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-orange-400">What’s included</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
                {includedFeatures.includes("Source file") && (
                <li className="flex gap-x-3">
                <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                Full product wrapping
              </li>
              )}
              {includedFeatures.includes("Commercial use") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Print-ready                </li>
              )}
              {includedFeatures.includes("Commercial use") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  3D mockup
                </li>
              )}
              {includedFeatures.includes("Conduct research") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Dielines
                </li>
              )}
              {includedFeatures.includes("Revisions") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Revision : <span className='text-orange-400 text-bold'>Unlimited</span>
                </li>
              )}
              {includedFeatures.includes("Delivery Time") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-8 flex-none text-orange-400" aria-hidden="true" />
                  Delivery Time : <span className='text-orange-400 text-bold'></span>
                  
                  <div>
  <label className="inline-flex">
    <input type="radio" className="form-radio h-4 w-4 text-orange-400" name="deliveryTime" value="1 day" />
    <span className="ml-2 text-sm font-medium text-gray-700">1 day ( + 62.14$)</span>
  </label>
  <br/>
  <label className="inline-flex">
    <input type="radio" className="form-radio h-4 w-4 text-orange-400" name="deliveryTime" value="2 days" />
    <span className="ml-2 text-sm font-medium text-gray-700">2 days</span>
  </label>
</div>

                </li>
              )}
                          </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">Pay once, own it forever</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                <span className="text-5xl font-bold tracking-tight text-gray-900">77.68</span>
                  <span className="text-5xl font-semibold leading-6 tracking-wide text-gray-600">$</span>
                </p>
                <Link
                  to="/payment"
                  className="mt-10 block w-full rounded-md bg-orange-400 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Buy
                </Link>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  Invoices and receipts available for easy company reimbursement
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        </div>
        </div>



<section class="text-gray-600 body-font">
  <div class="container px-10 py-2 mx-auto">
  <h1 class="sm:text-5xl text-3xl font-bold text-center title-font text-orange-400 mb-4">{t("Packagin Design FAQs")}</h1>
<br></br>
<br></br>
<br></br>
     <div className="accordion" id="accordionExample">
        <div className="accordion-item bg-white border border-gray-200">
          <h2 className="accordion-header mb-0" id="headingOne">
            <button className="
        accordion-button
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
               <b> What i will need to provide you?</b>
            </button>
          </h2>
          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div className="accordion-body py-4 px-5">
You will require to provide the accurate size in inches/centimeters, all the content (text/images) that you want to incorporate in your packaging box design, any design reference that you like or even some bullet points on the design will be of great help
</div>          </div>
        </div>
        <div className="accordion-item bg-white border border-gray-200">
          <h2 className="accordion-header mb-0" id="headingTwo">
            <button className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        <b>How its Work?</b></button>
          </h2>
          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div className="accordion-body py-4 px-5">

            Our process is simple, we will first work on your provided requirements -> provide you with a draft -> work around your feedback to improve the draft and once the design is finalized by you, we will send you all high DPI files and editable source files.

            </div>
          </div>
        </div>
        <div className="accordion-item bg-white border border-gray-200">
          <h2 className="accordion-header mb-0" id="headingThree">
            <button className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              <b>Do I get print-ready files for printing?</b>
            </button>
          </h2>
          <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            <div className="accordion-body py-4 px-5">
            Of course, you will have a PDF/Ai/PSD ready-to-print!
            </div>
          </div>
        </div>
        <div className="accordion-item bg-white border border-gray-200">
          <h2 className="accordion-header mb-0" id="headingFour">
            <button className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
              <b>What is full wrap design?</b>
            </button>
          </h2>
          <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            <div className="accordion-body py-4 px-5">
            Full wrap designs cover your whole package.

</div>
          </div>
        </div>
        <div className="accordion-item bg-white border border-gray-200">
          <h2 className="accordion-header mb-0" id="headingFive">
            <button className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
              <b>Does changing text or color count as a revision?

</b>
            </button>
          </h2>
          <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample5">
            <div className="accordion-body py-4 px-5">
            Really not :) you can leave a message anytime for text and color changes.


</div>
          </div>
        </div>
        <div className="accordion-item bg-white border border-gray-200">
          <h2 className="accordion-header mb-0" id="headingSix">
            <button className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
              <b>Do you design Logo's too?

</b>
            </button>
          </h2>
          <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample6">
            <div className="accordion-body py-4 px-5">
            Of course let's discuss in inbox! as a designer I just started as a logo designer ;)


</div>
          </div>
        </div>
        <div className="accordion-item bg-white border border-gray-200">
          <h2 className="accordion-header mb-0" id="headingSeven">
            <button className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
              <b>What files will I get?



</b>
            </button>
          </h2>
          <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
            <div className="accordion-body py-4 px-5">
            After getting your design ready, within a few minutes, we are ready with your print-ready files i.e. PDF, Ai, PSD, and High Res. JPG, PNG shared in a dropbox or order page.

</div>
          </div>
        </div>
        <div className="accordion-item bg-white border border-gray-200">
          <h2 className="accordion-header mb-0" id="headingEight">
            <button className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
              <b>What is Full Product Wrapping?



</b>
            </button>
          </h2>
          <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="collapseEight" data-bs-parent="#accordionExample">
            <div className="accordion-body py-4 px-5">
            Full Product Wrapping means a Box design (It's called Full product wrapping because it covers all four-six sides and has the four/six-sided dieline) Inverse to that we have label designs (one-side wrapping) or Bag/Pouch designs (two-side wrapping)



</div>
          </div>
        </div>
        <div className="accordion-item bg-white border border-gray-200">
          <h2 className="accordion-header mb-0" id="headingNine">
            <button className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
              <b>What is full wrap design?</b>
            </button>
          </h2>
          <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
            <div className="accordion-body py-4 px-5">
            Full wrap designs cover your whole package.

</div>
          </div>
        </div>
        <div className="accordion-item bg-white border border-gray-200">
          <h2 className="accordion-header mb-0" id="headingTen">
            <button className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
              <b>What is full wrap design?</b>
            </button>
          </h2>
          <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            <div className="accordion-body py-4 px-5">
            Full wrap designs cover your whole package.

</div>
          </div>
        </div>
      </div>
    <button class="flex mx-auto mt-16 text-white bg-orange-400 border-0 py-2 px-8 focus:outline-none hover:bg-orange-600 rounded text-lg">{t("Have more Questions?")}</button>
  </div>
</section>
    <br></br>
    <br></br>
        
        </>
    )
}


export default Packaging_Design;

const products = [
  {
    src: product_packaging_image_1,
  },
  {
    src: product_packaging_image_81,
  },
  {
    src: product_packaging_image_2,
  },
  {
    src: product_packaging_image_3,
  },
  {
    src: product_packaging_image_4,
  },
  {
    src: product_packaging_image_82,
  },
  {
    src: product_packaging_image_5,
  },
  {
    src: product_packaging_image_6,
  },
  {
    src: product_packaging_image_7,
  },
  {
    src: product_packaging_image_8,
  },
  {
    src: product_packaging_image_83,
  },
  {
    src: product_packaging_image_8,
  },
  {
    src: product_packaging_image_9,
  },
  {
    src: product_packaging_image_10,
  },
  {
    src: product_packaging_image_11,
  },
  {
    src: product_packaging_image_12,
  },
  {
    src: product_packaging_image_13,
  },
  {
    src: product_packaging_image_14,
  },
  {
    src: product_packaging_image_84,
  },
  {
    src: product_packaging_image_15,
  },
  {
    src: product_packaging_image_16,
  },
  {
    src: product_packaging_image_17,
  },
  {
    src: product_packaging_image_18,
  },
  {
    src: product_packaging_image_19,
  },
  {
    src: product_packaging_image_20,
  },
  {
    src: product_packaging_image_85,
  },
  {
    src: product_packaging_image_21,
  },
  {
    src: product_packaging_image_22,
  },
  {
    src: product_packaging_image_23,
  },
  {
    src: product_packaging_image_24,
  },
  {
    src: product_packaging_image_25,
  },
  {
    src: product_packaging_image_26,
  },
  {
    src: product_packaging_image_27,
  },
  {
    src: product_packaging_image_28,
  },
  {
    src: product_packaging_image_29,
  },
  {
    src: product_packaging_image_30,
  },
  {
    src: product_packaging_image_31,
  },
  {
    src: product_packaging_image_32,
  },
  {
    src: product_packaging_image_33,
  },
  {
    src: product_packaging_image_34,
  },
  {
    src: product_packaging_image_35,
  },
  {
    src: product_packaging_image_36,
  },
  {
    src: product_packaging_image_37,
  },
  {
    src: product_packaging_image_38,
  },
  {
    src: product_packaging_image_39,
  },
  {
    src: product_packaging_image_40,
  },
  {
    src: product_packaging_image_41,
  },
  {
    src: product_packaging_image_42,
  },
  {
    src: product_packaging_image_43,
  },
  {
    src: product_packaging_image_44,
  },
  {
    src: product_packaging_image_45,
  },
  {
    src: product_packaging_image_46,
  },
  {
    src: product_packaging_image_47,
  },
  {
    src: product_packaging_image_48,
  },
  {
    src: product_packaging_image_49,
  },
  {
    src: product_packaging_image_50,
  },
  {
    src: product_packaging_image_51,
  },
  {
    src: product_packaging_image_52,
  },
  {
    src: product_packaging_image_53,
  },
  {
    src: product_packaging_image_54,
  },
  {
    src: product_packaging_image_55,
  },
  {
    src: product_packaging_image_56,
  },
  {
    src: product_packaging_image_57,
  },
  {
    src: product_packaging_image_58,
  },
  {
    src: product_packaging_image_59,
  },
  {
    src: product_packaging_image_60,
  },
  {
    src: product_packaging_image_61,
  },
  {
    src: product_packaging_image_62,
  },
  {
    src: product_packaging_image_63,
  },
  {
    src: product_packaging_image_64,
  },
  {
    src: product_packaging_image_65,
  },
  {
    src: product_packaging_image_66,
  },
  {
    src: product_packaging_image_67,
  },
  {
    src: product_packaging_image_68,
  },
  {
    src: product_packaging_image_69,
  },
  {
    src: product_packaging_image_70,
  },
  {
    src: product_packaging_image_71,
  },
  {
    src: product_packaging_image_72,
  },
  {
    src: product_packaging_image_73,
  },
  {
    src: product_packaging_image_74,
  },
  {
    src: product_packaging_image_75,
  },
  {
    src: product_packaging_image_76,
  },
  {
    src: product_packaging_image_77,
  },
  {
    src: product_packaging_image_78,
  },

]