import { Link, useNavigate } from "react-router-dom";
import './signin.css';
import Footer from './footer';
import { useCallback, useEffect, useRef, useState } from "react";
import {createUserWithEmailAndPassword,sendEmailVerification} from 'firebase/auth'
import {getAuth,signInWithPopup,GoogleAuthProvider, } from "firebase/auth";
import {  doc,getDoc,setDoc} from "firebase/firestore"; // import Firestore functions
import {db} from '../firestore.js';
import signup_picture from './images/web_images/SIGN-UP.png';


function Registration()
{

  function Letter_Image(initial)
  {
    if(initial==="A")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f4/Eo_circle_orange_letter-a.svg/2048px-Eo_circle_orange_letter-a.svg.png"
    }
    if(initial==="B")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Eo_circle_orange_letter-b.svg/1200px-Eo_circle_orange_letter-b.svg.png"
    }
    if(initial==="C")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0e/Eo_circle_orange_letter-c.svg/1200px-Eo_circle_orange_letter-c.svg.png"
    }
    if(initial==="D")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/Eo_circle_orange_letter-d.svg/1200px-Eo_circle_orange_letter-d.svg.png";
    }
    if(initial==="E")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d3/Eo_circle_orange_letter-e.svg/1200px-Eo_circle_orange_letter-e.svg.png";
    }
    if(initial==="F")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Eo_circle_orange_letter-f.svg/1200px-Eo_circle_orange_letter-f.svg.png";
    }
    if(initial==="G")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c7/Eo_circle_orange_letter-g.svg/2048px-Eo_circle_orange_letter-g.svg.png";
    }
    if(initial==="H")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Eo_circle_orange_white_letter-h.svg/1200px-Eo_circle_orange_white_letter-h.svg.png";
    }
    if(initial==="I")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7b/Eo_circle_orange_letter-i.svg/1200px-Eo_circle_orange_letter-i.svg.png";
    }
    if(initial==="J")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/Eo_circle_orange_letter-j.svg/1024px-Eo_circle_orange_letter-j.svg.png";
    }
    if(initial==="K")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/3/37/Eo_circle_orange_letter-k.svg/1200px-Eo_circle_orange_letter-k.svg.png";
    }
    if(initial==="L")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0a/Eo_circle_orange_letter-l.svg/1200px-Eo_circle_orange_letter-l.svg.png";
    }
    if(initial==="M")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/3/32/Eo_circle_orange_letter-m.svg/1200px-Eo_circle_orange_letter-m.svg.png";
    }
    if(initial==="N")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Eo_circle_orange_white_letter-n.svg/1200px-Eo_circle_orange_white_letter-n.svg.png";
    }
    if(initial==="O")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Eo_circle_orange_letter-o.svg/1200px-Eo_circle_orange_letter-o.svg.png";
    }
    if(initial==="P")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Eo_circle_orange_letter-p.svg/2048px-Eo_circle_orange_letter-p.svg.png";
    }
    if(initial==="Q")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/Eo_circle_orange_white_letter-q.svg/240px-Eo_circle_orange_white_letter-q.svg.png";
    }
    if(initial==="R")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c6/Eo_circle_orange_letter-r.svg/1200px-Eo_circle_orange_letter-r.svg.png";
    }
    if(initial==="S")
    {
      return "https://cdn.pixabay.com/photo/2022/03/17/06/08/letter-s-7073833_1280.png";
    }
    if(initial==="T")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Eo_circle_orange_letter-t.svg/1200px-Eo_circle_orange_letter-t.svg.png";
    }
    if(initial==="U")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5a/Eo_circle_orange_letter-u.svg/1024px-Eo_circle_orange_letter-u.svg.png";
    }
    if(initial==="V")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Eo_circle_orange_letter-v.svg/1200px-Eo_circle_orange_letter-v.svg.png";
    }
    if(initial==="W")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Eo_circle_orange_white_letter-w.svg/2048px-Eo_circle_orange_white_letter-w.svg.png";
    }
    if(initial==="X")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/Eo_circle_orange_letter-x.svg/1200px-Eo_circle_orange_letter-x.svg.png";
    }
    if(initial==="Y")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Eo_circle_orange_white_letter-y.svg/1024px-Eo_circle_orange_white_letter-y.svg.png";
    }
    if(initial==="Z")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/8/81/Eo_circle_orange_letter-z.svg/2048px-Eo_circle_orange_letter-z.svg.png";
    }

  }

  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [Error, setError] = useState('');
  const [passwordconfirmation, setConfirmPassword] = useState('');
  const [value, setValue] = useState('');
  const allCountries = countries.map((country) => country.name);
  const navigate = useNavigate();

  {/* PASSWORD VALIDATION FUNCTION */}

 



  const validatePassword = () => {
    let isValid = true
    if (password !== '' && passwordconfirmation !== ''){
      if (password !== passwordconfirmation) {
        isValid = false
        setError('Passwords does not match')
      }
    }
    return isValid
  }


  {/* REGISTER FUNCTION */}
  const register = async () => {
    // e.preventDefault()
    setError('')
    if(validatePassword()) {
      try {
        await createUserWithEmailAndPassword(auth,email, password);
        // send email verification
        try{
          const user = auth.currentUser;
          try {
            const image=Letter_Image(name[0]);
            const docSnapshot = await getDoc(doc(db, "users", email));
            console.log(docSnapshot.data());
            
            if (docSnapshot.exists()) {
              console.log("User already exists");
              return;
            }
          const docRef = doc(db, "users", email);
          await setDoc(docRef, {
            name: name,
            email: email,
            password:password,
            image: image,
            account:"email"
          });
      
        } catch (error) {
          console.log(error.message);
        }
          sendEmailVerification(user)
          .then(async () => {
            console.log('Email verification sent to', email);
            try {
              await auth.signOut();
              console.log('User logged out successfully');
              navigate('/');
            } catch (error) {
              await auth.signOut();
              console.log(error.message);
            }
                    })
          .catch((error) => {
            // Handle errors here
          });          
        }
        catch(error2)
        {
          console.log(error2);
        }
        // do something with the newly created user, e.g. redirect to a dashboard page
      } catch (error) {
        console.error('Error creating user:', error);
        // handle the error, e.g. display an error message to the user
      }
  };
    
    
    setEmail('')
    setPassword('')
    setConfirmPassword('')
    return;
  }
    return(
<>



<section className="h-screen">
        <div className="px-6 h-full ">
          <div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">
            <div className="grow-0 shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0">
              <img src={signup_picture} className="w-full" alt="Sample image" />
            </div>
            <div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
              <form>
                {/* Email input */}
                <div className="mb-6">
                  <input  type="text" className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleFormControlInput1" placeholder="Email address"
                  value={email}
                  onChange={(e)=>{setEmail(e.target.value)}}
                   />
                </div>
                {/* Full Name */}
                <div className="mb-6">
                  <input  type="text" className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleFormControlInput2" placeholder="Full Name" 
                  value={name}
                  onChange={(e)=>{setName(e.target.value)}}/>
                </div>
                {/* Password input */}
                <div className="mb-6">
                  <input type="password" className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleFormControlInput3" placeholder="Password" 
                  value={password}
                  onChange={(e)=>{setPassword(e.target.value)}}/>
                </div>
                <div className="mb-6">
                  <input type="password" className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleFormControlInput4" placeholder="Re-Enter Password" 
                  value={passwordconfirmation}
                  onChange={(e)=>{setConfirmPassword(e.target.value)}}
                  />
                </div>
                
                {/* Country */}
                <div className="mb-6">
                  <Autocomplete
                    value={value}
                    setValue={setValue}
                    name="search"
                    placeholder="Country"
                    suggestions={allCountries}
                    notFound="No suggestions available!" 
                    className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  />
                </div>
                <div className="text-center lg:text-left">
                  <button onClick={()=>{register()}} type="button" className="color_code_button inline-block px-7 py-3 font-medium text-sm leading-snug uppercase rounded shadow-md  hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 a active:shadow-lg transition duration-150 ease-in-out">
                  Register
                  </button>
                  <p className="text-sm font-semibold mt-2 pt-1 mb-0">
                    Already have an account?
                    <br></br>
                    <Link to="/signin" className="text-red-600 hover:text-red-700 focus:text-red-700 transition duration-200 ease-in-out">Login</Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <br></br>
      <br></br>
      </section>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Footer/>



</>
    )
}












const style = {
  label: `text-gray-700`,
  disabled: `cursor-not-allowed`,
  container: `relative mb-6 mt-3`,
  default: `rounded-lg w-full flex-1 mt-1 py-1.5 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:border-transparent border border-gray-300`,
  suggestion: {
    activeItem: 'bg-gray-300',
    item: `px-4 py-3 focus text-sm text-gray-700 cursor-pointer hover:bg-gray-200`,
    list: `shadow-xl absolute top-full left-0 right-0 border w-auto md:max-w-full overflow-y-auto max-h-80 mt-2 bg-white p-3 z-20`,
  },
};










function Autocomplete({
  name,
  label,
  suggestions,
  value,
  setValue,
  notFound,
  ...rest
}) {
  const ref = useRef(null);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  //close suggestions list when click outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!ref.current?.contains(event.target)) {
        if (!showSuggestions) return;
        setShowSuggestions(false);
      }
    };
    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, [showSuggestions, ref]);

  const handleChange = useCallback(
    (e) => {
      const userInput = e.currentTarget.value;
      const filteredSuggestions = suggestions.filter(
        (suggestion) =>
          suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
      );

      setActiveSuggestion(0);
      setFilteredSuggestions(filteredSuggestions);
      setShowSuggestions(true);
      setValue(e.currentTarget.value);
    },
    [setValue, suggestions],
  );

  const onClick = (e) => {
    setActiveSuggestion(0);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setValue(e.currentTarget.innerText);
  };

  const onKeyDown = (e) => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setActiveSuggestion(0);
      setShowSuggestions(false);
      setValue(filteredSuggestions[activeSuggestion]);
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion === filteredSuggestions.length - 1) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);
    }
  };

  let suggestionsListComponent;

  if (showSuggestions && value) {
    if (filteredSuggestions.length) {
      suggestionsListComponent = (
        <ul className={style.suggestion.list}>
          {filteredSuggestions.map((suggestion, index) => {
            let className;

            if (index === activeSuggestion) {
              className = `${style.suggestion.item} ${style.suggestion.activeItem}`;
            }

            if (index !== activeSuggestion) {
              className = style.suggestion.item;
            }
            return (
              <li className={className} key={suggestion} onClick={onClick}>
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    } else {
      suggestionsListComponent = (
        <div className="mt-4 text-sm text-gray-700">
          <em>{notFound}</em>
        </div>
      );
    }
  }
  return (
    <div className={style.container}>
      <label htmlFor={name} className={style.label}>
        {label}
      </label>
      <input
        autoComplete="off"
        className={style.default}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        value={value}
        {...rest}
      />
      {suggestionsListComponent}
    </div>
  );
}

const countries = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: "Cote D'Ivoire", code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: "Korea, Democratic People'S Republic of", code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: "Lao People'S Democratic Republic", code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
];
export default Registration;