
import { ScrollIndicatorPage } from './scroll';
import Image from "react-image-enlarger";
import { useState } from 'react';
import { ArrowRightCircleIcon,ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next';
import './imager.css';
import '../browser/dark.css';
import { CheckIcon } from '@heroicons/react/20/solid'
import {Link} from 'react-router-dom';
import { Glide } from 'react-glide';
import 'react-glide/lib/reactGlide.css'
import ImageZoom from 'react-medium-image-zoom';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import styled from 'styled-components';
import Modal from 'react-modal';
////////////////LOGO DESING IMAGES////////////////
import logo_design_1 from './images/portfolio/logo designs/1.jpg'
import logo_design_2 from './images/portfolio/logo designs/2.jpg'
import logo_design_3 from './images/portfolio/logo designs/3.jpg'
import logo_design_4 from './images/portfolio/logo designs/4.jpg'
import logo_design_5 from './images/portfolio/logo designs/5.jpg'
import logo_design_6 from './images/portfolio/logo designs/6.jpg'
import logo_design_7 from './images/portfolio/logo designs/7.jpg'
import logo_design_8 from './images/portfolio/logo designs/8.jpg'
import logo_design_9 from './images/portfolio/logo designs/9.jpg'
import logo_design_10 from './images/portfolio/logo designs/10.jpg'
import logo_design_11 from './images/portfolio/logo designs/11.jpg'
import logo_design_12 from './images/portfolio/logo designs/12.jpg'
import logo_design_13 from './images/portfolio/logo designs/13.jpg'
import logo_design_14 from './images/portfolio/logo designs/14.jpg'
import logo_design_15 from './images/portfolio/logo designs/15.jpg'
import logo_design_16 from './images/portfolio/logo designs/16.jpg'
import logo_design_17 from './images/portfolio/logo designs/17.jpg'
import logo_design_19 from './images/portfolio/logo designs/19.jpg'
import logo_design_20 from './images/portfolio/logo designs/20.jpg'
import logo_design_21 from './images/portfolio/logo designs/21.jpg'
import logo_design_22 from './images/portfolio/logo designs/22.jpg'
import logo_design_23 from './images/portfolio/logo designs/23.jpg'
import logo_design_24 from './images/portfolio/logo designs/24.jpg'
import logo_design_25 from './images/portfolio/logo designs/25.jpg'
import logo_design_26 from './images/portfolio/logo designs/26.jpg'
import logo_design_27 from './images/portfolio/logo designs/27.jpg'
import logo_design_28 from './images/portfolio/logo designs/28.jpg'



const FullScreenImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  background-color: transparent;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 3px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: black;
  cursor: pointer;
`;

const includedFeatures = [
  'Source file',
  'Commercial use',
  'Conduct research',
  'Data points included',
  'Revisions',
  'Delivery Time'

]
const features = [
  {
    name: 'Amazon product photography editing',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Background removal/changing',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Product image retouch',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Unique product Infographics',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Removing dust or any kind of scratches/spots',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Before/After Images',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Infographics images (shows the product`s features)',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Professional product listing for AMAZON, EBAY, ALIBABA',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Lifestyle photo editing ',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Add logo,Badges,Custom icon etc.',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Comparison charts',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Photo retouching/editing ',
    icon: ArrowRightCircleIcon,
  },
]

function Brand_Logo_Design()
{
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState(null);
  const [showLogoDesign,setshowLogoDesign]=useState(false);

  const handleImageClick = (event) => {
    setSelectedImageSrc(event.target.src);
    setModalIsOpen(true);
  }
  

  const handleCloseModal = () => {
    setModalIsOpen(false);
  }

  const { t } = useTranslation('translation');
  const { t: currencyT } = useTranslation('currency');
  const changer3 =()=>
  {
    if(showLogoDesign===true){
        setshowLogoDesign(false);
        return;
    }
    else{
    setshowLogoDesign(true);
    return;
  }
}
const handleContextMenu = (e) => {
  e.preventDefault(); // Prevent default behavior of the right-click event
};
    return(
        <>
        <ScrollIndicatorPage/>
 
        <Modal
  isOpen={modalIsOpen}
  onRequestClose={handleCloseModal}
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    content: {
    //   position: 'absolute',
      top: '55%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '100%',
      maxHeight: '100%',
      width: '639.5px', // added
      height: '433.5px', // added
      padding: '0px',
      border: 'none',
      borderRadius: '0',
      background: 'transparent' // added

    },
  }}
  
>
        <CloseButton onClick={handleCloseModal}>X</CloseButton>
        <FullScreenImage src={selectedImageSrc} />
      </Modal>

        <div className="bg-white py-12 sm:py-18">
          
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-5xl lg:text-center">
        <h2 className="text-base font-semibold leading-7 text-orange-500">Grow<span className='black-color'>phics</span></h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-orange-400 sm:text-4xl">
          {t("Brand Logo Design")}
                    </p>
          <p className="mt-6 text-lg leading-8 text-gray-600 justifier">
        {t("Brand_Logo_Design_Service")}
          </p>
        </div>
        <div className="mx-auto mt-4 max-w-2xl sm:mt-20 lg:mt-20 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-orange-400">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>

    <div className="bg-changer py-24 sm:py-8" id="BLD">
      <br/>
      <br/>
      <div className="mx-auto max-w-7xl px-6 lg:px-18">
        <h2 className="text-center portfolio_headings font-semibold leading-8 text-orange-400">
        {t("BRAND LOGO DESIGN")}
                </h2>
        <br></br>
        {/* <p className='text-orange-400'>{t("Brand Logo Design Description")}</p> */}
        <div className="mx-auto">
                {/* EDITING PP */}
                <br/>
        <button onClick={()=>changer3()} className="color_code_button inline-block px-7 py-3 font-medium text-sm leading-snug uppercase rounded shadow-md  hover:shadow-lg  active:shadow-lg transition duration-150 ease-in-out">
            {showLogoDesign ? <span>{t("Show Less")}</span> : <span>{t("Show All")}</span>}
          </button>
        <div className="mx-auto ">
<br/>
{showLogoDesign ?
  <>
  <span className='text-black font-bold'>Scroll down to view more</span>
  </>
:<></>
}

<div className="flex items-center justify-center w-full h-full py-48 sm:py-8 px-4 bg-gray-900 container_border">
  {/* FOR WEB */}
  
  {showLogoDesign ?
  <>
  <div className='flex flex-col pp_height overflow-x-auto mb-4 '>

       <div className="mt-2 grid grid-cols-1 gap-x-2 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8 ">
          {logo_design.map((product) => (
            <div key={product.id} className="group relative">
              <div className="min-h-40 aspect-h-24 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-full">
                <img onContextMenu={handleContextMenu}
                    onClick={handleImageClick}
                  src={product.src}
                  className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                />
              </div>
            </div>
          ))}
        </div>
        </div>
      </>
  :
  <>
    <CarouselProvider className="lg:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={10} visibleSlides={4} step={1} infinite={true}>
        <div className="w-full relative flex items-center justify-center">
        {!modalIsOpen && (
          <>
            <ButtonBack 
                          role="button" 
                          aria-label="slide backward" 
                          className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                          id="prev"
                        >
                            <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
            </>
        )}
        
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
     
                <Slider>
                    <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                        <Slide index={0}>
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={logo_design_1}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={1}>
                            
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={logo_design_2}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={2}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={logo_design_3}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={3}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={logo_design_4}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={4}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={logo_design_5}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={5}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={logo_design_6}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={6}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={logo_design_7}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={7}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={logo_design_8}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={8}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={logo_design_9}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={9}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={logo_design_10}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
              
                    </div>
                </Slider>
            </div>
            
            {!modalIsOpen && (
          <>
         <ButtonNext role="button" aria-label="slide forward" 
                          className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                        >
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            
                        </ButtonNext>
            </>
        )}
  
        </div>
        

    </CarouselProvider>


    {/* FOR TAB */}
    <CarouselProvider className="lg:hidden md:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={12} visibleSlides={2} step={1} infinite={true}>
    <div className="w-full relative flex items-center justify-center">
             {!modalIsOpen && (
          <>
            <ButtonBack 
                          role="button" 
                          aria-label="slide backward" 
                          className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                          id="prev"
                        >
                            <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
            </>
        )}
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                <Slider>
                    <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                        <Slide index={0}>
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/171664213/original/929084264b492316c64db559bd958386dfe7eb5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={1}>
                            
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs3/171664213/original/c3837cc7268a8645530273b3f301d044c3a01a5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={2}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_smartwm/t_gig_pdf_gallery_view_ver4,q_auto,f_auto/attachments/delivery/asset/8585d444974aec39b18e811cb1cbba6b-1679825598/Preview-Eldotrado-Pdf/do-amazon-product-photography-editing-product-infographic.pdf"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
              
                    </div>
                </Slider>
            </div>
             {!modalIsOpen && (
          <>
         <ButtonNext role="button" aria-label="slide forward" 
                          className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                        >
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            
                        </ButtonNext>
            </>
        )}
        </div>
    </CarouselProvider>
    {/* FOR MOBILE */}
    <CarouselProvider className="block md:hidden " naturalSlideWidth={20} isIntrinsicHeight={true} totalSlides={3} visibleSlides={1} step={1} infinite={true}>
    <div className="w-full relative flex items-center justify-center">
             {!modalIsOpen && (
          <>
            <ButtonBack 
                          role="button" 
                          aria-label="slide backward" 
                          className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                          id="prev"
                        >
                            <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
            </>
        )}
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                <Slider>
                    <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                        <Slide index={0}>
                            <div className="flex flex-shrink-0 relative w-full sm:w-full">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/171664213/original/929084264b492316c64db559bd958386dfe7eb5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={1}>
                            
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs3/171664213/original/c3837cc7268a8645530273b3f301d044c3a01a5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={2}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_smartwm/t_gig_pdf_gallery_view_ver4,q_auto,f_auto/attachments/delivery/asset/8585d444974aec39b18e811cb1cbba6b-1679825598/Preview-Eldotrado-Pdf/do-amazon-product-photography-editing-product-infographic.pdf"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
              
                    </div>
                </Slider>
            </div>
             {!modalIsOpen && (
          <>
         <ButtonNext role="button" aria-label="slide forward" 
                          className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                        >
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            
                        </ButtonNext>
            </>
        )}
        </div>
    </CarouselProvider>
    </> 

}
</div>

    
</div>

</div>
      </div>
      <br/>
      <br/>
    </div>
    <div className="bg-white py-24 sm:py-8 mobile-packages-hide">
    <h2 className="text-3xl font-bold tracking-tight text-orange-400 sm:text-4xl text-center">Compare packages</h2>
              <br/>
              <br/>
              <br/>
      <div className=" mx-auto max-w-7xl px-8 lg:px-18"></div>
    <div className="text-black compare-table">
  <table>
    <thead>
      <tr>
        <th>Package</th>
        <th className='thx'>Basic</th>
        <th className='thx'>Standard</th>
        <th className='thx'>Premium</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td></td>
        <td>36.25$</td>
        <td>46.61$</td>
        <td>56.96$</td>
      </tr>
      <tr>
        <td></td>
        <td><b>SILVER (1  CONCEPT<br/> LOGO DESIGN) </b></td>
        <td><b>GOLD (2-3 CONCEPTS<br/> LOGO DESIGN) </b></td>
        <td><b>DIAMOND (4-5 <br/>CONCEPTS LOGO <br/>DESIGN) </b></td>


      </tr>
      <tr>
        <td></td>
        <td>Includes : - AI,<br/> JPEG, PNG & PDF <br/>- Unlimited Revisions</td>
        <td>Includes : - AI,<br/> JPEG, PNG & PDF <br/>- Unlimited Revisions</td>
        <td>Includes : - AI,<br/> JPEG, PNG & PDF <br/>- Unlimited Revisions<br/> - Brand Identity</td>
        
      </tr>
      <tr>
        <td>Logo transparency</td>
        <td><CheckIcon className="h-6 w-5 flex-none text-grey-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
      </tr>
      <tr>
        <td>Vector file</td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
      </tr>
      <tr>
        <td>Printable file
</td>
        <td><CheckIcon className="h-6 w-5 flex-none text-grey-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
      </tr>
      <tr>
        <td>3D mockup</td>
        <td><CheckIcon className="h-6 w-5 flex-none text-grey-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
      </tr>
      <tr>
        <td>Source file
</td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
      </tr>
      <tr>
        <td>Stationery designs
</td>
        <td><CheckIcon className="h-6 w-5 flex-none text-grey-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-grey-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
      </tr>
      <tr>
        <td>Social media kit
</td>
        <td><CheckIcon className="h-6 w-5 flex-none text-grey-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-grey-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
      </tr>

      <tr>
        <td>Number of concepts included</td>
        <td>1</td>
        <td>3</td>
        <td>5</td>
      </tr>
      <tr>
        <td>Revisions</td>
        <td>Unlimited</td>
        <td>Unlimited</td>
        <td>Unlimited</td>
      </tr>
      <tr>
        <td>Delivery Time</td>
        <td>1 day</td>
        <td>3 days</td>
        <td>5 days</td>
      </tr>
      <tr>
        <td></td>
        <td><button className='btn bg-orange-400 text-white hover:bg-orange-500'>Select</button></td>
        <td><button className='btn bg-orange-400 text-white hover:bg-orange-500'>Select</button></td>
        <td><button className='btn bg-orange-400 text-white hover:bg-orange-500'>Select</button></td>
   
      </tr>
    </tbody>
  </table>
</div>
</div>

    <div className="bg-white py-24 sm:py-8 big-packages">
      <div className="mx-auto max-w-7xl px-8 lg:px-18">
      <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="hover1 mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Basic</h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
            Includes : - AI, JPEG, PNG & PDF - Unlimited Revisions
                        </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-orange-400">What’s included</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.includes("Source file") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-grey-400" aria-hidden="true" />
                  Logo transparency
 
                </li>
              )}
              {includedFeatures.includes("Commercial use") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Vector file
                </li>
              )}
              {includedFeatures.includes("Commercial use") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-grey-400" aria-hidden="true" />
                  Printable file

                </li>
              )}
              {includedFeatures.includes("Conduct research") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-grey-400" aria-hidden="true" />
                  3D mockup
                </li>
              )}
              {includedFeatures.includes("Conduct research") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Source file
                </li>
              )}
              {includedFeatures.includes("Conduct research") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-grey-400" aria-hidden="true" />
                  Stationery designs
                </li>
              )}
              {includedFeatures.includes("Conduct research") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-grey-400" aria-hidden="true" />
                  Social media kit
                </li>
              )}
              {includedFeatures.includes("Revisions") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Revision : <span className='text-orange-400 text-bold'>Unlimited</span>
                </li>
              )}
              {includedFeatures.includes("Delivery Time") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Number of concepts included : <span className='text-orange-400 text-bold'>1 </span>
                </li>
              )}
              {includedFeatures.includes("Delivery Time") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Delivery Time : <span className='text-orange-400 text-bold'>1 day</span>
                </li>
              )}
                          </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">Pay once, own it forever</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                <span className="text-5xl font-bold tracking-tight text-gray-900">36.25</span>
                  <span className="text-5xl font-semibold leading-6 tracking-wide text-gray-600">$</span>
                </p>
                <Link
                  to="/payment"
                  className="mt-10 block w-full rounded-md bg-orange-400 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Buy
                </Link>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  Invoices and receipts available for easy company reimbursement
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="hover1 mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-8 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Standard</h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
            Includes : - AI, JPEG, PNG & PDF - Unlimited Revisions            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-orange-400">What’s included</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
     {includedFeatures.includes("Source file") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Logo transparency
 
                </li>
              )}
              {includedFeatures.includes("Commercial use") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Vector file
                </li>
              )}
              {includedFeatures.includes("Commercial use") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Printable file

                </li>
              )}
              {includedFeatures.includes("Conduct research") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  3D mockup
                </li>
              )}
              {includedFeatures.includes("Conduct research") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Source file
                </li>
              )}
              {includedFeatures.includes("Conduct research") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-grey-400" aria-hidden="true" />
                  Stationery designs
                </li>
              )}
              {includedFeatures.includes("Conduct research") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-grey-400" aria-hidden="true" />
                  Social media kit
                </li>
              )}
              {includedFeatures.includes("Revisions") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Revision : <span className='text-orange-400 text-bold'>Unlimited</span>
                </li>
              )}
              {includedFeatures.includes("Delivery Time") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Number of concepts included : <span className='text-orange-400 text-bold'>3 </span>
                </li>
              )}
              {includedFeatures.includes("Delivery Time") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Delivery Time : <span className='text-orange-400 text-bold'>1 day</span>
                </li>
              )}
                          </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">Pay once, own it forever</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">46.61</span>
                  <span className="text-5xl font-semibold leading-6 tracking-wide text-gray-600">$</span>
                </p>
                <Link
                  to="/payment"
                  className="mt-10 block w-full rounded-md bg-orange-400 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Buy
                </Link>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  Invoices and receipts available for easy company reimbursement
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="hover1 mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-8 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Premium</h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
            Includes : - AI, JPEG, PNG & PDF - Unlimited Revisions - Brand Identity            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-orange-400">What’s included</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
     {includedFeatures.includes("Source file") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Logo transparency
 
                </li>
              )}
              {includedFeatures.includes("Commercial use") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Vector file
                </li>
              )}
              {includedFeatures.includes("Commercial use") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Printable file

                </li>
              )}
              {includedFeatures.includes("Conduct research") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  3D mockup
                </li>
              )}
              {includedFeatures.includes("Conduct research") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Source file
                </li>
              )}
              {includedFeatures.includes("Conduct research") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Stationery designs
                </li>
              )}
              {includedFeatures.includes("Conduct research") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Social media kit
                </li>
              )}
              {includedFeatures.includes("Revisions") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Revision : <span className='text-orange-400 text-bold'>Unlimited</span>
                </li>
              )}
              {includedFeatures.includes("Delivery Time") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Number of concepts included : <span className='text-orange-400 text-bold'>5 </span>
                </li>
              )}
              {includedFeatures.includes("Delivery Time") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Delivery Time : <span className='text-orange-400 text-bold'>1 day</span>
                </li>
              )}
                          </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">Pay once, own it forever</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                <span className="text-5xl font-bold tracking-tight text-gray-900">	56.96</span>
                  <span className="text-5xl font-semibold leading-6 tracking-wide text-gray-600">$</span>
                </p>
                <Link
                  to="/payment"
                  className="mt-10 block w-full rounded-md bg-orange-400 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Buy
                </Link>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  Invoices and receipts available for easy company reimbursement
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        </div>
        </div>



<section class="text-gray-600 body-font">
  <div class="container px-10 py-2 mx-auto">
  <h1 class="sm:text-5xl text-3xl font-bold text-center title-font text-orange-400 mb-4">{t("Enhanced Brand FAQs")}</h1>
<br></br>
<br></br>
<br></br>
     <div className="accordion" id="accordionExample">
        <div className="accordion-item bg-white border border-gray-200">
          <h2 className="accordion-header mb-0" id="headingOne">
            <button className="
        accordion-button
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
               <b>
                What do you need from me to start working?
                </b>
            </button>
          </h2>
          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div className="accordion-body py-4 px-5">
            All we need is product photos, product link(s), and your logo. In addition, if you are able to provide some more details it would be great, such as EBC layout, any inspiration, text info, etc. These allow us to provide you with the best results.
</div>
</div>
        </div>
        <div className="accordion-item bg-white border border-gray-200">
          <h2 className="accordion-header mb-0" id="headingTwo">
            <button className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        <b>
        How many revisions are included?
          </b></button>
          </h2>
          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div className="accordion-body py-4 px-5">
            There are unlimited revisions as we believe to satisfy all our clients.
            </div>
          </div>
        </div>
        <div className="accordion-item bg-white border border-gray-200">
          <h2 className="accordion-header mb-0" id="headingThree">
            <button className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              <b>
              I have more products to do EBC images for them. Can I get any discount on them?
              </b>
            </button>
          </h2>
          <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            <div className="accordion-body py-4 px-5">
            Why not, we believe in long-term partnerships with our clients we would be more than happy to give you some discounts on them.
            </div>
          </div>
        </div>
        <div className="accordion-item bg-white border border-gray-200">
          <h2 className="accordion-header mb-0" id="headingFour">
            <button className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
              <b>
              What happens if Amazon does not approve the EBC?
              </b>
            </button>
          </h2>
          <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
            <div className="accordion-body py-4 px-5">
            Don't worry, this can happen sometimes due to many reasons. We will work with you to make the necessary changes suggested by Amazon to get it approved.
</div>
          </div>
        </div>
        <div className="accordion-item bg-white border border-gray-200">
          <h2 className="accordion-header mb-0" id="headingFive">
            <button className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
              <b>
              I've not registered my product as a brand yet. Do I have to do that before ordering your service?
</b>
            </button>
          </h2>
          <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample5">
            <div className="accordion-body py-4 px-5">
            Yes, you will need to register the brand before ordering our service. If this is not completed prior, we can still create the EBC, but you will not be able to submit it to Amazon before registering your brand.
</div>
          </div>
        </div>
        <div className="accordion-item bg-white border border-gray-200">
          <h2 className="accordion-header mb-0" id="headingSix">
            <button className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
              <b>
              All images in your portfolio are your work?
              </b>
            </button>
          </h2>
          <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample6">
            <div className="accordion-body py-4 px-5">
            Yes, all images shown in our portfolio are our work. Also, your project after is finished may be added to our portfolio and/or our social media. If you for any reason DO NOT WANT your design to be shown there, let us know in advance.
</div>
          </div>
        </div>
        <div className="accordion-item bg-white border border-gray-200">
          <h2 className="accordion-header mb-0" id="headingSeven">
            <button className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
              <b>
              Do you design the images in other language as well?
</b>
            </button>
          </h2>
          <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
            <div className="accordion-body py-4 px-5">
            Yes, we do the images in different languages such as (German, French, Dutch, Spanish, etc.) as we work with clients from all over the world.

</div>
          </div>
        </div>
        


      </div>
    <button class="flex mx-auto mt-16 text-white bg-orange-400 border-0 py-2 px-8 focus:outline-none hover:bg-orange-600 rounded text-lg">{t("Have more Questions?")}</button>
  </div>
</section>
    <br></br>
    <br></br>
        
        </>
    )
}


export default Brand_Logo_Design;

const logo_design = [
  {
    src: logo_design_1,
  },
  {
    src: logo_design_2,
  },
  {
    src: logo_design_3,
  },
  {
    src: logo_design_4,
  },
  {
    src: logo_design_5,
  },
  {
    src: logo_design_6,
  },
  {
    src: logo_design_7,
  },
  {
    src: logo_design_8,
  },
  {
    src: logo_design_9,
  },
  {
    src: logo_design_10,
  },
  {
    src: logo_design_11,
  },
  {
    src: logo_design_12,
  },
  {
    src: logo_design_13,
  },
  {
    src: logo_design_14,
  },
  {
    src: logo_design_15,
  },
  {
    src: logo_design_16,
  },
  {
    src: logo_design_17,
  },
  {
    src: logo_design_28,
  },
  {
    src: logo_design_19,
  },
  {
    src: logo_design_20,
  },
  {
    src: logo_design_21,
  },
  {
    src: logo_design_22,
  },
  {
    src: logo_design_23,
  },
  {
    src: logo_design_24,
  },
  {
    src: logo_design_25,
  },
  {
    src: logo_design_26,
  },
  {
    src: logo_design_27,
  },

 

]