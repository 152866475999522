import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './imager.css';
import { ScrollIndicatorPage } from '../browser//scroll';
import '../browser/dark.css';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import styled from 'styled-components';
import Modal from 'react-modal';

////////////////PRODUCT PACKAGE DESING IMAGES////////////////
import product_packaging_image_1 from './images/portfolio/PRODUCT PACKAGING/1.png'
import product_packaging_image_2 from './images/portfolio/PRODUCT PACKAGING/2.jpg'
import product_packaging_image_3 from './images/portfolio/PRODUCT PACKAGING/3.jpg'
import product_packaging_image_4 from './images/portfolio/PRODUCT PACKAGING/4.jpg'
import product_packaging_image_5 from './images/portfolio/PRODUCT PACKAGING/5.png'
import product_packaging_image_6 from './images/portfolio/PRODUCT PACKAGING/6.jpg'
import product_packaging_image_7 from './images/portfolio/PRODUCT PACKAGING/7.jpg'
import product_packaging_image_8 from './images/portfolio/PRODUCT PACKAGING/8.jpg'
import product_packaging_image_9 from './images/portfolio/PRODUCT PACKAGING/9.jpg'
import product_packaging_image_10 from './images/portfolio/PRODUCT PACKAGING/10.jpg'
import product_packaging_image_11 from './images/portfolio/PRODUCT PACKAGING/11.jpg'
import product_packaging_image_12 from './images/portfolio/PRODUCT PACKAGING/12.jpg'
import product_packaging_image_13 from './images/portfolio/PRODUCT PACKAGING/13.jpg'
import product_packaging_image_14 from './images/portfolio/PRODUCT PACKAGING/14.jpg'
import product_packaging_image_15 from './images/portfolio/PRODUCT PACKAGING/15.jpg'
import product_packaging_image_16 from './images/portfolio/PRODUCT PACKAGING/16.jpg'
import product_packaging_image_17 from './images/portfolio/PRODUCT PACKAGING/17.jpg'
import product_packaging_image_18 from './images/portfolio/PRODUCT PACKAGING/18.jpg'
import product_packaging_image_19 from './images/portfolio/PRODUCT PACKAGING/19.jpg'
import product_packaging_image_20 from './images/portfolio/PRODUCT PACKAGING/20.jpg'
import product_packaging_image_21 from './images/portfolio/PRODUCT PACKAGING/21.jpg'
import product_packaging_image_22  from './images/portfolio/PRODUCT PACKAGING/22.png'
import product_packaging_image_23 from './images/portfolio/PRODUCT PACKAGING/23.jpg'
import product_packaging_image_24 from './images/portfolio/PRODUCT PACKAGING/24.jpg'
import product_packaging_image_25 from './images/portfolio/PRODUCT PACKAGING/25.jpg'
import product_packaging_image_26 from './images/portfolio/PRODUCT PACKAGING/26.jpg'
import product_packaging_image_27 from './images/portfolio/PRODUCT PACKAGING/27.jpg'
import product_packaging_image_28 from './images/portfolio/PRODUCT PACKAGING/28.jpg'
import product_packaging_image_29 from './images/portfolio/PRODUCT PACKAGING/29.jpg'
import product_packaging_image_30 from './images/portfolio/PRODUCT PACKAGING/30.jpg'
import product_packaging_image_31 from './images/portfolio/PRODUCT PACKAGING/31.jpg'
import product_packaging_image_32 from './images/portfolio/PRODUCT PACKAGING/32.jpg'
import product_packaging_image_33 from './images/portfolio/PRODUCT PACKAGING/33.jpg'
import product_packaging_image_34 from './images/portfolio/PRODUCT PACKAGING/34.jpg'
import product_packaging_image_35 from './images/portfolio/PRODUCT PACKAGING/35.jpg'
import product_packaging_image_36 from './images/portfolio/PRODUCT PACKAGING/36.jpg'
import product_packaging_image_37 from './images/portfolio/PRODUCT PACKAGING/37.jpg'
import product_packaging_image_38 from './images/portfolio/PRODUCT PACKAGING/38.jpg'
import product_packaging_image_39 from './images/portfolio/PRODUCT PACKAGING/39.jpg'
import product_packaging_image_40 from './images/portfolio/PRODUCT PACKAGING/40.png'
import product_packaging_image_41 from './images/portfolio/PRODUCT PACKAGING/41.jpg'
import product_packaging_image_42 from './images/portfolio/PRODUCT PACKAGING/42.jpg'
import product_packaging_image_43 from './images/portfolio/PRODUCT PACKAGING/43.jpg'
import product_packaging_image_44 from './images/portfolio/PRODUCT PACKAGING/44.jpg'
import product_packaging_image_45 from './images/portfolio/PRODUCT PACKAGING/45.jpg'
import product_packaging_image_46 from './images/portfolio/PRODUCT PACKAGING/46.jpg'
import product_packaging_image_47 from './images/portfolio/PRODUCT PACKAGING/47.jpg'
import product_packaging_image_48 from './images/portfolio/PRODUCT PACKAGING/48.jpg'
import product_packaging_image_49 from './images/portfolio/PRODUCT PACKAGING/49.jpg'
import product_packaging_image_50 from './images/portfolio/PRODUCT PACKAGING/50.jpg'
import product_packaging_image_51 from './images/portfolio/PRODUCT PACKAGING/51.jpg'
import product_packaging_image_52 from './images/portfolio/PRODUCT PACKAGING/52.jpg'
import product_packaging_image_53 from './images/portfolio/PRODUCT PACKAGING/53.jpg'
import product_packaging_image_54 from './images/portfolio/PRODUCT PACKAGING/54.jpg'
import product_packaging_image_55 from './images/portfolio/PRODUCT PACKAGING/55.jpg'
import product_packaging_image_56 from './images/portfolio/PRODUCT PACKAGING/56.jpg'
import product_packaging_image_57 from './images/portfolio/PRODUCT PACKAGING/57.jpg'
import product_packaging_image_58 from './images/portfolio/PRODUCT PACKAGING/58.jpg'
import product_packaging_image_59 from './images/portfolio/PRODUCT PACKAGING/59.jpg'
import product_packaging_image_60 from './images/portfolio/PRODUCT PACKAGING/60.jpg'
import product_packaging_image_61 from './images/portfolio/PRODUCT PACKAGING/61.jpg'
import product_packaging_image_62 from './images/portfolio/PRODUCT PACKAGING/62.jpg'
import product_packaging_image_63 from './images/portfolio/PRODUCT PACKAGING/63.jpg'
import product_packaging_image_64 from './images/portfolio/PRODUCT PACKAGING/64.png'
import product_packaging_image_65 from './images/portfolio/PRODUCT PACKAGING/65.jpg'
import product_packaging_image_66 from './images/portfolio/PRODUCT PACKAGING/66.jpg'
import product_packaging_image_67 from './images/portfolio/PRODUCT PACKAGING/67.jpg'
import product_packaging_image_68 from './images/portfolio/PRODUCT PACKAGING/68.jpg'
import product_packaging_image_69 from './images/portfolio/PRODUCT PACKAGING/69.jpg'
import product_packaging_image_70 from './images/portfolio/PRODUCT PACKAGING/70.jpg'
import product_packaging_image_71 from './images/portfolio/PRODUCT PACKAGING/71.jpg'
import product_packaging_image_72 from './images/portfolio/PRODUCT PACKAGING/72.jpg'
import product_packaging_image_73 from './images/portfolio/PRODUCT PACKAGING/73.png'
import product_packaging_image_74 from './images/portfolio/PRODUCT PACKAGING/74.jpg'
import product_packaging_image_75 from './images/portfolio/PRODUCT PACKAGING/75.jpg'
import product_packaging_image_76 from './images/portfolio/PRODUCT PACKAGING/76.jpg'
import product_packaging_image_77 from './images/portfolio/PRODUCT PACKAGING/77.jpg'
import product_packaging_image_78 from './images/portfolio/PRODUCT PACKAGING/78.jpg'
import product_packaging_image_81 from './images/portfolio/packaging/81.jpg'
import product_packaging_image_82 from './images/portfolio/packaging/82.jpg'
import product_packaging_image_83 from './images/portfolio/packaging/83.jpg'
import product_packaging_image_84 from './images/portfolio/packaging/84.jpg'
import product_packaging_image_85 from './images/portfolio/packaging/85.jpg'
import product_packaging_image_86 from './images/portfolio/packaging/86.jpg'
import product_packaging_image_87 from './images/portfolio/packaging/87.jpg'

////////////////AMAZON STOREFRONT DESIGN IMAGES////////////////
import storefront_image_1 from './images/portfolio/store front/1.jpg'
import storefront_image_2 from './images/portfolio/store front/2.jpg'
import storefront_image_3 from './images/portfolio/store front/3.jpg'
import storefront_image_4 from './images/portfolio/store front/4.jpg'
import storefront_image_5 from './images/portfolio/store front/5.jpg'
import storefront_image_6 from './images/portfolio/store front/6.jpg'
import storefront_image_7 from './images/portfolio/store front/7.jpg'
import storefront_image_8 from './images/portfolio/store front/8.jpg'
import storefront_image_9 from './images/portfolio/store front/9.jpg'
import storefront_image_10 from './images/portfolio/store front/10.jpg'
import storefront_image_11 from './images/portfolio/store front/11.jpg'
import storefront_image_12 from './images/portfolio/store front/12.jpg'
import storefront_image_13 from './images/portfolio/store front/13.jpg'
import storefront_image_14 from './images/portfolio/store front/14.jpg'
import storefront_image_15 from './images/portfolio/store front/15.jpg'
import storefront_image_16 from './images/portfolio/store front/16.jpg'
import storefront_image_17 from './images/portfolio/store front/17.jpg'
import storefront_image_18 from './images/portfolio/store front/18.jpg'
import storefront_image_19 from './images/portfolio/store front/19.jpg'
import storefront_image_20 from './images/portfolio/store front/20.jpg'
import storefront_image_21 from './images/portfolio/store front/21.jpg'
import storefront_image_22 from './images/portfolio/store front/22.jpg'
import storefront_image_23 from './images/portfolio/store front/23.jpg'
import storefront_image_24 from './images/portfolio/store front/24.jpg'
import storefront_image_25 from './images/portfolio/store front/25.jpg'
import storefront_image_26 from './images/portfolio/store front/26.jpg'
import storefront_image_27 from './images/portfolio/store front/27.jpg'

////////////////LOGO DESING IMAGES////////////////
import logo_design_1 from './images/portfolio/logo designs/1.jpg'
import logo_design_2 from './images/portfolio/logo designs/2.jpg'
import logo_design_3 from './images/portfolio/logo designs/3.jpg'
import logo_design_4 from './images/portfolio/logo designs/4.jpg'
import logo_design_5 from './images/portfolio/logo designs/5.jpg'
import logo_design_6 from './images/portfolio/logo designs/6.jpg'
import logo_design_7 from './images/portfolio/logo designs/7.jpg'
import logo_design_8 from './images/portfolio/logo designs/8.jpg'
import logo_design_9 from './images/portfolio/logo designs/9.jpg'
import logo_design_10 from './images/portfolio/logo designs/10.jpg'
import logo_design_11 from './images/portfolio/logo designs/11.jpg'
import logo_design_12 from './images/portfolio/logo designs/12.jpg'
import logo_design_13 from './images/portfolio/logo designs/13.jpg'
import logo_design_14 from './images/portfolio/logo designs/14.jpg'
import logo_design_15 from './images/portfolio/logo designs/15.jpg'
import logo_design_16 from './images/portfolio/logo designs/16.jpg'
import logo_design_17 from './images/portfolio/logo designs/17.jpg'
import logo_design_19 from './images/portfolio/logo designs/19.jpg'
import logo_design_20 from './images/portfolio/logo designs/20.jpg'
import logo_design_21 from './images/portfolio/logo designs/21.jpg'
import logo_design_22 from './images/portfolio/logo designs/22.jpg'
import logo_design_23 from './images/portfolio/logo designs/23.jpg'
import logo_design_24 from './images/portfolio/logo designs/24.jpg'
import logo_design_25 from './images/portfolio/logo designs/25.jpg'
import logo_design_26 from './images/portfolio/logo designs/26.jpg'
import logo_design_27 from './images/portfolio/logo designs/27.jpg'
import logo_design_28 from './images/portfolio/logo designs/28.jpg'

////////////////EBC A////////////////
import EBC_1 from './images/portfolio/EBC A/1.jpg'
import EBC_2 from './images/portfolio/EBC A/2.jpg'
import EBC_3 from './images/portfolio/EBC A/3.jpg'
import EBC_4 from './images/portfolio/EBC A/4.jpg'
import EBC_5 from './images/portfolio/EBC A/5.jpg'
import EBC_6 from './images/portfolio/EBC A/6.jpg'
import EBC_7 from './images/portfolio/EBC A/7.jpg'
import EBC_8 from './images/portfolio/EBC A/8.jpg'
import EBC_9 from './images/portfolio/EBC A/9.jpg'
import EBC_10 from './images/portfolio/EBC A/10.jpg'
import EBC_11 from './images/portfolio/EBC A/11.jpg'
import EBC_12 from './images/portfolio/EBC A/12.jpg'
import EBC_13 from './images/portfolio/EBC A/13.jpg'
import EBC_14 from './images/portfolio/EBC A/14.jpg'
import EBC_15 from './images/portfolio/EBC A/15.jpg'
import EBC_16 from './images/portfolio/EBC A/16.jpg'
import EBC_17 from './images/portfolio/EBC A/17.jpg'
import EBC_18 from './images/portfolio/EBC A/18.jpg'
import EBC_19 from './images/portfolio/EBC A/19.jpg'
import EBC_20 from './images/portfolio/EBC A/20.jpg'
import EBC_21 from './images/portfolio/EBC A/21.jpg'
import EBC_22 from './images/portfolio/EBC A/22.jpg'
import EBC_23 from './images/portfolio/EBC A/23.jpg'
import EBC_24 from './images/portfolio/EBC A/24.jpg'

////////////////Listing Images////////////////
import ListingImages1 from './images/portfolio/listing images/1.jpg'
import ListingImages2 from './images/portfolio/listing images/2.jpg'
import ListingImages3 from './images/portfolio/listing images/3.jpg'
import ListingImages4 from './images/portfolio/listing images/4.jpg'
import ListingImages5 from './images/portfolio/listing images/5.jpg'
import ListingImages6 from './images/portfolio/listing images/6.jpg'
import ListingImages7 from './images/portfolio/listing images/7.jpg'
import ListingImages8 from './images/portfolio/listing images/8.jpg'
import ListingImages9 from './images/portfolio/listing images/9.jpg'
import ListingImages10 from './images/portfolio/listing images/10.jpg'
import ListingImages11 from './images/portfolio/listing images/11.jpg'
import ListingImages12 from './images/portfolio/listing images/12.jpg'
import ListingImages13 from './images/portfolio/listing images/13.jpg'
import ListingImages14 from './images/portfolio/listing images/14.jpg'
import ListingImages15 from './images/portfolio/listing images/15.jpg'
import ListingImages16 from './images/portfolio/listing images/16.jpg'
import ListingImages17 from './images/portfolio/listing images/17.jpg'
import ListingImages18 from './images/portfolio/listing images/18.jpg'
import ListingImages19 from './images/portfolio/listing images/19.jpg'
import ListingImages20 from './images/portfolio/listing images/20.jpg'
import ListingImages21 from './images/portfolio/listing images/21.jpg'
import ListingImages22 from './images/portfolio/listing images/22.jpg'
import ListingImages23 from './images/portfolio/listing images/23.jpg'
import ListingImages24 from './images/portfolio/listing images/24.jpg'
import ListingImages25 from './images/portfolio/listing images/25.jpg'
import ListingImages26 from './images/portfolio/listing images/26.jpg'
import ListingImages27 from './images/portfolio/listing images/27.jpg'
import ListingImages28 from './images/portfolio/listing images/28.jpg'
import ListingImages29 from './images/portfolio/listing images/29.jpg'
import ListingImages30 from './images/portfolio/listing images/30.jpg'
import ListingImages31 from './images/portfolio/listing images/31.jpg'
import ListingImages32 from './images/portfolio/listing images/32.jpg'
import ListingImages33 from './images/portfolio/listing images/33.jpg'
import ListingImages34 from './images/portfolio/listing images/34.jpg'
import ListingImages35 from './images/portfolio/listing images/35.jpg'
import ListingImages36 from './images/portfolio/listing images/36.jpg'
import ListingImages37 from './images/portfolio/listing images/37.jpg'
import ListingImages38 from './images/portfolio/listing images/38.jpg'
import ListingImages39 from './images/portfolio/listing images/39.jpg'
import ListingImages40 from './images/portfolio/listing images/40.jpg'
import ListingImages41 from './images/portfolio/listing images/41.jpg'
import ListingImages42 from './images/portfolio/listing images/42.jpg'
import ListingImages43 from './images/portfolio/listing images/43.jpg'
import ListingImages44 from './images/portfolio/listing images/44.jpg'
import ListingImages45 from './images/portfolio/listing images/45.jpg'
import ListingImages46 from './images/portfolio/listing images/46.jpg'
import ListingImages47 from './images/portfolio/listing images/47.jpg'
import ListingImages48 from './images/portfolio/listing images/48.jpg'
import ListingImages49 from './images/portfolio/listing images/49.jpg'
import ListingImages50 from './images/portfolio/listing images/50.jpg'
import ListingImages51 from './images/portfolio/listing images/51.jpg'
import ListingImages52 from './images/portfolio/listing images/52.jpg'

////////////////CARD DESIGN IMAGES////////////////
import CardDesign1 from './images/portfolio/CARD DESIGNS/1.jpg'
import CardDesign2 from './images/portfolio/CARD DESIGNS/2.jpg'
import CardDesign3 from './images/portfolio/CARD DESIGNS/3.jpg'
import CardDesign4 from './images/portfolio/CARD DESIGNS/4.jpg'
import CardDesign5 from './images/portfolio/CARD DESIGNS/5.jpg'
import CardDesign6 from './images/portfolio/CARD DESIGNS/6.jpg'
import CardDesign7 from './images/portfolio/CARD DESIGNS/7.jpg'
import CardDesign8 from './images/portfolio/CARD DESIGNS/8.jpg'
import CardDesign9 from './images/portfolio/CARD DESIGNS/9.jpg'
import CardDesign10 from './images/portfolio/CARD DESIGNS/10.jpg'
import CardDesign11 from './images/portfolio/CARD DESIGNS/11.jpg'
import CardDesign12 from './images/portfolio/CARD DESIGNS/12.jpg'
import CardDesign13 from './images/portfolio/CARD DESIGNS/13.jpg'
import CardDesign14 from './images/portfolio/CARD DESIGNS/14.jpg'
import CardDesign15 from './images/portfolio/CARD DESIGNS/15.jpg'
import CardDesign16 from './images/portfolio/CARD DESIGNS/16.jpg'
import CardDesign17 from './images/portfolio/CARD DESIGNS/17.jpg'
import CardDesign18 from './images/portfolio/CARD DESIGNS/18.jpg'
import CardDesign19 from './images/portfolio/CARD DESIGNS/19.jpg'
import CardDesign20 from './images/portfolio/CARD DESIGNS/20.jpg'
import CardDesign21 from './images/portfolio/CARD DESIGNS/21.jpg'
import CardDesign22 from './images/portfolio/CARD DESIGNS/22.jpg'
import CardDesign23 from './images/portfolio/CARD DESIGNS/23.jpg'


const FullScreenImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  background-color: transparent;
`;

const FullScreenImage2 = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  background-color: transparent;
  width: 100%; /* Set the width to 100% to fill the modal */
  height: 100%; /* Set the height to 100% to fill the modal */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 3px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: black;
  cursor: pointer;
`;
const CloseButton2 = styled.button`
  position: absolute;
  top: 3px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
`;

function Portfolio()
{
    const [showReviewProductPhotography,setshowReviewProductPhotography]=useState(false);
    const [showReviewStorefront,setshowReviewStorefront]=useState(false);
    const [showLogoDesign,setshowLogoDesign]=useState(false);
    const [showEBC,setshowEBC]=useState(false);
    const [showListingImages,setshowListingImages]=useState(false);
    const [showCardDesign,setshowCardDesign]=useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalIsOpen2, setModalIsOpen2] = useState(false);
    const [selectedImageSrc, setSelectedImageSrc] = useState(null);


    const handleImageClick = (event) => {
    setSelectedImageSrc(event.target.src);
    setModalIsOpen(true);
  }

  const handleCloseModal = () => {
    setModalIsOpen(false);
  }


  const handleImageClick2 = (event) => {
    setSelectedImageSrc(event.target.src);
    setModalIsOpen2(true);
  }

  const handleCloseModal2 = () => {
    setModalIsOpen2(false);
  }
    const { t } = useTranslation('translation');
    const { t: currencyT } = useTranslation('currency');

  const changer =()=>
  {
    if(showReviewProductPhotography===true){
        setshowReviewProductPhotography(false);
        return;
    }
    else{
    setshowReviewProductPhotography(true);
    return;
  }
}
  const changer2 =()=>
  {
    if(showReviewStorefront===true){
        setshowReviewStorefront(false);
        return;
    }
    else{
    setshowReviewStorefront(true);
    return;
  }
}
  const changer3 =()=>
  {
    if(showLogoDesign===true){
        setshowLogoDesign(false);
        return;
    }
    else{
    setshowLogoDesign(true);
    return;
  }
}
  const changer4 =()=>
  {
    if(showEBC===true){
        setshowEBC(false);
        return;
    }
    else{
    setshowEBC(true);
    return;
  }
}

const changer5 =()=>
{
  if(showListingImages===true){
      setshowListingImages(false);
      return;
  }
  else{
  setshowListingImages(true);
  return;
}
}
const changer6 =()=>
{
  if(showCardDesign===true){
      setshowCardDesign(false);
      return;
  }
  else{
  setshowCardDesign(true);
  return;
}
}
const handleContextMenu = (e) => {
  e.preventDefault(); // Prevent default behavior of the right-click event
};

    return(
        
<>
<ScrollIndicatorPage/>
<Modal
  isOpen={modalIsOpen}
  onRequestClose={handleCloseModal}
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    content: {
    //   position: 'absolute',
      top: '55%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '100%',
      maxHeight: '100%',
      width: '639.5px', // added
      height: '433.5px', // added
      padding: '0px',
      border: 'none',
      borderRadius: '0',
      background: 'transparent' // added

    },
  }}
  
>
  <CloseButton  onClick={handleCloseModal}>X</CloseButton>
  <FullScreenImage onContextMenu={handleContextMenu} src={selectedImageSrc} />
</Modal>


<Modal
  isOpen={modalIsOpen2}
  onRequestClose={handleCloseModal2}
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    content: {
      top: '55%',
      left: '68%',
      transform: 'translate(-78%, -50%)',
      maxWidth: '100%',
      maxHeight: '100%',
      width: '800px', // Adjust the width as desired
      height: '600px', // Adjust the height as desired
      padding: '0px',
      border: 'none',
      borderRadius: '0',
      background: 'transparent',
    },
  }}
  
>
  <CloseButton2  onClick={handleCloseModal2}>X</CloseButton2>
  <FullScreenImage2 onContextMenu={handleContextMenu} src={selectedImageSrc} />
</Modal>

<div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-orange-500">Grow<span className='black-color'>phics</span></h2>
              <p className="mt-2 text-3xl font-bold  tracking-tight text-orange-400 sm:text-4xl">{t("Portfolio")}</p>
              <p className="mt-6 text-lg font-bold leading-8 text-orange-400">
              {t("Portfolio Description1")}
              </p>
              <p className='black-color font-semibold'>{t("Portfolio Description2")}</p>
              <br/>
              <p className='black-color font-semibold'>{t("Portfolio Description3")}</p>
            </div>
          </div>
          <img onContextMenu={handleContextMenu}
            src="https://fiverr-res.cloudinary.com/images/q_auto,f_auto/gigs/282688297/original/a0511120381d2ddcdc7504330c2c6217cb655161/design-amazon-product-listing-images-that-convert.jpg"
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>

    <div className="bg-changer py-18 sm:py-2" id="PPD">
      <br></br>
       {/* PACKAGIN DESIGN PHOTOGRAPHY */}

       <div className=" py-24 sm:py-8" id="PP">

<div className="mx-auto max-w-7xl px-8 lg:px-18">
  <h2 className="text-center portfolio_headings font-semibold leading-8 text-orange-400">
  {t("Product Packaging Designs")}
  </h2>

  <br></br>
  {/* <p className='text-orange-400 font-semibold'> {t("PRODUCT PACKAGING DESIGN Description")}</p> */}
          {/* EDITING PP */}
  <br/>
  <button onClick={()=>changer()} className="color_code_button inline-block px-7 py-3 font-medium text-sm leading-snug uppercase rounded shadow-md  hover:shadow-lg  active:shadow-lg transition duration-150 ease-in-out">
      {showReviewProductPhotography ? <span>{t("Show Less")}</span> : <span>{t("Show All")}</span>}
    </button>
  <div className="mx-auto ">
<br/>
{showReviewProductPhotography ?
  <>
  <span className='text-black font-bold'>Scroll down to view more</span>
  </>
:<></>
}
<div className="flex items-center justify-center w-full h-full py-48 sm:py-8 px-4 bg-gray-900 container_border">
{/* FOR WEB */}
{showReviewProductPhotography ?
<>
<div className='flex flex-col pp_height overflow-x-auto mb-4 '>
 <div className="mt-2 grid grid-cols-1 gap-x-2 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8 ">
    {products.map((product) => (
      <div key={product.id} className="group relative">
        <div className="min-h-40 aspect-h-24 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-full">
          <img onContextMenu={handleContextMenu}
              onClick={handleImageClick}
            src={product.src}
            className="h-full w-full object-cover object-center lg:h-full lg:w-full"
          />
        </div>
      </div>
    ))}
  </div>
  </div>
</>
:
<>
<CarouselProvider className="lg:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={10} visibleSlides={4} step={1} infinite={true}>
  <div className="w-full relative flex items-center justify-center">
  {!modalIsOpen && (
    <>
      <ButtonBack 
                    role="button" 
                    aria-label="slide backward" 
                    className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                    id="prev"
                  >
                      <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                  </ButtonBack>
      </>
  )}
  
      <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">

          <Slider>
              <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                  <Slide index={0}>
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src={product_packaging_image_1}
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>

                  <Slide index={1}>
                      
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src={product_packaging_image_86}
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>

                  <Slide index={2}>                                       
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src={product_packaging_image_3}
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>
                  <Slide index={3}>                                       
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src={product_packaging_image_4}
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>
                  <Slide index={4}>                                       
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src={product_packaging_image_5}
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>
                  <Slide index={5}>                                       
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src={product_packaging_image_87}
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>
                  <Slide index={6}>                                       
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src={product_packaging_image_7}
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>
                  <Slide index={7}>                                       
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src={product_packaging_image_8}
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>
                  <Slide index={8}>                                       
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src={product_packaging_image_9}
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>
                  <Slide index={9}>                                       
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src={product_packaging_image_10}
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>
        
              </div>
          </Slider>
      </div>
      
      {!modalIsOpen && (
    <>
   <ButtonNext role="button" aria-label="slide forward" 
                    className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                  >
                      <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      
                  </ButtonNext>
      </>
  )}

  </div>
  

</CarouselProvider>
{/* FOR TAB */}
<CarouselProvider className="lg:hidden md:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={12} visibleSlides={2} step={1} infinite={true}>
<div className="w-full relative flex items-center justify-center">
       {!modalIsOpen && (
    <>
      <ButtonBack 
                    role="button" 
                    aria-label="slide backward" 
                    className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                    id="prev"
                  >
                      <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                  </ButtonBack>
      </>
  )}
      <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
          <Slider>
              <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                  <Slide index={0}>
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/171664213/original/929084264b492316c64db559bd958386dfe7eb5c/do-amazon-product-photography-editing-product-infographic.png"
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>

                  <Slide index={1}>
                      
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs3/171664213/original/c3837cc7268a8645530273b3f301d044c3a01a5c/do-amazon-product-photography-editing-product-infographic.png"
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>

                  <Slide index={2}>                                       
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src="https://fiverr-res.cloudinary.com/images/t_smartwm/t_gig_pdf_gallery_view_ver4,q_auto,f_auto/attachments/delivery/asset/8585d444974aec39b18e811cb1cbba6b-1679825598/Preview-Eldotrado-Pdf/do-amazon-product-photography-editing-product-infographic.pdf"
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>
        
              </div>
          </Slider>
      </div>
       {!modalIsOpen && (
    <>
   <ButtonNext role="button" aria-label="slide forward" 
                    className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                  >
                      <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      
                  </ButtonNext>
      </>
  )}
  </div>
</CarouselProvider>
{/* FOR MOBILE */}
<CarouselProvider className="block md:hidden " naturalSlideWidth={20} isIntrinsicHeight={true} totalSlides={3} visibleSlides={1} step={1} infinite={true}>
<div className="w-full relative flex items-center justify-center">
       {!modalIsOpen && (
    <>
      <ButtonBack 
                    role="button" 
                    aria-label="slide backward" 
                    className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                    id="prev"
                  >
                      <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                  </ButtonBack>
      </>
  )}
      <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
          <Slider>
              <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                  <Slide index={0}>
                      <div className="flex flex-shrink-0 relative w-full sm:w-full">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/171664213/original/929084264b492316c64db559bd958386dfe7eb5c/do-amazon-product-photography-editing-product-infographic.png"
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>

                  <Slide index={1}>
                      
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs3/171664213/original/c3837cc7268a8645530273b3f301d044c3a01a5c/do-amazon-product-photography-editing-product-infographic.png"
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>

                  <Slide index={2}>                                       
                      <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                      <img onContextMenu={handleContextMenu}
                      className='hoverer'
                      src="https://fiverr-res.cloudinary.com/images/t_smartwm/t_gig_pdf_gallery_view_ver4,q_auto,f_auto/attachments/delivery/asset/8585d444974aec39b18e811cb1cbba6b-1679825598/Preview-Eldotrado-Pdf/do-amazon-product-photography-editing-product-infographic.pdf"
                      onClick={handleImageClick}/>
                      </div>
                  </Slide>
        
              </div>
          </Slider>
      </div>
       {!modalIsOpen && (
    <>
   <ButtonNext role="button" aria-label="slide forward" 
                    className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                  >
                      <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      
                  </ButtonNext>
      </>
  )}
  </div>
</CarouselProvider>
</>


}
</div>

</div>
</div>

<br></br>
</div>
    </div>
<br></br>
<br></br>

{/* INSERT CARD DESIGN */}
    <div className="bg-white py-24 sm:py-8" id="ICD">
      <div className="mx-auto max-w-7xl px-8 lg:px-18">
        <h2 className="text-center portfolio_headings font-semibold leading-8 text-orange-400">
        {t("Insert Cards/Books Design")}
        </h2>
        <br></br>
        {/* <p className='text-orange-400 font-semibold'> {t("INSERT CARD DESIGN Description")}</p> */}
        <div className="mx-auto">
                {/* EDITING PP */}
                <br/>

        <button onClick={()=>changer6()} className="color_code_button inline-block px-7 py-3 font-medium text-sm leading-snug uppercase rounded shadow-md  hover:shadow-lg  active:shadow-lg transition duration-150 ease-in-out">
            {showCardDesign ? <span>{t("Show Less")}</span> : <span>{t("Show All")}</span>}
          </button>
        <div className="mx-auto ">
<br/>
{showCardDesign ?
  <>
  <span className='text-black font-bold'>Scroll down to view more</span>
  </>
:<></>
}
<div className="flex items-center justify-center w-full h-full py-48 sm:py-8 px-4 bg-gray-900 container_border">
  {/* FOR WEB */}
  {showCardDesign ?
  <>
  <div className='flex flex-col pp_height overflow-x-auto mb-4 '>
       <div className="mt-2 grid grid-cols-1 gap-x-2 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8 ">
          {CardDesign.map((product) => (
            <div key={product.id} className="group relative">
              <div className="min-h-40 aspect-h-24 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-full">
                <img onContextMenu={handleContextMenu}
                    onClick={handleImageClick}
                  src={product.src}
                  className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                />
              </div>
            </div>
          ))}
        </div>
        </div>
      </>
  :
  <>
    <CarouselProvider className="lg:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={10} visibleSlides={4} step={1} infinite={true}>
        <div className="w-full relative flex items-center justify-center">
        {!modalIsOpen && (
          <>
            <ButtonBack 
                          role="button" 
                          aria-label="slide backward" 
                          className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                          id="prev"
                        >
                            <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
            </>
        )}
        
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
     
                <Slider>
                    <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                        <Slide index={0}>
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={CardDesign1}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={1}>
                            
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={CardDesign2}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={2}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={CardDesign3}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={3}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={CardDesign4}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={4}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={CardDesign5}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={5}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={CardDesign6}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={6}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={CardDesign7}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={7}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={CardDesign8}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={8}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={CardDesign9}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={9}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={CardDesign10}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
              
                    </div>
                </Slider>
            </div>
            
            {!modalIsOpen && (
          <>
         <ButtonNext role="button" aria-label="slide forward" 
                          className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                        >
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            
                        </ButtonNext>
            </>
        )}
  
        </div>
        

    </CarouselProvider>


    {/* FOR TAB */}
    <CarouselProvider className="lg:hidden md:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={12} visibleSlides={2} step={1} infinite={true}>
    <div className="w-full relative flex items-center justify-center">
             {!modalIsOpen && (
          <>
            <ButtonBack 
                          role="button" 
                          aria-label="slide backward" 
                          className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                          id="prev"
                        >
                            <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
            </>
        )}
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                <Slider>
                    <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                        <Slide index={0}>
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/171664213/original/929084264b492316c64db559bd958386dfe7eb5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={1}>
                            
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs3/171664213/original/c3837cc7268a8645530273b3f301d044c3a01a5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={2}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_smartwm/t_gig_pdf_gallery_view_ver4,q_auto,f_auto/attachments/delivery/asset/8585d444974aec39b18e811cb1cbba6b-1679825598/Preview-Eldotrado-Pdf/do-amazon-product-photography-editing-product-infographic.pdf"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
              
                    </div>
                </Slider>
            </div>
             {!modalIsOpen && (
          <>
         <ButtonNext role="button" aria-label="slide forward" 
                          className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                        >
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            
                        </ButtonNext>
            </>
        )}
        </div>
    </CarouselProvider>
    {/* FOR MOBILE */}
    <CarouselProvider className="block md:hidden " naturalSlideWidth={20} isIntrinsicHeight={true} totalSlides={3} visibleSlides={1} step={1} infinite={true}>
    <div className="w-full relative flex items-center justify-center">
             {!modalIsOpen && (
          <>
            <ButtonBack 
                          role="button" 
                          aria-label="slide backward" 
                          className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                          id="prev"
                        >
                            <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
            </>
        )}
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                <Slider>
                    <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                        <Slide index={0}>
                            <div className="flex flex-shrink-0 relative w-full sm:w-full">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/171664213/original/929084264b492316c64db559bd958386dfe7eb5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={1}>
                            
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs3/171664213/original/c3837cc7268a8645530273b3f301d044c3a01a5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={2}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_smartwm/t_gig_pdf_gallery_view_ver4,q_auto,f_auto/attachments/delivery/asset/8585d444974aec39b18e811cb1cbba6b-1679825598/Preview-Eldotrado-Pdf/do-amazon-product-photography-editing-product-infographic.pdf"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
              
                    </div>
                </Slider>
            </div>
             {!modalIsOpen && (
          <>
         <ButtonNext role="button" aria-label="slide forward" 
                          className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                        >
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            
                        </ButtonNext>
            </>
        )}
        </div>
    </CarouselProvider>
    </> 

}
</div>
</div>
</div>
</div>      
</div>



{/* LISTING IMAGE DESIGN */}


<br/>
<br/>
<br/>
<div className="bg-changer py-24 sm:py-8" id="LIC">
<br/>
<br/>
      <div className="mx-auto max-w-7xl px-6 lg:px-18">
        <h2 className="text-center portfolio_headings font-semibold leading-8 text-orange-400">
        {t("Listing Images")}
        </h2>
        <br></br>
        {/* <p className='text-orange-400'>{t("Listing Images Creation Description")}</p> */}
        <div className="mx-auto">
                {/* EDITING PP */}
                <br/>

        <button onClick={()=>changer5()} className="color_code_button inline-block px-7 py-3 font-medium text-sm leading-snug uppercase rounded shadow-md  hover:shadow-lg  active:shadow-lg transition duration-150 ease-in-out">
            {showListingImages ? <span>{t("Show Less")}</span> : <span>{t("Show All")}</span>}
          </button>
        <div className="mx-auto ">
<br/>
{showListingImages ?
  <>
  <span className='text-black font-bold'>Scroll down to view more</span>
  </>
:<></>
}
<div className="flex items-center justify-center w-full h-full py-48 sm:py-8 px-4 bg-gray-900 container_border">
  {/* FOR WEB */}
  {showListingImages ?
  <>
  <div className='flex flex-col pp_height overflow-x-auto mb-4 '>
       <div className="mt-2 grid grid-cols-1 gap-x-2 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8 ">
          {ListingImages.map((product) => (
            <div key={product.id} className="group relative">
              <div className="min-h-40 aspect-h-24 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-full">
                <img onContextMenu={handleContextMenu}
                    onClick={handleImageClick}
                  src={product.src}
                  className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                />
              </div>
            </div>
          ))}
        </div>
        </div>
      </>
  :
  <>
    <CarouselProvider className="lg:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={10} visibleSlides={4} step={1} infinite={true}>
        <div className="w-full relative flex items-center justify-center">
        {!modalIsOpen && (
          <>
            <ButtonBack 
                          role="button" 
                          aria-label="slide backward" 
                          className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                          id="prev"
                        >
                            <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
            </>
        )}
        
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
     
                <Slider>
                    <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                        <Slide index={0}>
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={ListingImages1}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={1}>
                            
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={ListingImages2}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={2}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={ListingImages3}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={3}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={ListingImages4}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={4}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={ListingImages5}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={5}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={ListingImages6}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={6}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={ListingImages7}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={7}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={ListingImages8}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={8}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={ListingImages9}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={9}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={ListingImages10}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
              
                    </div>
                </Slider>
            </div>
            
            {!modalIsOpen && (
          <>
         <ButtonNext role="button" aria-label="slide forward" 
                          className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                        >
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            
                        </ButtonNext>
            </>
        )}
  
        </div>
        

    </CarouselProvider>


    {/* FOR TAB */}
    <CarouselProvider className="lg:hidden md:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={12} visibleSlides={2} step={1} infinite={true}>
    <div className="w-full relative flex items-center justify-center">
             {!modalIsOpen && (
          <>
            <ButtonBack 
                          role="button" 
                          aria-label="slide backward" 
                          className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                          id="prev"
                        >
                            <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
            </>
        )}
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                <Slider>
                    <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                        <Slide index={0}>
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/171664213/original/929084264b492316c64db559bd958386dfe7eb5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={1}>
                            
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs3/171664213/original/c3837cc7268a8645530273b3f301d044c3a01a5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={2}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_smartwm/t_gig_pdf_gallery_view_ver4,q_auto,f_auto/attachments/delivery/asset/8585d444974aec39b18e811cb1cbba6b-1679825598/Preview-Eldotrado-Pdf/do-amazon-product-photography-editing-product-infographic.pdf"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
              
                    </div>
                </Slider>
            </div>
             {!modalIsOpen && (
          <>
         <ButtonNext role="button" aria-label="slide forward" 
                          className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                        >
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            
                        </ButtonNext>
            </>
        )}
        </div>
    </CarouselProvider>
    {/* FOR MOBILE */}
    <CarouselProvider className="block md:hidden " naturalSlideWidth={20} isIntrinsicHeight={true} totalSlides={3} visibleSlides={1} step={1} infinite={true}>
    <div className="w-full relative flex items-center justify-center">
             {!modalIsOpen && (
          <>
            <ButtonBack 
                          role="button" 
                          aria-label="slide backward" 
                          className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                          id="prev"
                        >
                            <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
            </>
        )}
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                <Slider>
                    <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                        <Slide index={0}>
                            <div className="flex flex-shrink-0 relative w-full sm:w-full">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/171664213/original/929084264b492316c64db559bd958386dfe7eb5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={1}>
                            
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs3/171664213/original/c3837cc7268a8645530273b3f301d044c3a01a5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={2}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_smartwm/t_gig_pdf_gallery_view_ver4,q_auto,f_auto/attachments/delivery/asset/8585d444974aec39b18e811cb1cbba6b-1679825598/Preview-Eldotrado-Pdf/do-amazon-product-photography-editing-product-infographic.pdf"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
              
                    </div>
                </Slider>
            </div>
             {!modalIsOpen && (
          <>
         <ButtonNext role="button" aria-label="slide forward" 
                          className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                        >
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            
                        </ButtonNext>
            </>
        )}
        </div>
    </CarouselProvider>
    </> 

}
</div>

    
</div>

</div>
      </div>
      <br/>
      <br/>
      <br/>
    </div>
<br></br>
<br></br>
{/* EBC A */}
<div className="bg-white py-24 sm:py-8" id="EACI">
      <div className="mx-auto max-w-7xl px-8 lg:px-18">
        <h2 className="text-center portfolio_headings font-semibold leading-8 text-orange-400">
        {t("EBC/A+ Content Images")}
        </h2>
        <br></br>
        {/* <p className='text-orange-400 font-semibold'> {t("EBC/A+ Content Images Description")}</p> */}
        <div className="mx-auto">
                {/* EDITING PP */}
                <br/>
        <button onClick={()=>changer4()} className="color_code_button inline-block px-7 py-3 font-medium text-sm leading-snug uppercase rounded shadow-md  hover:shadow-lg  active:shadow-lg transition duration-150 ease-in-out">
            {showEBC ? <span>{t("Show Less")}</span> : <span>{t("Show All")}</span>}
          </button>
        <div className="mx-auto ">
<br/>
{showEBC ?
  <>
  <span className='text-black font-bold'>Scroll down to view more</span>
  </>
:<></>
}
<div className="flex items-center justify-center w-full h-full py-48 sm:py-8 px-4 bg-gray-900 container_border">
  {/* FOR WEB */}
  {showEBC ?
  <>
  <div className='flex flex-col pp_height overflow-x-auto mb-4 '>
       <div className="mt-2 grid grid-cols-1 gap-x-2 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8 ">
          {EBC.map((product) => (
            <div key={product.id} className="group relative">
              <div className="min-h-40 aspect-h-24 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-full">
                <img onContextMenu={handleContextMenu}
                    onClick={handleImageClick2}
                  src={product.src}
                  className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                />
              </div>
            </div>
          ))}
        </div>
        </div>
      </>
  :
  <>
    <CarouselProvider className="lg:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={10} visibleSlides={4} step={1} infinite={true}>
        <div className="w-full relative flex items-center justify-center">
        {!modalIsOpen && (
          <>
            <ButtonBack 
                          role="button" 
                          aria-label="slide backward" 
                          className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                          id="prev"
                        >
                            <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
            </>
        )}
        
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
     
                <Slider>
                    <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                        <Slide index={0}>
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={EBC_1}
                            onClick={handleImageClick2}/>
                            </div>
                        </Slide>

                        <Slide index={1}>
                            
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={EBC_2}
                            onClick={handleImageClick2}/>
                            </div>
                        </Slide>

                        <Slide index={2}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={EBC_3}
                            onClick={handleImageClick2}/>
                            </div>
                        </Slide>
                        <Slide index={3}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={EBC_4}
                            onClick={handleImageClick2}/>
                            </div>
                        </Slide>
                        <Slide index={4}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={EBC_5}
                            onClick={handleImageClick2}/>
                            </div>
                        </Slide>
                        <Slide index={5}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={EBC_6}
                            onClick={handleImageClick2}/>
                            </div>
                        </Slide>
                        <Slide index={6}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={EBC_7}
                            onClick={handleImageClick2}/>
                            </div>
                        </Slide>
                        <Slide index={7}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={EBC_8}
                            onClick={handleImageClick2}/>
                            </div>
                        </Slide>
                        <Slide index={8}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={EBC_9}
                            onClick={handleImageClick2}/>
                            </div>
                        </Slide>
                        <Slide index={9}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={EBC_10}
                            onClick={handleImageClick2}/>
                            </div>
                        </Slide>
              
                    </div>
                </Slider>
            </div>
            
            {!modalIsOpen && (
          <>
         <ButtonNext role="button" aria-label="slide forward" 
                          className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                        >
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            
                        </ButtonNext>
            </>
        )}
  
        </div>
        

    </CarouselProvider>


    {/* FOR TAB */}
    <CarouselProvider className="lg:hidden md:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={12} visibleSlides={2} step={1} infinite={true}>
    <div className="w-full relative flex items-center justify-center">
             {!modalIsOpen && (
          <>
            <ButtonBack 
                          role="button" 
                          aria-label="slide backward" 
                          className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                          id="prev"
                        >
                            <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
            </>
        )}
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                <Slider>
                    <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                        <Slide index={0}>
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/171664213/original/929084264b492316c64db559bd958386dfe7eb5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={1}>
                            
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs3/171664213/original/c3837cc7268a8645530273b3f301d044c3a01a5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={2}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_smartwm/t_gig_pdf_gallery_view_ver4,q_auto,f_auto/attachments/delivery/asset/8585d444974aec39b18e811cb1cbba6b-1679825598/Preview-Eldotrado-Pdf/do-amazon-product-photography-editing-product-infographic.pdf"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
              
                    </div>
                </Slider>
            </div>
             {!modalIsOpen && (
          <>
         <ButtonNext role="button" aria-label="slide forward" 
                          className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                        >
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            
                        </ButtonNext>
            </>
        )}
        </div>
    </CarouselProvider>
    {/* FOR MOBILE */}
    <CarouselProvider className="block md:hidden " naturalSlideWidth={20} isIntrinsicHeight={true} totalSlides={3} visibleSlides={1} step={1} infinite={true}>
    <div className="w-full relative flex items-center justify-center">
             {!modalIsOpen && (
          <>
            <ButtonBack 
                          role="button" 
                          aria-label="slide backward" 
                          className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                          id="prev"
                        >
                            <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
            </>
        )}
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                <Slider>
                    <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                        <Slide index={0}>
                            <div className="flex flex-shrink-0 relative w-full sm:w-full">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/171664213/original/929084264b492316c64db559bd958386dfe7eb5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={1}>
                            
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs3/171664213/original/c3837cc7268a8645530273b3f301d044c3a01a5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={2}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_smartwm/t_gig_pdf_gallery_view_ver4,q_auto,f_auto/attachments/delivery/asset/8585d444974aec39b18e811cb1cbba6b-1679825598/Preview-Eldotrado-Pdf/do-amazon-product-photography-editing-product-infographic.pdf"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
              
                    </div>
                </Slider>
            </div>
             {!modalIsOpen && (
          <>
         <ButtonNext role="button" aria-label="slide forward" 
                          className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                        >
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            
                        </ButtonNext>
            </>
        )}
        </div>
    </CarouselProvider>
    </> 

}
</div>

    
</div>

</div>
</div>
    </div>

    {/* BRAND LOGO DESIGN */}

    <div className="bg-changer py-24 sm:py-8" id="BLD">
      <br/>
      <br/>
      <div className="mx-auto max-w-7xl px-6 lg:px-18">
        <h2 className="text-center portfolio_headings font-semibold leading-8 text-orange-400">
        {t("Brand Logo Design")}
                </h2>
        <br></br>
        {/* <p className='text-orange-400'>{t("Brand Logo Design Description")}</p> */}
        <div className="mx-auto">
                {/* EDITING PP */}
                
        <button onClick={()=>changer3()} className="color_code_button inline-block px-7 py-3 font-medium text-sm leading-snug uppercase rounded shadow-md  hover:shadow-lg  active:shadow-lg transition duration-150 ease-in-out">
            {showLogoDesign ? <span>{t("Show Less")}</span> : <span>{t("Show All")}</span>}
          </button>
        <div className="mx-auto ">
<br/>
<br/>
                {showLogoDesign ?
  <>
  <span className='text-black font-bold'>Scroll down to view more</span>
  </>
:<></>
}
<div className="flex items-center justify-center w-full h-full py-48 sm:py-8 px-4 bg-gray-900 container_border">
  {/* FOR WEB */}
  {showLogoDesign ?
  <>
  <div className='flex flex-col pp_height overflow-x-auto mb-4 '>
       <div className="mt-2 grid grid-cols-1 gap-x-2 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8 ">
          {logo_design.map((product) => (
            <div key={product.id} className="group relative">
              <div className="min-h-40 aspect-h-24 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-full">
                <img onContextMenu={handleContextMenu}
                    onClick={handleImageClick}
                  src={product.src}
                  className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                />
              </div>
            </div>
          ))}
        </div>
        </div>
      </>
  :
  <>
    <CarouselProvider className="lg:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={10} visibleSlides={4} step={1} infinite={true}>
        <div className="w-full relative flex items-center justify-center">
        {!modalIsOpen && (
          <>
            <ButtonBack 
                          role="button" 
                          aria-label="slide backward" 
                          className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                          id="prev"
                        >
                            <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
            </>
        )}
        
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
     
                <Slider>
                    <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                        <Slide index={0}>
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={logo_design_1}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={1}>
                            
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={logo_design_2}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={2}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={logo_design_3}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={3}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={logo_design_4}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={4}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={logo_design_5}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={5}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={logo_design_6}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={6}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={logo_design_7}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={7}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={logo_design_8}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={8}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={logo_design_9}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={9}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={logo_design_10}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
              
                    </div>
                </Slider>
            </div>
            
            {!modalIsOpen && (
          <>
         <ButtonNext role="button" aria-label="slide forward" 
                          className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                        >
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            
                        </ButtonNext>
            </>
        )}
  
        </div>
        

    </CarouselProvider>


    {/* FOR TAB */}
    <CarouselProvider className="lg:hidden md:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={12} visibleSlides={2} step={1} infinite={true}>
    <div className="w-full relative flex items-center justify-center">
             {!modalIsOpen && (
          <>
            <ButtonBack 
                          role="button" 
                          aria-label="slide backward" 
                          className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                          id="prev"
                        >
                            <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
            </>
        )}
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                <Slider>
                    <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                        <Slide index={0}>
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/171664213/original/929084264b492316c64db559bd958386dfe7eb5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={1}>
                            
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs3/171664213/original/c3837cc7268a8645530273b3f301d044c3a01a5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={2}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_smartwm/t_gig_pdf_gallery_view_ver4,q_auto,f_auto/attachments/delivery/asset/8585d444974aec39b18e811cb1cbba6b-1679825598/Preview-Eldotrado-Pdf/do-amazon-product-photography-editing-product-infographic.pdf"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
              
                    </div>
                </Slider>
            </div>
             {!modalIsOpen && (
          <>
         <ButtonNext role="button" aria-label="slide forward" 
                          className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                        >
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            
                        </ButtonNext>
            </>
        )}
        </div>
    </CarouselProvider>
    {/* FOR MOBILE */}
    <CarouselProvider className="block md:hidden " naturalSlideWidth={20} isIntrinsicHeight={true} totalSlides={3} visibleSlides={1} step={1} infinite={true}>
    <div className="w-full relative flex items-center justify-center">
             {!modalIsOpen && (
          <>
            <ButtonBack 
                          role="button" 
                          aria-label="slide backward" 
                          className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                          id="prev"
                        >
                            <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
            </>
        )}
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                <Slider>
                    <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                        <Slide index={0}>
                            <div className="flex flex-shrink-0 relative w-full sm:w-full">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/171664213/original/929084264b492316c64db559bd958386dfe7eb5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={1}>
                            
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs3/171664213/original/c3837cc7268a8645530273b3f301d044c3a01a5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={2}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_smartwm/t_gig_pdf_gallery_view_ver4,q_auto,f_auto/attachments/delivery/asset/8585d444974aec39b18e811cb1cbba6b-1679825598/Preview-Eldotrado-Pdf/do-amazon-product-photography-editing-product-infographic.pdf"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
              
                    </div>
                </Slider>
            </div>
             {!modalIsOpen && (
          <>
         <ButtonNext role="button" aria-label="slide forward" 
                          className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                        >
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            
                        </ButtonNext>
            </>
        )}
        </div>
    </CarouselProvider>
    </> 

}
</div>

    
</div>

</div>
      </div>
      <br/>
      <br/>
    </div>

    {/* AMAZON STOREFRONT DESIGN */}

    
    <div className="bg-white py-24 sm:py-8" id="ASD">
      <div className="mx-auto max-w-7xl px-8 lg:px-18">
        <h2 className="text-center portfolio_headings font-semibold leading-8 text-orange-400">
        {t("Amazon Storefront Design")}
        </h2>
        <br></br>
        {/* <p className='text-orange-400 font-semibold'> {t("AMAZON STOREFRONT DESIGN Description")}</p> */}
        <div className="mx-auto">
                {/* EDITING PP */}
                <br/>
        <button onClick={()=>changer2()} className="color_code_button inline-block px-7 py-3 font-medium text-sm leading-snug uppercase rounded shadow-md  hover:shadow-lg  active:shadow-lg transition duration-150 ease-in-out">
            {showReviewStorefront ? <span>{t("Show Less")}</span> : <span>{t("Show All")}</span>}
          </button>
        <div className="mx-auto ">
<br/>
{showReviewStorefront ?
  <>
  <span className='text-black font-bold'>Scroll down to view more</span>
  </>
:<></>
}
<div className="flex items-center justify-center w-full h-full py-48 sm:py-8 px-4 bg-gray-900 container_border">
  {/* FOR WEB */}
  {showReviewStorefront ?
  <>
  <div className='flex flex-col pp_height overflow-x-auto mb-4 '>
       <div className="mt-2 grid grid-cols-1 gap-x-2 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8 ">
          {storefront.map((product) => (
            <div key={product.id} className="group relative">
              <div className="min-h-40 aspect-h-24 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-full">
                <img onContextMenu={handleContextMenu}
                    onClick={handleImageClick}
                  src={product.src}
                  className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                />
              </div>
            </div>
          ))}
        </div>
        </div>
      </>
  :
  <>
    <CarouselProvider className="lg:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={10} visibleSlides={4} step={1} infinite={true}>
        <div className="w-full relative flex items-center justify-center">
        {!modalIsOpen && (
          <>
            <ButtonBack 
                          role="button" 
                          aria-label="slide backward" 
                          className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                          id="prev"
                        >
                            <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
            </>
        )}
        
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
     
                <Slider>
                    <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                        <Slide index={0}>
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={storefront_image_1}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={1}>
                            
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={storefront_image_2}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={2}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={storefront_image_3}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={3}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={storefront_image_4}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={4}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={storefront_image_5}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={5}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={storefront_image_6}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={6}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={storefront_image_7}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={7}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={storefront_image_8}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={8}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={storefront_image_9}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
                        <Slide index={9}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src={storefront_image_10}
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
              
                    </div>
                </Slider>
            </div>
            
            {!modalIsOpen && (
          <>
         <ButtonNext role="button" aria-label="slide forward" 
                          className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                        >
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            
                        </ButtonNext>
            </>
        )}
  
        </div>
        

    </CarouselProvider>


    {/* FOR TAB */}
    <CarouselProvider className="lg:hidden md:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={12} visibleSlides={2} step={1} infinite={true}>
    <div className="w-full relative flex items-center justify-center">
             {!modalIsOpen && (
          <>
            <ButtonBack 
                          role="button" 
                          aria-label="slide backward" 
                          className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                          id="prev"
                        >
                            <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
            </>
        )}
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                <Slider>
                    <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                        <Slide index={0}>
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/171664213/original/929084264b492316c64db559bd958386dfe7eb5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={1}>
                            
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs3/171664213/original/c3837cc7268a8645530273b3f301d044c3a01a5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={2}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_smartwm/t_gig_pdf_gallery_view_ver4,q_auto,f_auto/attachments/delivery/asset/8585d444974aec39b18e811cb1cbba6b-1679825598/Preview-Eldotrado-Pdf/do-amazon-product-photography-editing-product-infographic.pdf"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
              
                    </div>
                </Slider>
            </div>
             {!modalIsOpen && (
          <>
         <ButtonNext role="button" aria-label="slide forward" 
                          className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                        >
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            
                        </ButtonNext>
            </>
        )}
        </div>
    </CarouselProvider>
    {/* FOR MOBILE */}
    <CarouselProvider className="block md:hidden " naturalSlideWidth={20} isIntrinsicHeight={true} totalSlides={3} visibleSlides={1} step={1} infinite={true}>
    <div className="w-full relative flex items-center justify-center">
             {!modalIsOpen && (
          <>
            <ButtonBack 
                          role="button" 
                          aria-label="slide backward" 
                          className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                          id="prev"
                        >
                            <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
            </>
        )}
            <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                <Slider>
                    <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                        <Slide index={0}>
                            <div className="flex flex-shrink-0 relative w-full sm:w-full">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/171664213/original/929084264b492316c64db559bd958386dfe7eb5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={1}>
                            
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs3/171664213/original/c3837cc7268a8645530273b3f301d044c3a01a5c/do-amazon-product-photography-editing-product-infographic.png"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>

                        <Slide index={2}>                                       
                            <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                            <img onContextMenu={handleContextMenu}
                            className='hoverer'
                            src="https://fiverr-res.cloudinary.com/images/t_smartwm/t_gig_pdf_gallery_view_ver4,q_auto,f_auto/attachments/delivery/asset/8585d444974aec39b18e811cb1cbba6b-1679825598/Preview-Eldotrado-Pdf/do-amazon-product-photography-editing-product-infographic.pdf"
                            onClick={handleImageClick}/>
                            </div>
                        </Slide>
              
                    </div>
                </Slider>
            </div>
             {!modalIsOpen && (
          <>
         <ButtonNext role="button" aria-label="slide forward" 
                          className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                        >
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            
                        </ButtonNext>
            </>
        )}
        </div>
    </CarouselProvider>
    </> 

}
</div>

    
</div>

</div>
      </div>
    </div>

 
    
    <br></br>
    <br></br>
    <br></br>
    <hr></hr>
</>

    )
}

export default Portfolio;

const products = [
    {
      src: product_packaging_image_1,
    },
    {
      src: product_packaging_image_81,
    },
    {
      src: product_packaging_image_2,
    },
    {
      src: product_packaging_image_3,
    },
    {
      src: product_packaging_image_4,
    },
    {
      src: product_packaging_image_82,
    },
    {
      src: product_packaging_image_5,
    },
    {
      src: product_packaging_image_6,
    },
    {
      src: product_packaging_image_7,
    },
    {
      src: product_packaging_image_8,
    },
    {
      src: product_packaging_image_83,
    },
    {
      src: product_packaging_image_8,
    },
    {
      src: product_packaging_image_9,
    },
    {
      src: product_packaging_image_10,
    },
    {
      src: product_packaging_image_11,
    },
    {
      src: product_packaging_image_12,
    },
    {
      src: product_packaging_image_13,
    },
    {
      src: product_packaging_image_14,
    },
    {
      src: product_packaging_image_84,
    },
    {
      src: product_packaging_image_15,
    },
    {
      src: product_packaging_image_16,
    },
    {
      src: product_packaging_image_17,
    },
    {
      src: product_packaging_image_18,
    },
    {
      src: product_packaging_image_19,
    },
    {
      src: product_packaging_image_20,
    },
    {
      src: product_packaging_image_85,
    },
    {
      src: product_packaging_image_21,
    },
    {
      src: product_packaging_image_22,
    },
    {
      src: product_packaging_image_23,
    },
    {
      src: product_packaging_image_24,
    },
    {
      src: product_packaging_image_25,
    },
    {
      src: product_packaging_image_26,
    },
    {
      src: product_packaging_image_27,
    },
    {
      src: product_packaging_image_28,
    },
    {
      src: product_packaging_image_29,
    },
    {
      src: product_packaging_image_30,
    },
    {
      src: product_packaging_image_31,
    },
    {
      src: product_packaging_image_32,
    },
    {
      src: product_packaging_image_33,
    },
    {
      src: product_packaging_image_34,
    },
    {
      src: product_packaging_image_35,
    },
    {
      src: product_packaging_image_36,
    },
    {
      src: product_packaging_image_37,
    },
    {
      src: product_packaging_image_38,
    },
    {
      src: product_packaging_image_39,
    },
    {
      src: product_packaging_image_40,
    },
    {
      src: product_packaging_image_41,
    },
    {
      src: product_packaging_image_42,
    },
    {
      src: product_packaging_image_43,
    },
    {
      src: product_packaging_image_44,
    },
    {
      src: product_packaging_image_45,
    },
    {
      src: product_packaging_image_46,
    },
    {
      src: product_packaging_image_47,
    },
    {
      src: product_packaging_image_48,
    },
    {
      src: product_packaging_image_49,
    },
    {
      src: product_packaging_image_50,
    },
    {
      src: product_packaging_image_51,
    },
    {
      src: product_packaging_image_52,
    },
    {
      src: product_packaging_image_53,
    },
    {
      src: product_packaging_image_54,
    },
    {
      src: product_packaging_image_55,
    },
    {
      src: product_packaging_image_56,
    },
    {
      src: product_packaging_image_57,
    },
    {
      src: product_packaging_image_58,
    },
    {
      src: product_packaging_image_59,
    },
    {
      src: product_packaging_image_60,
    },
    {
      src: product_packaging_image_61,
    },
    {
      src: product_packaging_image_62,
    },
    {
      src: product_packaging_image_63,
    },
    {
      src: product_packaging_image_64,
    },
    {
      src: product_packaging_image_65,
    },
    {
      src: product_packaging_image_66,
    },
    {
      src: product_packaging_image_67,
    },
    {
      src: product_packaging_image_68,
    },
    {
      src: product_packaging_image_69,
    },
    {
      src: product_packaging_image_70,
    },
    {
      src: product_packaging_image_71,
    },
    {
      src: product_packaging_image_72,
    },
    {
      src: product_packaging_image_73,
    },
    {
      src: product_packaging_image_74,
    },
    {
      src: product_packaging_image_75,
    },
    {
      src: product_packaging_image_76,
    },
    {
      src: product_packaging_image_77,
    },
    {
      src: product_packaging_image_78,
    },

  ]
const logo_design = [
    {
      src: logo_design_1,
    },
    {
      src: logo_design_2,
    },
    {
      src: logo_design_3,
    },
    {
      src: logo_design_4,
    },
    {
      src: logo_design_5,
    },
    {
      src: logo_design_6,
    },
    {
      src: logo_design_7,
    },
    {
      src: logo_design_8,
    },
    {
      src: logo_design_9,
    },
    {
      src: logo_design_10,
    },
    {
      src: logo_design_11,
    },
    {
      src: logo_design_12,
    },
    {
      src: logo_design_13,
    },
    {
      src: logo_design_14,
    },
    {
      src: logo_design_15,
    },
    {
      src: logo_design_16,
    },
    {
      src: logo_design_17,
    },
    {
      src: logo_design_28,
    },
    {
      src: logo_design_19,
    },
    {
      src: logo_design_20,
    },
    {
      src: logo_design_21,
    },
    {
      src: logo_design_22,
    },
    {
      src: logo_design_23,
    },
    {
      src: logo_design_24,
    },
    {
      src: logo_design_25,
    },
    {
      src: logo_design_26,
    },
    {
      src: logo_design_27,
    },

   

  ]
const storefront = [
    {
      src: storefront_image_1,
    },
    {
      src: storefront_image_2,
    },
    {
      src: storefront_image_3,
    },
    {
      src: storefront_image_4,
    },
    {
      src: storefront_image_5,
    },
    {
      src: storefront_image_6,
    },
    {
      src: storefront_image_7,
    },
    {
      src: storefront_image_8,
    },
    {
      src: storefront_image_9,
    },
    {
      src: storefront_image_10,
    },
    {
      src: storefront_image_11,
    },
    {
      src: storefront_image_12,
    },
    {
      src: storefront_image_13,
    },
    {
      src: storefront_image_14,
    },
    {
      src: storefront_image_15,
    },
    {
      src: storefront_image_16,
    },
    {
      src: storefront_image_17,
    },
    {
      src: storefront_image_18,
    },
    {
      src: storefront_image_19,
    },
    {
      src: storefront_image_20,
    },
    {
      src: storefront_image_21,
    },
    {
      src: storefront_image_21,
    },
    {
      src: storefront_image_22,
    },
    {
      src: storefront_image_23,
    },
    {
      src: storefront_image_24,
    },
    {
      src: storefront_image_25,
    },
    {
      src: storefront_image_26,
    },
    {
      src: storefront_image_27,
    },
   

  ]

const EBC = [
    {
      src: EBC_1,
    },
    {
      src: EBC_2,
    },
    {
      src: EBC_3,
    },
    {
      src: EBC_4,
    },
    {
      src: EBC_5,
    },
    {
      src: EBC_6,
    },
    {
      src: EBC_7,
    },
    {
      src: EBC_8,
    },
    {
      src: EBC_9,
    },
    {
      src: EBC_10,
    },
    {
      src: EBC_11,
    },
    {
      src: EBC_12,
    },
    {
      src: EBC_13,
    },
    {
      src: EBC_14,
    },
    {
      src: EBC_15,
    },
    {
      src: EBC_16,
    },
    {
      src: EBC_17,
    },
    {
      src: EBC_18,
    },
    {
      src: EBC_19,
    },
    {
      src: EBC_20,
    },
    {
      src: EBC_21,
    },
    {
      src: EBC_22,
    },
    {
      src: EBC_23,
    },
    {
      src: EBC_24,
    },
 
   

  ]

const ListingImages  = [
    {
      src: ListingImages1,
    },
    {
      src: ListingImages2,
    },
    {
      src: ListingImages3,
    },
    {
      src: ListingImages4,
    },
    {
      src: ListingImages5,
    },
    {
      src: ListingImages6,
    },
    {
      src: ListingImages7,
    },
    {
      src: ListingImages8,
    },
    {
      src: ListingImages9,
    },
    {
      src: ListingImages10,
    },
    {
      src: ListingImages11,
    },
    {
      src: ListingImages12,
    },
    {
      src: ListingImages13,
    },
    {
      src: ListingImages14,
    },
    {
      src: ListingImages15,
    },
    {
      src: ListingImages16,
    },
    {
      src: ListingImages17,
    },
    {
      src: ListingImages18,
    },
    {
      src: ListingImages19,
    },
    {
      src: ListingImages20,
    },
    {
      src: ListingImages21,
    },
    {
      src: ListingImages22,
    },
    {
      src: ListingImages23,
    },
    {
      src: ListingImages24,
    },
    {
      src: ListingImages25,
    },
    {
      src: ListingImages26,
    },
    {
      src: ListingImages27,
    },
    {
      src: ListingImages28,
    },
    {
      src: ListingImages29,
    },
    {
      src: ListingImages30,
    },
    {
      src: ListingImages31,
    },
    {
      src: ListingImages32,
    },
    {
      src: ListingImages33,
    },
    {
      src: ListingImages34,
    },
    {
      src: ListingImages35,
    },
    {
      src: ListingImages36,
    },
    {
      src: ListingImages37,
    },
    {
      src: ListingImages38,
    },
    {
      src: ListingImages39,
    },
    {
      src: ListingImages40,
    },
    {
      src: ListingImages41,
    },
    {
      src: ListingImages42,
    },
    {
      src: ListingImages43,
    },
    {
      src: ListingImages44,
    },
    {
      src: ListingImages45,
    },
    {
      src: ListingImages46,
    },
    {
      src: ListingImages47,
    },
    {
      src: ListingImages48,
    },
    {
      src: ListingImages49,
    },
    {
      src: ListingImages50,
    },
    {
      src: ListingImages51,
    },
    {
      src: ListingImages52,
    },


  ]

const CardDesign = [
    {
      src: CardDesign1,
    },
    {
      src: CardDesign2,
    },
    {
      src: CardDesign3,
    },
    {
      src: CardDesign4,
    },
    {
      src: CardDesign5,
    },
    {
      src: CardDesign6,
    },
    {
      src: CardDesign7,
    },
    {
      src: CardDesign8,
    },
    {
      src: CardDesign9,
    },
    {
      src: CardDesign10,
    },
    {
      src: CardDesign11,
    },
    {
      src: CardDesign12,
    },
    {
      src: CardDesign13,
    },
    {
      src: CardDesign14,
    },
    {
      src: CardDesign15,
    },
    {
      src: CardDesign16,
    },
    {
      src: CardDesign17,
    },
    {
      src: CardDesign18,
    },
    {
      src: CardDesign19,
    },
    {
      src: CardDesign20,
    },
    {
      src: CardDesign21,
    },
    {
      src: CardDesign22,
    },
    {
      src: CardDesign23,
    },

  ]