import React, { useState,useEffect } from 'react';
import {   push,
  serverTimestamp,
  ref, onValue, off,
  child,
  set,
  update,
  get
} from 'firebase/database';
import { Fragment } from 'react';
import { CheckIcon,CalendarIcon, } from '@heroicons/react/20/solid';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import PP from '../browser/images/service_icons/Product-Photoography-icon.png';
import EBC from '../browser/images/service_icons/EBC.png';
import Storefront from '../browser/images/service_icons/Amazon-Storefront-Design-icon.png';
import Logo from '../browser/images/service_icons/Logo-design-icon.png';
import Card from '../browser/images/service_icons/insert-card-icon-1a.png';
import Videography from '../browser/images/service_icons/Product-Videography-icon-1a.png';
import Packaging from '../browser/images/service_icons/Packaging-icon.png';
import { ScrollIndicatorPage } from '../browser/scroll';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { ref as storageRef2 } from 'firebase/storage';
import {  uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firestore.js";
import Picker from 'emoji-picker-react';

  const listItems = {
  item1:{
    name:"Product Photography",
    image:PP
  },
  item2:{
    name:"Packaging Design",
    image:Packaging
  },
  item3:{
    name:"Videography",
    image:Videography
  },
  item4:{
    name:"Enhanced Brand Content",
    image:EBC
  },
  item5:{
    name:"Brand Logo Design",
    image:Logo
  },
  item6:{
    name:"Amazon Storefront Desing",
    image:Storefront
  },
  item7:{
    name:"Insert Cards/Books Design",
    image:Card
  },
};

const services={
  product_photography:{
    "Source File":"Source File",
    "Commercial use":"Commercial use",
    "Conduct research":"Conduct research",
  },
  packaging_design:{
    "Source File":"Source File",
    "Full product wrapping":"Full product wrapping",
    "Print-ready":"Print-ready",
    "Dielines":"Dielines",
    "3D mockup":"3D mockup",
  },
  ebc:{
    "Source File":"Source File",
    "High resolution":"High resolution",
    "Commercial use":"Commercial use",
    "Mockups included":"Mockups included",
  },
  brd:{
    "Source File":"Source File",
    "Logo transparency":"Logo transparency",
    "Vector file":"Vector file",
    "Printable file":"Printable file",
    "3D mockup":"3D mockup",
    "Stationery designs":"Stationery designs",
    "Social media kit":"Social media kit",
  }
  
}

const MessageBox = ({ selectedUser, dbreal,userPictureLink,userImages,uid }) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState(null);

//////////////////////////////// CODE FOR IMAGE UPLOAD //////////////////////////////// 
const [file, setFile] = useState("");

const handleChange = async (event) => {
  try {
    setFile(event.target.files[0]);

    const storageRef = storageRef2(storage, `/users/${uid}/${event.target.files[0]}`);

    const snapshot = await uploadBytes(storageRef, event.target.files[0]);
    console.log('File uploaded successfully');

    // Get the download URL of the uploaded file
    const downloadURL = await getDownloadURL(snapshot.ref);
    console.log('Download URL:', downloadURL);

    const messagesRef = ref(dbreal, `users/${uid}/messages/images`);
    await push(messagesRef, {
      image: downloadURL,
      picture_id: "images",
      createdAt: serverTimestamp(),
      readbyadmin: true,
      readbyuser: false,
      user: "sender",
      email:"behrozgraphics@gmail.com"
    });

  } catch (error) {
    console.error('Error:', error);
  }
};

  //////////////////// IMAGE FUNCTION //////////////////////////////
  

  const [open5, setOpen5] = useState(false);
  const [imageLink, setImageLink] = useState('');
  const image_fun =async(image)=>
  {
    setOpen5(true);
    setImageLink(image);
  }

  //////////////////// THIS IS THE USE STATE OF PART 4 //////////////////////////////
    const [messages, setMessages] = useState([]);
    const [text, setText] = useState('');
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [ServiceName, setServiceName] = useState('');
    const [ServiceImage, setServiceImage] = useState('');
    const listArray = Object.values(listItems);
    
    const [checkedValues, setCheckedValues] = useState([]);
    const [isCheckboxCheckedNumberOfImages, setisCheckboxCheckedNumberOfImages] = useState(false);

    const handleCheckboxChangeNumberOfImages = (event) => {
      setisCheckboxCheckedNumberOfImages(event.target.checked);
    };
  // Event handler for checkbox change
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      setCheckedValues((prevValues) => [...prevValues, value]);
    } else {
      setCheckedValues((prevValues) => prevValues.filter((val) => val !== value));
    }
  };

  const [selectedNumberOfImages, setSelectedNumberOfImages] = useState("");
  const handleSelectChange = (event) => {
    setSelectedNumberOfImages(event.target.value);
  };
  
  // Coupen Radio
  const [isCheckboxCheckedCoupen, setisCheckboxCheckedCoupen] = useState(false);

  const handleCheckboxChangeCoupen = (event) => {
    setisCheckboxCheckedCoupen(event.target.checked);
  };
  
  const [selectedCoupen, setselectedCoupen] = useState("");
  const handleSelectChangesCoupen = (event) => {
    setselectedCoupen(event.target.value);
  };

  // Delivery Radio

  const [selectedDelivery, setselectedDelivery] = useState("");
  const handleSelectChangesDelivery = (event) => {
    setselectedDelivery(event.target.value);
  };

  // Revision Radio

  const [selectedRevision, setselectedRevision] = useState("");
  const handleSelectChangesRevision = (event) => {
    setselectedRevision(event.target.value);
  };

  // Price Radio
  const [price, setPrice] = useState('');

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  // Price Radio
  const [textar, setText2] = useState('');

  const handleTextChange = (event) => {
    setText2(event.target.value);
  };

  // Requirements Radio
  const [isCheckboxCheckedRequirements, setisCheckboxCheckedRequirements] = useState(false);

  const handleCheckboxChangeRequirements = (event) => {
    setisCheckboxCheckedRequirements(event.target.checked);
  };


    useEffect(()  => {
      const messagesRef = ref(dbreal, `users/${selectedUser}/messages`);
    
      const unsubscribe = onValue(messagesRef, async (snapshot) => {
        const messagesData = [];
        const promises = [];

        const data = snapshot.val();
        if (data) {

          Object.keys(data).forEach((key) => {
            console.log(key.email);
            if(key==="images"){
              Object.keys(data[key]).forEach((key2) => {
                if(key2!=="readbyadmin"){
                  messagesData.push({ id: key2, ...data[key][key2] });
                }
            })
            }
            else{
            messagesData.push({ id: key, ...data[key] });
            }
          const documentRef = child(messagesRef, key);
          const promise = update(documentRef, { readbyadmin: true })
            .catch((error) => {
              console.error("Error updating 'readbyadmin' field:", error);
            });
  
          promises.push(promise);
          
          });
          
          await Promise.all(promises);

        }
        // Sort the messages by createdAt timestamp
        messagesData.sort((a, b) => a.createdAt - b.createdAt);
        
        setMessages(messagesData);
      
      });
    
      return () => unsubscribe();
    }, [selectedUser]);
    
  

    ////////////////////////THIS IS FOR SENDING MESSAGE ////////////////////////

  const sendMessage = async () => {
    if (text.trim() === '') return;
    setText('');

    const message = {
      text,
      createdAt: serverTimestamp(),
      user: 'receiver',
      readbyadmin: true,
      readbyuser: false,
      image:userPictureLink,
      email:"behrozegraphics@gmail.com"
    };

    try {
      const messagesRef = ref(dbreal, `users/${uid}/messages`);

      await push(messagesRef, message);
    } catch (error) {
      console.error('Error sending message:', error);
    }

  };

  const [requirements_data, setrequirements_data] = useState([]);
  const [requirements_data2, setrequirements_data2] = useState([]);


      ////////////////////////THIS IS FOR SENDING OFFER ////////////////////////

const uploadsubmit = async(event) => {
  event.preventDefault();


  try {
    const messagesRef = ref(dbreal, `users/${uid}/messages`);
    const messageId = "offer";
    const messageWithCustomId = { ...requirements_data, id: messageId };
  
    const messageSnapshot = await get(child(messagesRef, messageId));
    if (messageSnapshot.exists()) {
      console.log('Message already exists!');
      await update(child(messagesRef, messageId), messageWithCustomId);
      setOpen3(false);
    } else {
      await set(child(messagesRef, messageId), messageWithCustomId);
      console.log('Message sent successfully!');
      setOpen3(false);
    }
  } catch (error) {
    console.error('Error sending message:', error);
  }
  
  




}
const submit =(event) => {
  event.preventDefault();
  if(selectedDelivery==='' || selectedDelivery==='0' )
  {
    alert("Please Choose Delivery Time");
    return;
  }
  if(selectedNumberOfImages==='' || selectedNumberOfImages==='0')
  {
    alert("Please Select the Number of Images");
    return;
  }
  const newData = {
    image:ServiceImage,
    service:ServiceName,
    requirements_text:textar,
    revisions: selectedRevision,
    delivery:selectedDelivery ,
    price:price,
    number_of_image:selectedNumberOfImages,
    checked_box:checkedValues,
    readbyuser:false,
    readbyadmin:true,
    accept:false,
    createdAt:serverTimestamp()
  };

  if(isCheckboxCheckedCoupen===true && selectedCoupen!=="0")
  {
    newData.selectedCoupen = selectedCoupen;
  }
  else if(isCheckboxCheckedCoupen===true && selectedCoupen==="0")
  {
    alert("Please Select the Pecentage of Coupon");
    return;
  }
  else
  {
    newData.selectedCoupen = '0';

  }

  if(isCheckboxCheckedRequirements===true)
  {
    newData.requirements_checked =true;
  }
  else
  {
    newData.requirements_checked = false;

  }

  setrequirements_data(newData);
  setOpen2(false);
  setOpen3(true);

}


const offer_letter=()=>
{
  setServiceImage("");
  setServiceName("");
  setCheckedValues([]);
  setisCheckboxCheckedNumberOfImages(false);
  setisCheckboxCheckedCoupen(false);
  setisCheckboxCheckedRequirements(false);
  setText2("");
  setselectedRevision('');
  setselectedCoupen('');
  setSelectedNumberOfImages('');
  setselectedDelivery('');
  setrequirements_data([]);
  for (let i = 0; i < messages.length; i++) {

    if (messages[i].id === 'offer') {


      const newData = {
        image:messages[i].image,
        service:messages[i].service,
        requirements_text:messages[i].requirements_text,
        revisions: messages[i].revisions,
        delivery:messages[i].delivery,
        price:messages[i].price,
        number_of_image:messages[i].number_of_image,
        checked_box:messages[i].checked_box,
        readbyuser:messages[i].readbyuser,
        accept:messages[i].accept,
        selectedCoupen:messages[i].selectedCoupen,
        requirements_checked:messages[i].requirements_checked,
        email:'behrozgraphics@gmail.com'
      };
      
      setrequirements_data2(newData);
      // alert(JSON.stringify(newData));
      setOpen4(true);

      
      
      
      break;
    }
  }
}


const emojiState=()=>
{
  if(showEmojiPicker==true)
  {
    setShowEmojiPicker(false);
  }
  else
  {
    setShowEmojiPicker(true);
  }
}


  return (
    <>
           <ScrollIndicatorPage/>

        <div className="flex flex-col flex-auto h-full p-6">
        <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full p-4">
          <div className="flex flex-col h-full overflow-x-auto mb-4">
            <div className="flex flex-col h-full">
              <div className="grid grid-cols-12 gap-y-2">
                {messages.map((message) => {
                  
                if (message.id === 'offer') {
                  return (
                    <div key={message.id} className="col-start-6 col-end-13 p-3 rounded-lg">
                    <div className="flex items-center justify-start flex-row-reverse">
                      <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                      <img className='className="flex items-center justify-center h-10 w-10 rounded-full bg-white flex-shrink-0' src={userPictureLink}/>

                      </div>
                      <div className="relative mr-3 text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl">
                      {message.accept ? (
                        <>
                       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <button   className="btn bg-gray-200 text-black hover:bg-orange-200"  onClick={()=>offer_letter()} style={{ display: 'flex', alignItems: 'center' }}>
                        <CheckIcon className="h-5 w-5 flex-none text-orange-400" aria-hidden="true" />&nbsp;
                        <b>Offer Accepted</b>
                      </button>
                      <br/>
                      <span>Click to open</span>
                    </div>


                        </>
                      ) : (
                        <>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <button onClick={()=>offer_letter()} className="btn bg-gray-200 text-black hover:bg-orange-200"  style={{ display: 'flex', alignItems: 'center' }}>
                        <b>Offer Sent</b>
                      </button>
                      <br/>
                      <span>Click to open</span>
                    </div>
                        </>
                      )}

                        </div>
                    </div>
                  </div>
                  );
                } 
                else if (message.picture_id === 'images') {
                  if (message.user === 'user') {

                  return (
                    <div key={message.id} className="col-start-1 col-end-8 p-3 rounded-lg">
                  <div className="flex flex-row items-center">
                  <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                      <img className='className="flex items-center justify-center h-10 w-10 rounded-full bg-white flex-shrink-0' src={userImages[selectedUser]}/>
                      </div>
                      <div className="relative ml-3 text-sm py-2 px-2 shadow rounded-xl hover:scale-90 transition-transform duration-300">
                      <img className='flex items-center justify-center h-28 w-36 bg-white flex-shrink-0' src={message.image} onClick={()=>image_fun(message.image)}/>
                        </div>
                    
                  </div>
                </div>
                  );
                  }
                  else if (message.user === 'sender') {

                  return (
                    <div key={message.id} className="col-start-6 col-end-13 p-3 rounded-lg">
                    <div className="flex items-center justify-start flex-row-reverse">
                      <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                      <img className='className="flex items-center justify-center h-10 w-10 rounded-full bg-white flex-shrink-0' src={userPictureLink}/>

                      </div>
                      <div className="relative mr-3 text-sm py-2 px-2 shadow rounded-xl hover:scale-90 transition-transform duration-300">
                      <img className='flex items-center justify-center h-28 w-36 bg-white flex-shrink-0' src={message.image} onClick={()=>image_fun(message.image)}/>
                      
                        </div>
                    </div>
                  </div>
                  );
                  }
                } 
                if (message.user === 'receiver') {
                  return (
                    <div key={message.id} className="col-start-6 col-end-13 p-3 rounded-lg">
                    <div className="flex items-center justify-start flex-row-reverse">
                      <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                      <img className='className="flex items-center justify-center h-10 w-10 rounded-full bg-white flex-shrink-0' src={userPictureLink}/>

                      </div>
                      <div className="relative mr-3 text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl">
                        <div>{message.text}</div>
                      </div>
                    </div>
                  </div>
                  );
                } 
                
                
                else if (message.user === 'sender') {
                  return (
                    <div key={message.id} className="col-start-1 col-end-8 p-3 rounded-lg">
                  <div className="flex flex-row items-center">
                    <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                      <img className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0" src={userImages[selectedUser]}/>
                    </div>
                    <div className="relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl">
                    <div>{message.text}</div>
                    </div>
                  </div>
                </div>
                  );
                }
                return null; // Optional: If message.user is neither "sender" nor "receiver", you can choose to skip rendering.
              })}
             
              {/* <Picker
              onEmojiClick={(event) => {
                setText(text + event.emoji); // Log the entire emojiObject for inspection
              }}
            /> */}

              </div>
              
            </div>
            
          </div>
          
          <div className="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4">
          <div>
            <div className="relative">
            <input
              type="file"
              onChange={handleChange}
              accept="image/*"
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            />
            <button
              className="flex items-center justify-center text-gray-400 hover:text-gray-600"
              style={{ maxWidth: "90%" }}
            >
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="orange"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                />
              </svg>
            </button>
          </div>
            </div>
            &nbsp;
            <div>
              <button onClick={()=>setOpen(true)} className="flex text-sm items-center justify-center border border-orange-400 hover:bg-gray-100 rounded-xl text-gray-700 font-bold px-2 py-1 flex-shrink-0">
                <p>Create an Offer</p>
                
              </button>
            </div>
            <div className="flex-grow ml-4">
              <div className="relative w-full">
                <input
                  type="text"
                  className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      // Call the function to send the message
                      sendMessage();
                    }
                  }}
                />
                {/* <button
                className="absolute flex items-center justify-center h-full w-12 right-0 top-0 text-gray-400 hover:text-gray-600"
                onClick={() => emojiState()}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="orange"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button> */}
              </div>
              
            </div>
            
            <div className="ml-4">
              <button onClick={sendMessage} className="flex items-center justify-center bg-orange-400 hover:bg-orange-600 rounded-xl text-white px-4 py-1 flex-shrink-0">
                <span>Send</span>
                <span className="ml-2">
                  <svg
                    className="w-4 h-4 transform rotate-45 -mt-px"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                    />
                  </svg>
                </span>
              </button>
            </div>
            <div>

    </div>
          </div>
        </div>
      </div>


      {/* Choose A Service */}
             <Transition.Root show={open} as={Fragment}>
             <Dialog as="div" className="relative z-10" onClose={setOpen}>
               <Transition.Child
                 as={Fragment}
                 enter="ease-out duration-300"
                 enterFrom="opacity-0"
                 enterTo="opacity-100"
                 leave="ease-in duration-200"
                 leaveFrom="opacity-100"
                 leaveTo="opacity-0"
               >
                 <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
               </Transition.Child>
       
               <div className="fixed inset-0 z-10 overflow-y-auto">
                 <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                   <Transition.Child
                     as={Fragment}
                     enter="ease-out duration-300"
                     enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                     enterTo="opacity-100 translate-y-0 md:scale-100"
                     leave="ease-in duration-200"
                     leaveFrom="opacity-100 translate-y-0 md:scale-100"
                     leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                   >
                     <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-2xl">
                       <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                         <button
                           type="button"
                           className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                           onClick={() => {setOpen(false);setServiceImage("");setServiceName("")}}
                         >
                           <span className="sr-only">Close</span>
                           <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                         </button>
                        {/* THE Start OF CREATE OFFER BOX */}
                         <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-1 lg:gap-x-8">
                           
                         <>

                         <div className="flex flex-col mt-0 overflow-auto">
                          <div className="flex flex-row items-center justify-between">
                            
                            <h1 className="font-bold text-gray-900">Select Service</h1>
                          </div>
                          <div className="text-sm breadcrumbs">
                          <ul>
                            <li><b>Select Service</b></li> 
                            <li>Set Your Offer</li> 
                            <li>Send</li>
                          </ul>
                        </div> 

                          <div className="flex flex-col space-y-1 mt-4 -mx-2 max-h-56 overflow-y-auto">
                            {listArray.map((item) => (
                              <>
                              <button
                                key={item.name}
                                onClick={()=>{
                                  setOpen(false)
                                  setServiceName(item.name);
                                  setServiceImage(item.image);
                                  setOpen2(true);
                                }}
                                className={'flex flex-row items-center bg-gray-100 hover:bg-gray-500 rounded-xl p-2'}
                              >
                                &nbsp;
                                <img src={item.image} style={{width:"8%",backgroundColor:"white",borderRadius:"20px"}}/>
                                &nbsp;
                                <p className='text-gray-800 font-bold'>{item.name}</p>
                              </button>
                              </>
                            ))}
                          </div>
                          
                        </div>
                        

                          </>

                         </div>
                    
                        {/* THE END OF CREATE OFFER BOX */}

                       </div>
                     </Dialog.Panel>
                   </Transition.Child>
                 </div>
               </div>
             </Dialog>
           </Transition.Root>




{/*////////////////////////////// CREATE OFFER CODE ///////////////////////  */}
   {/* CREATE AN OFFER */}
   <Transition.Root show={open2} as={Fragment}>
             <Dialog as="div" className="relative z-10" onClose={setOpen2}>
               <Transition.Child
                 as={Fragment}
                 enter="ease-out duration-300"
                 enterFrom="opacity-0"
                 enterTo="opacity-100"
                 leave="ease-in duration-200"
                 leaveFrom="opacity-100"
                 leaveTo="opacity-0"
               >
                 <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
               </Transition.Child>
       
               <div className="fixed inset-0 z-10 overflow-y-auto">
                 <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                   <Transition.Child
                     as={Fragment}
                     enter="ease-out duration-300"
                     enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                     enterTo="opacity-100 translate-y-0 md:scale-100"
                     leave="ease-in duration-200"
                     leaveFrom="opacity-100 translate-y-0 md:scale-100"
                     leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                   >
                     <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-2xl">
                       <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                         <button
                           type="button"
                           className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                           onClick={() => {setOpen2(false);setServiceImage("");setServiceName("")}}
                         >
                           <span className="sr-only">Close</span>
                           <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                         </button>
                        {/* THE Start OF CREATE OFFER BOX */}
                         <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-1 lg:gap-x-8">
                           
                         <>

                         <div className="flex flex-col mt-0 overflow-auto">
                          <div className="flex flex-row items-center justify-between">
                            
                            <h1 className="font-bold text-gray-900">Set an Offer</h1>
                          </div>
                          <div className="text-sm breadcrumbs">
                          <ul>
                            <li><button onClick={() => {setOpen2(false);
                              setServiceImage("");
                              setServiceName("");
                              setCheckedValues([]);
                              setisCheckboxCheckedNumberOfImages(false);
                              setisCheckboxCheckedCoupen(false);
                              setisCheckboxCheckedRequirements(false);
                              setText2("");
                              setselectedRevision('');
                              setselectedCoupen('');
                              setSelectedNumberOfImages('');
                              setselectedDelivery('');
                              setrequirements_data([]);
                              setOpen(true)}}>Select Service</button></li> 
                            <li><b>Set Your Offer</b></li> 
                            <li>Send</li>
                          </ul>
                        </div > 
                        <form onSubmit={(event)=>submit(event)}>
                          <div className='overflow-y-auto max-h-96'>
                          <div className="flex flex-col space-y-1 mt-4 -mx-2 max-h-56 ">
                          <div className="flex">
                              <img src={ServiceImage} alt="Image" className="w-1/2" style={{width:"20%"}}/>
                              <div className="w-1/2">

                              <textarea
                              required
                              value={textar}
                              onChange={handleTextChange}
                              id="message" rows="4" class="block p-2.5  text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder='Describe Your Offer' style={{width:"155%"}}></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col space-y-1 mt-4  overflow-y-auto">
                          <div className="flex">
                          <span className='text-black text-sm'>Define the terms of your offer and what it includes.</span>

                            </div>
                          </div>
                          <br/>

                          <div className="bg-gray-200 p-4 rounded-lg">
                            <div className="flex mb-4">
                              <div className="w-1/3">
                                <label htmlFor="revision" className="mr-2 text-black">Revisions</label>
                                
                              </div>

                              <div className="w-1/3">
                                <label htmlFor="delivery-days" className="mr-2 text-black">Delivery</label>
                                
                              </div>

                            <div className="flex items-center w-1/3">
                              <label htmlFor="price" className="mr-2 text-black">Price</label>
                            </div>
                            </div>

                            <div className="flex mb-4">
                              <div className="w-1/3">
                                <select id="revision" 
                                value={selectedRevision}
                                onChange={handleSelectChangesRevision}
                                className="text-black border rounded-md py-1 px-8">
                                  <option value="0">0</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="UNLIMITED">UNLIMITED</option>
                                </select>
                              </div>

                              <div className="w-1/3">
                                <select id="delivery-days" 
                                value={selectedDelivery}
                                onChange={handleSelectChangesDelivery}
                                className="text-black border rounded-md py-1 px-2">
                                  <option value="0">Select</option>
                                  <option value="1">1 day</option>
                                  <option value="2">2 days</option>
                                  <option value="3">3 days</option>
                                  <option value="4">4 days</option>
                                  <option value="5">5 days</option>
                                  <option value="6">6 days</option>
                                  <option value="7">7 days</option>
                                  <option value="8">8 days</option>
                                  <option value="9">9 days</option>
                                  <option value="10">10 days</option>
                                  <option value="11">11 days</option>
                                  <option value="12">12 days</option>
                                  <option value="13">13 days</option>
                                  <option value="14">14 days</option>
                                  <option value="15">15 days</option>
                                  <option value="16">16 days</option>
                                  <option value="17">17 days</option>
                                  <option value="18">18 days</option>
                                </select>
                              </div>

                            <div className="flex items-center w-1/3">
                              <input id="price" type="text" 
                              required
                                    value={price}
                                    onChange={handlePriceChange}
                              className="text-black border rounded-md py-1 px-12 w-36" />
                            </div>
                            </div>

                          </div>
                            <br/>
                          <hr/>
                          <br/>
                          {/* THIS IS FOR REQUIREMENTS */}
                          <div class="grid grid-cols-2 gap-4">
                          <label class="flex items-center">
                            <input type="checkbox" 
                            checked={isCheckboxCheckedRequirements}
                            onChange={handleCheckboxChangeRequirements}
                            className="form-checkbox mr-2"/>
                            <span class="text-sm">Request for Requirements</span>
                          </label>
                        </div>

                              {/* THIS IS FOR COUPEN */}
                              <br/>
                              <hr/>
                              <br/>
                              <div class="grid grid-cols-2 gap-4">
                              <label class="flex items-center">
                                <input type="checkbox" 
                                checked={isCheckboxCheckedCoupen}
                                onChange={handleCheckboxChangeCoupen}
                                className="form-checkbox mr-2"/>
                                <span class="text-sm">Offer a Coupen</span>
                              </label>
                              
                              <div className="flex items-center justify-end">
                                <select id="revision" 
                                disabled={!isCheckboxCheckedCoupen}
                                value={selectedCoupen}
                                onChange={handleSelectChangesCoupen}
                                className="text-black border rounded-md py-1 px-8">
                                  <option value="0">Select</option>
                                  <option value="10">10%</option>
                                  <option value="20">20%</option>
                                  <option value="30">30%</option>
                                  <option value="40">40%</option>
                                  <option value="50">50%</option>
                                </select>
                              </div>
                            </div>

                              <br/>
                            <div className='bg-gray-200 p-2 '>
                                <span className='text-black font-bold text-md'>Offer includes</span>
                        </div>
                        <br/>
                       
                        <div className="grid grid-cols-2 gap-4">
                          <label className="flex items-center">
                            <input
                              type="checkbox"
                              className="form-checkbox mr-2"
                              checked={isCheckboxCheckedNumberOfImages}
                              onChange={handleCheckboxChangeNumberOfImages}
                            />
                            <span className="text-sm"># of Images</span>
                          </label>

                          <div className="flex items-center justify-end">
                          <select
                            id="revision"
                            className="text-black border rounded-md py-1 px-8"
                            disabled={!isCheckboxCheckedNumberOfImages}
                            value={selectedNumberOfImages}
                            onChange={handleSelectChange}
                            >
                              <option value="0">Select</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                            </select>
                          </div>
                        </div>
                              <br/>
                              <hr/>
                              <br/>
                              {Object.entries(services).map(([key, value]) => {
                                if (
                                  (key === 'product_photography' && ServiceName === 'Product Photography') ||
                                  (key === 'packaging_design' && ServiceName === 'Packaging Design') ||
                                  (key === 'ebc' && ServiceName === 'Enhanced Brand Content') ||
                                  (key === 'brd' && ServiceName === 'Brand Logo Design')
                                ) {
                                  return Object.entries(value).map(([subKey, subValue]) => (
                                    <React.Fragment key={subKey}>
                                      <div className="grid grid-cols-2 gap-4">
                                        <label className="flex items-center">
                                          <input
                                            type="checkbox"
                                            className="form-checkbox mr-2"
                                            value={subKey}
                                            onChange={handleCheckboxChange}
                                          />
                                          <span className="text-sm">{subValue}</span>
                                        </label>
                                      </div>
                                      <br />
                                      <hr />
                                      <br />
                                    </React.Fragment>
                                  ));
                                }
                                return null;
                              })}
                              <button onClick={(event)=>submit(event)} className="btn bg-orange text-white">Send</button>





{/* END */}
                        </div>    
                        </form>                   
                        </div>
                          </>

                         </div>
                    
                        {/* THE END OF CREATE OFFER BOX */}

                       </div>
                     </Dialog.Panel>
                   </Transition.Child>
                 </div>
               </div>
             </Dialog>
           </Transition.Root>







{/*////////////////////////////// CREATE CONFIRMATION FOR LAST SEND ///////////////////////  */}
   <Transition.Root show={open3} as={Fragment}>
             <Dialog as="div" className="relative z-10" onClose={setOpen3}>
               <Transition.Child
                 as={Fragment}
                 enter="ease-out duration-300"
                 enterFrom="opacity-0"
                 enterTo="opacity-100"
                 leave="ease-in duration-200"
                 leaveFrom="opacity-100"
                 leaveTo="opacity-0"
               >
                 <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
               </Transition.Child>
       
               <div className="fixed inset-0 z-10 overflow-y-auto">
                 <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                   <Transition.Child
                     as={Fragment}
                     enter="ease-out duration-300"
                     enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                     enterTo="opacity-100 translate-y-0 md:scale-100"
                     leave="ease-in duration-200"
                     leaveFrom="opacity-100 translate-y-0 md:scale-100"
                     leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                   >
                     <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-2xl">
                       <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                         <button
                           type="button"
                           className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                           onClick={() => {
                            setServiceImage("");
                              setServiceName("");
                              setCheckedValues([]);
                              setisCheckboxCheckedNumberOfImages(false);
                              setisCheckboxCheckedCoupen(false);
                              setisCheckboxCheckedRequirements(false);
                              setText2("");
                              setselectedRevision('');
                              setselectedCoupen('');
                              setSelectedNumberOfImages('');
                              setselectedDelivery('');
                              setrequirements_data([]);
                              setOpen3(false);
                           }}
                         >
                           <span className="sr-only">Close</span>
                           <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                         </button>
                        {/* THE Start OF CREATE OFFER BOX */}
                         <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-1 lg:gap-x-8">
                           
                         <>

                         <div className="flex flex-col mt-0 overflow-auto">
                          <div className="flex flex-row items-center justify-between">
                            
                            <h1 className="font-bold text-gray-900">Offer</h1>
                          </div>
                          <div className="text-sm breadcrumbs">
                          <ul>
                            <li>Select Service</li> 
                            <li><button onClick={() => {
                              setOpen3(false);  
                              setOpen2(true);
                              }}>Set Your Offer</button></li> 
                            <li><b>Send</b></li>
                          </ul>
                        </div > 
                        <form onSubmit={(event) => uploadsubmit(event)}>
                          <div className='overflow-y-auto max-h-96'>
                          <div className="flex flex-col space-y-1 mt-4 ">
                          <div className="flex">
                          <span className='text-black text-sm'><b>Here is the offer for you:</b></span>
                            </div>
                            <div className="flex">
                          <span className='text-black text-sm'><b>Name: </b>{requirements_data.service}</span>
                            </div>
                            <div className="flex">
                              <span className='text-black text-sm'>{requirements_data.requirements_text}</span>
                          </div>
                          </div>
                          <br/>
                          <hr/>
                          {requirements_data.selectedCoupen !== '0' && (
                            <div className="flex flex-col space-y-1 mt-4">
                              <div className="flex">
                              <span className='text-black font-bold text-sm'>Note: </span>&nbsp;
                                <span className='text-black text-sm'>Congratulations You have a coupen of: {requirements_data.selectedCoupen} % </span>
                              </div>
                              <br/>
                              <hr/>
                            </div>
                          )}
                          {requirements_data.requirements_checked !== false && (
                            <div className="flex flex-col space-y-1 mt-4">
                              <div className="flex">
                                <span className='text-black font-bold text-sm'>Note: </span>&nbsp;
                                <span className='text-black  text-sm'>You are requested to provide your requirements. </span>
                              </div>
                              <br/>
                              <hr/>
                            </div>
                            
                          )}

                          <div className="flex flex-col space-y-1 mt-4 ">
                          <div className="flex">
                          <span className='text-black font-bold text-sm'>Your offer includes</span>

                            </div>
                            <div className="bg-white">
                              <div className="mt-2 grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-4">
                              <div className="mt-4 flex justify-between">
                                          <div>
                                          <div className="flex items-center">
                                          <CheckIcon className="h-5 w-5 flex-none text-orange-400" aria-hidden="true" />&nbsp;
                                            <h3 className="text-sm text-gray-700">Number of Revisions: {requirements_data.revisions}</h3>
                                        </div>
                              </div>
                              </div>
                              <div className="mt-4 flex justify-between">
                                          <div>
                                          <div className="flex items-center">
                                          <CalendarIcon className="h-5 w-5 flex-none text-orange-400" aria-hidden="true" />&nbsp;
                                            <h3 className="text-sm text-gray-700">Delivery: {requirements_data.delivery} Days </h3>
                                        </div>
                              </div>
                              </div>
                              <div className="mt-4 flex justify-between">
                                          <div>
                                          <div className="flex items-center">
                                          <CheckIcon className="h-5 w-5 flex-none text-orange-400" aria-hidden="true" />&nbsp;
                                            <h3 className="text-sm text-gray-700">Number of images: {requirements_data.number_of_image} </h3>
                                        </div>
                              </div>
                              </div>
                              </div>
                            </div>
                                <div className="bg-white">
                                  <div className="mt-2 grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-4">
                                    {requirements_data.checked_box?.map((product) => (
                                      <div key={product.id} className="group relative">
                                        <div className="mt-4 flex justify-between">
                                          <div>
                                          <div className="flex items-center">
                                            <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />&nbsp;
                                            <h3 className="text-sm text-gray-700">{product}</h3>
                                          </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              {/* </div> */}

                          </div>
                          <br/>

                          
                            <br/>
                          <hr/>
                          <br/>
      

                             
                              <button onClick={(event)=>uploadsubmit(event)} className="btn text-white">Send</button>





                        {/* END */}
                        </div>    
                        </form>                   
                        </div>
                          </>

                         </div>
                    
                        {/* THE END OF CREATE OFFER BOX */}

                       </div>
                     </Dialog.Panel>
                   </Transition.Child>
                 </div>
               </div>
             </Dialog>
           </Transition.Root>

{/*////////////////////////////// CHECK OFFER ///////////////////////  */}
<Transition.Root show={open4} as={Fragment}>
             <Dialog as="div" className="relative z-10" onClose={setOpen4}>
               <Transition.Child
                 as={Fragment}
                 enter="ease-out duration-300"
                 enterFrom="opacity-0"
                 enterTo="opacity-100"
                 leave="ease-in duration-200"
                 leaveFrom="opacity-100"
                 leaveTo="opacity-0"
               >
                 <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
               </Transition.Child>
       
               <div className="fixed inset-0 z-10 overflow-y-auto">
                 <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                   <Transition.Child
                     as={Fragment}
                     enter="ease-out duration-300"
                     enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                     enterTo="opacity-100 translate-y-0 md:scale-100"
                     leave="ease-in duration-200"
                     leaveFrom="opacity-100 translate-y-0 md:scale-100"
                     leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                   >
                     <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-2xl">
                       <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                         <button
                           type="button"
                           className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                           onClick={() => {
                            setServiceImage("");
                              setServiceName("");
                              setCheckedValues([]);
                              setisCheckboxCheckedNumberOfImages(false);
                              setisCheckboxCheckedCoupen(false);
                              setisCheckboxCheckedRequirements(false);
                              setText2("");
                              setselectedRevision('');
                              setselectedCoupen('');
                              setSelectedNumberOfImages('');
                              setselectedDelivery('');
                              setrequirements_data([]);
                              setrequirements_data2([]);
                              setOpen4(false);
                           }}
                         >
                           <span className="sr-only">Close</span>
                           <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                         </button>
                        {/* THE Start OF CREATE OFFER BOX */}
                         <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-1 lg:gap-x-8">
                           
                         <>

                         <div className="flex flex-col mt-0 overflow-auto">
                          <div className="flex flex-row items-center justify-between">
                            
                            <h1 className="font-bold text-gray-900">Offer</h1>
                          </div>
                          
                          <div className='overflow-y-auto max-h-96'>
                          <div className="flex flex-col space-y-1 mt-4 ">
                          <div className="flex">
                          <span className='text-black text-sm'><b>Here is the offer for you:</b></span>
                            </div>
                            <hr/>
                            <div className="flex">
                          <span className='text-black text-sm'><b>Name: {requirements_data2.service}</b></span>
                            </div>
                            <hr/>
                            <div className="flex">
                          <span className='text-black text-sm'><b>Price: {requirements_data2.price} $ </b></span>
                            </div>
                            <hr/>
                            <div className="flex">
                              <span className='text-black text-sm'>{requirements_data2.requirements_text}</span>
                          </div>
                          </div>
                          <br/>
                          <hr/>
                          {requirements_data2.selectedCoupen !== '0' && (
                            <div className="flex flex-col space-y-1 mt-4">
                              <div className="flex">
                              <span className='text-black font-bold text-sm'>Note: </span>&nbsp;
                                <span className='text-black text-sm'>Congratulations You have a coupen of: <b>{requirements_data2.selectedCoupen} %</b> </span>
                              </div>
                              <br/>
                              <hr/>
                            </div>
                          )}
                          {requirements_data2.requirements_checked !== false && (
                            <div className="flex flex-col space-y-1 mt-4">
                              <div className="flex">
                                <span className='text-black font-bold text-sm'>Note: </span>&nbsp;
                                <span className='text-black  text-sm'>You are requested to provide your requirements. </span>
                              </div>
                              <br/>
                              <hr/>
                            </div>
                            
                          )}

                          <div className="flex flex-col space-y-1 mt-4 ">
                          <div className="flex">
                          <span className='text-black font-bold text-sm'>Your offer includes</span>

                            </div>
                            <div className="bg-white">
                              <div className="mt-2 grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-4">
                              <div className="mt-4 flex justify-between">
                                          <div>
                                          <div className="flex items-center">
                                          <CheckIcon className="h-5 w-5 flex-none text-orange-400" aria-hidden="true" />&nbsp;
                                            <h3 className="text-sm text-gray-700">Number of Revisions:<b> {requirements_data2.revisions}</b></h3>
                                        </div>
                              </div>
                              </div>
                              <div className="mt-4 flex justify-between">
                                          <div>
                                          <div className="flex items-center">
                                          <CalendarIcon className="h-5 w-5 flex-none text-orange-400" aria-hidden="true" />&nbsp;
                                            <h3 className="text-sm text-gray-700">Delivery: <b>{requirements_data2.delivery} Days</b> </h3>
                                        </div>
                              </div>
                              </div>
                              <div className="mt-4 flex justify-between">
                                          <div>
                                          <div className="flex items-center">
                                          <CheckIcon className="h-5 w-5 flex-none text-orange-400" aria-hidden="true" />&nbsp;
                                            <h3 className="text-sm text-gray-700">Number of images: <b>{requirements_data2.number_of_image}</b>  </h3>
                                        </div>
                              </div>
                              </div>
                              </div>
                            </div>
                                <div className="bg-white">
                                  <div className="mt-2 grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-4">
                                    {requirements_data2.checked_box?.map((product) => (
                                      <div key={product.id} className="group relative">
                                        <div className="mt-4 flex justify-between">
                                          <div>
                                          <div className="flex items-center">
                                            <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />&nbsp;
                                            <h3 className="text-sm text-gray-700"><b>{product}</b></h3>
                                          </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              {/* </div> */}

                          </div>
                          <br/>

                          
                            <br/>
                          <hr/>
                          <br/>

                        {/* END */}
                        </div>    
                        </div>
                          </>

                         </div>
                    
                        {/* THE END OF CREATE OFFER BOX */}

                       </div>
                     </Dialog.Panel>
                   </Transition.Child>
                 </div>
               </div>
             </Dialog>
           </Transition.Root>


           <Transition.Root show={open5} as={Fragment}>
             <Dialog as="div" className="relative z-10" onClose={setOpen5}>
               <Transition.Child
                 as={Fragment}
                 enter="ease-out duration-300"
                 enterFrom="opacity-0"
                 enterTo="opacity-100"
                 leave="ease-in duration-200"
                 leaveFrom="opacity-100"
                 leaveTo="opacity-0"
               >
                 <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
               </Transition.Child>
       
               <div className="fixed inset-0 z-10 overflow-y-auto">
                 <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                   <Transition.Child
                     as={Fragment}
                     enter="ease-out duration-300"
                     enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                     enterTo="opacity-100 translate-y-0 md:scale-100"
                     leave="ease-in duration-200"
                     leaveFrom="opacity-100 translate-y-0 md:scale-100"
                     leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                   >
                     <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-0 lg:max-w-3xl">
                       <div className="relative flex w-full items-center overflow-hidden px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                         <button
                           type="button"
                           className="absolute right-4 top-4 text-orange-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                           onClick={() => {
                              setOpen5(false);
                           }}
                         >
                           <span className="sr-only">Close</span>
                           <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                         </button>
                        {/* THE Start OF CREATE OFFER BOX */}
                         <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-1 lg:gap-x-8">
                           
                         <>

                         <div className="flex flex-col mt-0 overflow-auto">
                          <div className="flex flex-row items-center justify-between">
                            
                            <img src={imageLink}/>
                          </div>
                          
                        
                        </div>
                          </>

                         </div>
                    
                        {/* THE END OF CREATE OFFER BOX */}

                       </div>
                     </Dialog.Panel>
                   </Transition.Child>
                 </div>
               </div>
             </Dialog>
           </Transition.Root>
      </>
      )  
};

export default MessageBox;
