import ImageFilter from 'react-image-filter';
import React, { useState } from 'react';



const CustomFilter = ({ image, filters }) => {
  const filterStyle = {
    filter: filters.join(' '),
  };

  return <img src={image} style={filterStyle} alt="Filtered Image" />;
};



const posts2 = [
    {
      id: 1,
      author: {
        name: 'Normal',
        role: 'Default Filter',
        imageUrl:
        'https://img.freepik.com/free-photo/beautiful-shot-white-british-shorthair-kitten_181624-57681.jpg',
      },
    },
    {
      id: 2,
      filter:'grayscale',
      author: {
        name: 'Grey Scale',
        role: 'Grey Scale Filter',
        filter:'grayscale',
        imageUrl:
        'https://img.freepik.com/free-photo/beautiful-shot-white-british-shorthair-kitten_181624-57681.jpg',
      },
    },
    {
      id: 3,
      filter:'sepia',
      author: {
        name: 'Sepia',
        role: 'Sepia Filter',
        imageUrl:
        'https://img.freepik.com/free-photo/beautiful-shot-white-british-shorthair-kitten_181624-57681.jpg',
      },
    },
    {
      id: 4,
      filter:'invert',
      author: {
        name: 'Invert',
        role: 'Invert Filter',
        imageUrl:
        'https://img.freepik.com/free-photo/beautiful-shot-white-british-shorthair-kitten_181624-57681.jpg',
      },
    },

    {
      id: 5,
      filter:'custom',
      author: {
        name: 'Sharp',
        role: 'Sharp Filter',
        imageUrl:
        'https://img.freepik.com/free-photo/beautiful-shot-white-british-shorthair-kitten_181624-57681.jpg',
      },
    },
    {
      id: 6,
      filter:'custom',
      author: {
        name: 'Magenta',
        role: 'Magenta Filter',
        imageUrl:
        'https://img.freepik.com/free-photo/beautiful-shot-white-british-shorthair-kitten_181624-57681.jpg',
      },
    },
  ]
  
  function Filter() {

    const [selectedImage, setSelectedImage] = useState('https://img.freepik.com/free-photo/beautiful-shot-white-british-shorthair-kitten_181624-57681.jpg');
  
    const handleImageUpload = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();
  
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
  
      if (file) {
        reader.readAsDataURL(file);
      }
    };

    const handleClickDownload = (filter) => {
      const imageUrl = selectedImage;
      var cssProperties={};
      if (filter === "Normal") {
        cssProperties = {
        };
      } else if (filter === "Gray Scale") {
        cssProperties = {
          filter: 'grayscale(100%)',
        };
      }
      else if (filter === "Sepia") {
        cssProperties = {
          filter: 'sepia(100%)',
        };
      }
      else if (filter === "Invert") {
        cssProperties = {
          filter: 'invert(100%)',
        };
      }
      else if (filter === "Sharp") {
        cssProperties = {
          filter: 'brightness(1.1) contrast(1.3) hue-rotate(20deg)',
        };
      }
      else if (filter === "Magenta") {
        cssProperties = {
          filter: 'brightness(1.0) contrast(1.1) hue-rotate(250deg)',
        };
      }
      downloadImage(imageUrl, cssProperties);
    };
    return (
      <>
      <div className="bg-white py-4 sm:py-8">
          <div>
            </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Filters</h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              Add filter to your Pictures
            </p>
          </div>
          <div class="flex items-center justify-center w-full ">
    <label for="dropzone-file" class="flex flex-col items-center justify-center w-96 h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
        <div class="flex flex-col items-center justify-center pt-5 pb-6 ">
            <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
            <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
        </div>
        <input id="dropzone-file" type="file" class="hidden" onChange={handleImageUpload}/>
    </label>
</div> 
          <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {posts2.map((post) => (
              <article key={post.id} className="flex max-w-xl flex-col items-start justify-between">
                
                <div id="filtered-image-container" className="group relative">
        {selectedImage && post.filter === 'custom' && post.id === 5 && (
          <CustomFilter image={selectedImage} filters={['brightness(1.1)', 'contrast(1.3)', 'hue-rotate(20deg)']} />
        )}
        {selectedImage && post.filter === 'custom' && post.id === 6 && (
          <CustomFilter image={selectedImage} filters={['brightness(1.0)', 'contrast(1.1)', 'hue-rotate(250deg)']} />
        )}
        {selectedImage && post.filter !== 'custom' && (
          <ImageFilter image={selectedImage} filter={post.filter} />
        )}
      </div>
                <div className="relative mt-8 flex items-center gap-x-4">
                  <div className="text-sm leading-6">
                    <p className="font-semibold text-gray-900">
                      <a href={post.author.href}>
                        <span className="absolute inset-0" />
                        {post.author.name}
                      </a>
                    </p>
                    <p className="text-gray-600">{post.author.role}</p>
                  </div>
                </div>
                <button
                    className="mt-4 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded focus:outline-none"
                    onClick={()=>handleClickDownload(post.author.name)}
                  >
                    Download
                  </button>
              </article>
            ))}
          </div>
        </div>
      </div>




</>
    )
  }
  
  const downloadImage = async (imageUrl, cssProperties) => {
    try {
      const image = new Image();
      image.crossOrigin = 'anonymous';
  
      // Create a promise to track the image loading
      const imageLoadPromise = new Promise((resolve, reject) => {
        image.onload = resolve;
        image.onerror = reject;
      });
  
      image.src = imageUrl;
  
      await imageLoadPromise;
  
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext('2d');
  
      applyCssProperties(ctx, cssProperties);
  
      ctx.drawImage(image, 0, 0);
  
      canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'image.jpg';
        link.click();
  
        URL.revokeObjectURL(url);
      }, 'image/jpeg');
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };
  
  const applyCssProperties = (ctx, cssProperties) => {
    const { filter, opacity } = cssProperties;
  
    if (filter) {
      ctx.filter = filter;
    }
  
    if (opacity) {
      ctx.globalAlpha = parseFloat(opacity);
    }
  };

export default Filter;