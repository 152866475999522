import React, { useState, useEffect } from 'react';
import {   
  ref,
  get,
  update,
  child,

} from 'firebase/database';
import { dbreal } from '../firestore.js';
import MessageBox from './message_box.js';
import { ScrollIndicatorPage } from '../browser/scroll';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './messages.css';
import { useLocation } from 'react-router-dom';

function Messages_Admin() {
  const location = useLocation();
  const receivedVariable = location.state?.user;
  const [selectedUser, setSelectedUser] = useState(receivedVariable);
  const data = localStorage.getItem('UserData');
  const data2 = JSON.parse(data);
  const email = data2.email;
  const userPictureLink = data2.image;
  const name = data2.name;
  const [usersWithMessages, setUsersWithMessages] = useState([]);
  const [userImages, setUserImages] = useState({});
  const [userEmail, setUseremail] = useState({});
  const [userUnreadCounts, setUserUnreadCounts] = useState({});


  const handleUserSelection = async (userId) => {
    setSelectedUser(userId);
  
    try {
      // Set the value to zero for the selected user in userUnreadCounts state
      setUserUnreadCounts((prevCounts) => ({
        ...prevCounts,
        [userId]: 0,
      }));
  
      // Update the readbyadmin field to true for the selected user in the Realtime Database
      const userMessagesRef = ref(dbreal, `users/${userId}/messages/`);
      const snapshot = await get(userMessagesRef);
  
      if (snapshot.exists()) {
        const data = snapshot.val();
        const promises = [];
  
        Object.keys(data).forEach((documentId) => {
          const documentRef = child(userMessagesRef, documentId);
          const promise = update(documentRef, { readbyadmin: true })
            .catch((error) => {
              console.error("Error updating 'readbyadmin' field:", error);
            });
  
          promises.push(promise);
        });
  
        await Promise.all(promises);
      }
  
      // Other logic for handling user selection
    } catch (error) {
      console.error(error);
    }
  };
  


  
  useEffect(() => {
    const fetchUsersWithMessages = async () => {
      try {
        const messagesRef = ref(dbreal, 'users');
        const messagesSnapshot = await get(messagesRef);
  
        const usersData = [];
        const userUnreadCountsObj = {};
        const image = {};
        const email = {};
  
        if (messagesSnapshot.exists()) {
          const promises = []; // Array to store promises
  
          messagesSnapshot.forEach((childSnapshot) => {
            const userId = childSnapshot.key;
            const userMessageRef = ref(dbreal, `users/${userId}/messages/`);
            usersData.push({ id: userId});

            const promise = get(userMessageRef)
              .then((snapshot) => {
                if (snapshot.exists()) {
                  const messagesData = snapshot.val();
                  const nestedCollectionKeys = Object.keys(messagesData);
                  for (const key of nestedCollectionKeys) {
                    

                    // for (const data in messagesData[key]) {
                      
                      const collection1Data = messagesData[key];
                      const readByAdmin = collection1Data.readbyadmin;
                      const userImage = collection1Data.image;
                      const userEmail = collection1Data.email;
                      const sender = collection1Data.user;
                      if (sender === "sender") {
                        image[userId] = userImage;
                        email[userId] = userEmail;
                      }
  
                      if (readByAdmin !== true) {
                        if (userUnreadCountsObj.hasOwnProperty(userId)) {
                          // Increment the existing value
                          userUnreadCountsObj[userId] += 1;
                        } else {
                          // Add the key with an initial value of 1
                          userUnreadCountsObj[userId] = 1;

                        }
                      }
                    // }
                  }
                } else {
                  console.log("No data available");
                }
              })
              .catch((error) => {
                console.error(error);
              });
  
            promises.push(promise); 
          });
  
          await Promise.all(promises);
        }
  
        setUseremail(email);
        setUserImages(image);
        setUserUnreadCounts(userUnreadCountsObj);
        setUsersWithMessages(usersData);
        setUserUnreadCounts(userUnreadCountsObj);
      } catch (error) {
        console.error(error);
      }
    };
  
    const interval = setInterval(() => {
      fetchUsersWithMessages();
      }, 3000); // Check for new messages every 5 seconds (adjust the interval as needed)
    
      return () => {
        clearInterval(interval); // Clear the interval when the component unmounts
      };  }, []);
  
  
  
  
  



  
    
const message_box = () => {
    return(
      <MessageBox selectedUser={selectedUser} dbreal={dbreal} userPictureLink={userPictureLink} userImages={userImages} uid={selectedUser} />
    )
  }




return(
    <>
  <ScrollIndicatorPage/>

  {/* component */}
  <div className="flex h-screen antialiased text-gray-800">
    <div className="flex flex-row h-full w-full overflow-x-hidden">
      <div className="flex flex-col py-2 pl-6 pr-2 w-64 bg-white flex-shrink-0">
        <div className="flex flex-row items-center justify-center h-12 w-full">
          <div className="flex items-center justify-center rounded-2xl text-indigo-700 bg-indigo-100 h-10 w-10">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="orange"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
              />
            </svg>
          </div>
          <div className="ml-2 font-bold text-2xl">Messages</div>
        </div>
        <div className="flex flex-col items-center bg-orange-100 border border-gray-200 mt-4 w-full py-6 px-4 rounded-lg">
          <div className="h-20 w-20 rounded-full border overflow-hidden">
            <img
              src={userPictureLink}
              alt="Avatar"
              className="h-full w-full"
            />
          </div>
          <div className="text-sm font-semibold mt-2">{name}</div>
          <div className="text-xs text-white-500">{email}</div>
       
        </div>
        <>
        <div className="flex flex-col mt-8">
          <div className="flex flex-row items-center justify-between text-xs">
            <span className="font-bold">Chats</span>
          </div>
          
          <div className="flex flex-col space-y-1 mt-4 -mx-2 h-62 overflow-y-auto">
      {usersWithMessages.map((user) => (
         
        <button
          key={user.id}
          onClick={() => handleUserSelection(user.id)}
          className={`flex flex-row items-center bg-gray-100 hover:bg-gray-500 rounded-xl p-2 ${
            userUnreadCounts[user.id] > 0 ? 'bg-gray-100' : ''
          }`}
        >
          
          <div className="flex items-center justify-center h-8 w-8 rounded-full">
            <img src={userImages[user.id]} alt="User" className="h-8 w-8 rounded-full" />
          </div>
          <div className="ml-2 text-sm font-semibold">{userEmail[user.id]}</div>
          {userUnreadCounts[user.id] > 0 && (
            <>
              <div className="flex items-center justify-center ml-auto text-xs text-white bg-red-500 h-4 w-4 rounded leading-none">
                {userUnreadCounts[user.id]}
              </div>
            </>
          )}
        </button>
      ))}
    </div>
     
              </div>
        </>
      </div>

      {/* CHAT BOX */}
      {selectedUser && (
            <div className=" py-0 flex flex-col flex-auto height-box p-6">
              {message_box()}
            </div>
          )}    </div>
  </div>
</>

)

}

export default Messages_Admin;