
import { ScrollIndicatorPage } from '../browser//scroll';
import Image from "react-image-enlarger";
import { useState } from 'react';
import { ArrowRightCircleIcon,ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next';
import './imager.css';
import '../browser/dark.css';
import { CheckIcon } from '@heroicons/react/20/solid'
import {Link} from 'react-router-dom';
import { Glide } from 'react-glide';
import 'react-glide/lib/reactGlide.css'
import ImageZoom from 'react-medium-image-zoom';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import styled from 'styled-components';
import Modal from 'react-modal';




const FullScreenImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  background-color: transparent;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 3px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
`;

const includedFeatures = [
  'Source file',
  'Commercial use',
  'Conduct research',
  'Data points included',
  'Revisions',
  'Delivery Time'

]
const features = [
  {
    name: 'Amazon product photography editing',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Background removal/changing',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Product image retouch',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Unique product Infographics',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Removing dust or any kind of scratches/spots',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Before/After Images',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Infographics images (shows the product`s features)',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Professional product listing for AMAZON, EBAY, ALIBABA',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Lifestyle photo editing ',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Add logo,Badges,Custom icon etc.',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Comparison charts',
    icon: ArrowRightCircleIcon,
  },
  {
    name: 'Photo retouching/editing ',
    icon: ArrowRightCircleIcon,
  },
]

function Product_Photography()
{
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState(null);
  const handleImageClick = (event) => {
    setSelectedImageSrc(event.target.src);
    setModalIsOpen(true);
  }
  

  const handleCloseModal = () => {
    setModalIsOpen(false);
  }

  const { t } = useTranslation('translation');
  const { t: currencyT } = useTranslation('currency');


    return(
        <>
        <ScrollIndicatorPage/>
 
        <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        style={{
          overlay: {
            // backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
          content: {
            position: 'absolute',
            top: '55%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '90%',
            maxHeight: '90%',
            width: '649.5px', // added
            height: '433.5px', // added
            padding: '0',
            border: 'none',
            borderRadius: '0',
            background: 'transparent' // added

          },
        }}
      >
        <CloseButton onClick={handleCloseModal}>X</CloseButton>
        <FullScreenImage src={selectedImageSrc} />
      </Modal>

        <div className="bg-white py-12 sm:py-18">
          
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-5xl lg:text-center">
        <h2 className="text-base font-semibold leading-7 text-orange-500">Grow<span className='black-color'>phics</span></h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-orange-400 sm:text-4xl">
            Product Photgraphy  
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600 justifier">
          {t("Product_Photography_Service")}
          </p>
        </div>
        <div className="mx-auto mt-4 max-w-2xl sm:mt-20 lg:mt-20 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-orange-400">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>

    <div className="bg-lighter py-24 sm:py-8" id="PP">
      <div className="mx-auto max-w-7xl px-8 lg:px-18">

        <br/>
        <br/>
        <p className='text-black font-semibold'> {t("PRODUCT PHOTOGRAPHY Description")}</p>
        <div className="mx-auto">

            <div className="flex items-center justify-center w-full h-full py-48 sm:py-8 px-4">
              {/* FOR WEB */}
              
                <CarouselProvider className="lg:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={4} visibleSlides={3} step={1} infinite={true}>
                    <div className="w-full relative flex items-center justify-center">
                    {!modalIsOpen && (
                      <>
                        <ButtonBack 
                          role="button" 
                          aria-label="slide backward" 
                          className="absolute z-30 left-0 -mr-2 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                          id="prev"
                        >
                            <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
                        </>
                    )}
                        <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                            <Slider>
                                <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                                <Slide index={11}>
                                        
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                        <img
                                        className='hoverer'
                                        src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs3/171664213/original/c3837cc7268a8645530273b3f301d044c3a01a5c/do-amazon-product-photography-editing-product-infographic.png"
                                        onClick={handleImageClick}/>
                                        </div>
                                    </Slide>

                                    <Slide index={0}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                        <img
                                        className='hoverer'
                                        src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/171664213/original/929084264b492316c64db559bd958386dfe7eb5c/do-amazon-product-photography-editing-product-infographic.png"
                                        onClick={handleImageClick}/>
                                        </div>
                                    </Slide>
            
                                    <Slide index={1}>
                                        
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                        <img
                                        className='hoverer'
                                        src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs3/171664213/original/c3837cc7268a8645530273b3f301d044c3a01a5c/do-amazon-product-photography-editing-product-infographic.png"
                                        onClick={handleImageClick}/>
                                        </div>
                                    </Slide>

                                    <Slide index={2}>                                       
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                        <img
                                        className='hoverer'
                                        src="https://fiverr-res.cloudinary.com/images/t_smartwm/t_gig_pdf_gallery_view_ver4,q_auto,f_auto/attachments/delivery/asset/8585d444974aec39b18e811cb1cbba6b-1679825598/Preview-Eldotrado-Pdf/do-amazon-product-photography-editing-product-infographic.pdf"
                                        onClick={handleImageClick}/>
                                        </div>
                                    </Slide>
                          
                                </div>
                            </Slider>
                        </div>
                        {!modalIsOpen && (
                      <>
                        <ButtonNext role="button" aria-label="slide forward" 
                          className="absolute z-30 right-0 -mr-0 focus:outline-none focus:bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer rounded-full bg-gray-500 w-10 h-10 flex items-center justify-center" 
                        >
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            
                        </ButtonNext>
                        </>
                    )}
                    </div>
                    
                </CarouselProvider>
                {/* FOR TAB */}
                <CarouselProvider className="lg:hidden md:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={12} visibleSlides={2} step={1} infinite={true}>
                <div className="w-full relative flex items-center justify-center">
                         {!modalIsOpen && (
                      <>
                        <ButtonBack role="button" aria-label="slide backward" className="absolute z-30 left-0 ml-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer" id="prev">
                            <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
                        </>
                    )}
                        <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                            <Slider>
                                <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                                    <Slide index={0}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                        <img
                                        className='hoverer'
                                        src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/171664213/original/929084264b492316c64db559bd958386dfe7eb5c/do-amazon-product-photography-editing-product-infographic.png"
                                        onClick={handleImageClick}/>
                                        </div>
                                    </Slide>
            
                                    <Slide index={1}>
                                        
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                        <img
                                        className='hoverer'
                                        src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs3/171664213/original/c3837cc7268a8645530273b3f301d044c3a01a5c/do-amazon-product-photography-editing-product-infographic.png"
                                        onClick={handleImageClick}/>
                                        </div>
                                    </Slide>

                                    <Slide index={2}>                                       
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                        <img
                                        className='hoverer'
                                        src="https://fiverr-res.cloudinary.com/images/t_smartwm/t_gig_pdf_gallery_view_ver4,q_auto,f_auto/attachments/delivery/asset/8585d444974aec39b18e811cb1cbba6b-1679825598/Preview-Eldotrado-Pdf/do-amazon-product-photography-editing-product-infographic.pdf"
                                        onClick={handleImageClick}/>
                                        </div>
                                    </Slide>
                          
                                </div>
                            </Slider>
                        </div>
                         {!modalIsOpen && (
                      <>
                        <ButtonNext role="button" aria-label="slide forward" className="absolute z-30 right-0 mr-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400" id="next">
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            
                        </ButtonNext>
                        </>
                    )}
                    </div>
                </CarouselProvider>
                {/* FOR MOBILE */}
                <CarouselProvider className="block md:hidden " naturalSlideWidth={20} isIntrinsicHeight={true} totalSlides={3} visibleSlides={1} step={1} infinite={true}>
                <div className="w-full relative flex items-center justify-center">
                         {!modalIsOpen && (
                      <>
                        <ButtonBack role="button" aria-label="slide backward" className="absolute z-30 left-0 ml-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer" id="prev">
                            <svg width={10} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
                        </>
                    )}
                        <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                            <Slider>
                                <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                                    <Slide index={0}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-full">
                                        <img
                                        className='hoverer'
                                        src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs/171664213/original/929084264b492316c64db559bd958386dfe7eb5c/do-amazon-product-photography-editing-product-infographic.png"
                                        onClick={handleImageClick}/>
                                        </div>
                                    </Slide>
            
                                    <Slide index={1}>
                                        
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                        <img
                                        className='hoverer'
                                        src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs3/171664213/original/c3837cc7268a8645530273b3f301d044c3a01a5c/do-amazon-product-photography-editing-product-infographic.png"
                                        onClick={handleImageClick}/>
                                        </div>
                                    </Slide>

                                    <Slide index={2}>                                       
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                        <img
                                        className='hoverer'
                                        src="https://fiverr-res.cloudinary.com/images/t_smartwm/t_gig_pdf_gallery_view_ver4,q_auto,f_auto/attachments/delivery/asset/8585d444974aec39b18e811cb1cbba6b-1679825598/Preview-Eldotrado-Pdf/do-amazon-product-photography-editing-product-infographic.pdf"
                                        onClick={handleImageClick}/>
                                        </div>
                                    </Slide>
                          
                                </div>
                            </Slider>
                        </div>
                         {!modalIsOpen && (
                      <>
                        <ButtonNext role="button" aria-label="slide forward" className="absolute z-30 right-0 mr-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400" id="next">
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            
                        </ButtonNext>
                        </>
                    )}
                    </div>
                </CarouselProvider>
                
            </div>
            
        </div>


        
        <br></br>

      </div>
      <br></br>
    </div>
    <div className="bg-white py-24 sm:py-8 mobile-packages-hide">
    <h2 className="text-3xl font-bold tracking-tight text-orange-400 sm:text-4xl text-center">Compare packages</h2>
              <br/>
              <br/>
              <br/>
      <div className=" mx-auto max-w-7xl px-8 lg:px-18"></div>
    <div className="text-black compare-table">
  <table>
    <thead>
      <tr>
        <th>Package</th>
        <th className='thx'>Basic</th>
        <th className='thx'>Standard</th>
        <th className='thx'>Premium</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td></td>
        <td>37.90$</td>
        <td>54.15$</td>
        <td>75.80$</td>
      </tr>
      <tr>
        <td></td>
        <td><b>SILVER (1-2 IMAGES <br/>INFO-GRAPHICS)</b></td>
        <td><b>GOLD (2-4 IMAGES <br/>INFO-GRAPHICS)</b></td>
        <td><b>DIAMOND (5-7 IMAGES <br/>INFO-GRAPHICS)</b></td>

      </tr>
      <tr>
        <td></td>
        <td>1-2 Info-graphics<br/>Images + Source File<br/> + Hi Resolution +<br/> Photo Editing + Photo<br/>Retouching/Enhancing</td>
        <td>2-4 Info-graphics<br/>Images + Source File<br/> + Hi Resolution +<br/> Photo Editing + Photo<br/>Retouching/Enhancing</td>
        <td>5-7 Info-graphics<br/>Images + Source File<br/> + Hi Resolution +<br/> Photo Editing + Photo<br/>Retouching/Enhancing</td>

      </tr>
      <tr>
        <td>Source File</td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
      </tr>
      <tr>
        <td>Commercial use</td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
      </tr>
      <tr>
        <td>Conduct research</td>
        <td><CheckIcon className="h-6 w-5 flex-none text-grey-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-grey-400" aria-hidden="true" /></td>
        <td><CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" /></td>
      </tr>
      <tr>
        <td>Data points included</td>
        <td>10</td>
        <td>15</td>
        <td>20</td>
      </tr>
      <tr>
        <td>Revisions</td>
        <td>Unlimited</td>
        <td>Unlimited</td>
        <td>Unlimited</td>
      </tr>
      <tr>
        <td>Delivery Time</td>
        <td>1 day</td>
        <td>1 day</td>
        <td>
        <div>
  <label className="inline-flex">
    <input type="radio" className="form-radio h-4 w-4 text-orange-400" name="deliveryTime" value="1 day" />
    <span className="ml-2 text-sm font-medium text-gray-700">1 day ( + 32.05$)</span>
  </label>
  <br/>
  <label className="inline-flex">
    <input type="radio" className="form-radio h-4 w-4 text-orange-400" name="deliveryTime" value="2 days" />
    <span className="ml-2 text-sm font-medium text-gray-700">2 days</span>
  </label>
</div>
        </td>
      </tr>
      <tr>
        <td></td>
        <td><button className='btn bg-orange-400 text-white hover:bg-orange-500'>Select</button></td>
        <td><button className='btn bg-orange-400 text-white hover:bg-orange-500'>Select</button></td>
        <td><button className='btn bg-orange-400 text-white hover:bg-orange-500'>Select</button></td>
   
      </tr>
    </tbody>
  </table>
</div>
</div>

    <div className="bg-white py-24 sm:py-8 big-packages">
      <div className="mx-auto max-w-7xl px-8 lg:px-18">
      <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="hover1 mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Basic</h3>
            <p className='text-bold'>SILVER (1-2 IMAGES INFO-GRAPHICS)</p>
            <p className="mt-6 text-base leading-7 text-gray-600">
            1-2 Info-graphics Images + Source File + Hi Resolution+ Photo Editing + Photo Retouching/Enhancing
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-orange-400">What’s included</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.includes("Source file") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Source file 
                </li>
              )}
              {includedFeatures.includes("Commercial use") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Commercial use
                </li>
              )}
              {includedFeatures.includes("Commercial use") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-grey-400" aria-hidden="true" />
                  Conduct research
                </li>
              )}
              {includedFeatures.includes("Conduct research") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Data points included : <span className='text-orange-400 text-bold'>10</span>
                </li>
              )}
              {includedFeatures.includes("Revisions") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Revision : <span className='text-orange-400 text-bold'>Unlimited</span>
                </li>
              )}
              {includedFeatures.includes("Delivery Time") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Delivery Time : <span className='text-orange-400 text-bold'>1 day</span>
                </li>
              )}
                          </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">Pay once, own it forever</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                <span className="text-5xl font-bold tracking-tight text-gray-900">37.90</span>
                  <span className="text-5xl font-semibold leading-6 tracking-wide text-gray-600">$</span>
                </p>
                <Link
                  to="/payment"
                  className="mt-10 block w-full rounded-md bg-orange-400 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Buy
                </Link>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  Invoices and receipts available for easy company reimbursement
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="hover1 mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-8 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Standard</h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
            2-4 Info-graphics Images + Source File + Hi Resolution+ Photo Editing + Photo Retouching/Enhancing
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-orange-400">What’s included</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.includes("Source file") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Source file 
                </li>
              )}
              {includedFeatures.includes("Commercial use") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Commercial use
                </li>
              )}
              {includedFeatures.includes("Commercial use") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-grey-400" aria-hidden="true" />
                  Conduct research
                </li>
              )}
              {includedFeatures.includes("Conduct research") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Data points included : <span className='text-orange-400 text-bold'>15</span>
                </li>
              )}
              {includedFeatures.includes("Revisions") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Revision : <span className='text-orange-400 text-bold'>Unlimited</span>
                </li>
              )}
              {includedFeatures.includes("Delivery Time") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Delivery Time : <span className='text-orange-400 text-bold'>1 day</span>
                </li>
              )}
                          </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">Pay once, own it forever</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">54.15</span>
                  <span className="text-5xl font-semibold leading-6 tracking-wide text-gray-600">$</span>
                </p>
                <Link
                  to="/payment"
                  className="mt-10 block w-full rounded-md bg-orange-400 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Buy
                </Link>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  Invoices and receipts available for easy company reimbursement
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="hover1 mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-8 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Premium</h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              <p className='text-bold'>SILVER (1-2 IMAGES INFO-GRAPHICS)</p>
              5-7 Info-graphics Images + Source File + Hi Resolution+ Photo Editing + Photo Retouching/Enhancing
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-orange-400">What’s included</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.includes("Source file") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Source file 
                </li>
              )}
              {includedFeatures.includes("Commercial use") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Commercial use
                </li>
              )}
              {includedFeatures.includes("Commercial use") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-grey-400" aria-hidden="true" />
                  Conduct research
                </li>
              )}
              {includedFeatures.includes("Conduct research") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Data points included : <span className='text-orange-400 text-bold'>20</span>
                </li>
              )}
              {includedFeatures.includes("Revisions") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />
                  Revision : <span className='text-orange-400 text-bold'>Unlimited</span>
                </li>
              )}
              {includedFeatures.includes("Delivery Time") && (
                <li className="flex gap-x-3">
                  <CheckIcon className="h-6 w-8 flex-none text-orange-400" aria-hidden="true" />
                  Delivery Time : <span className='text-orange-400 text-bold'></span>
                  
                  <div>
  <label className="inline-flex">
    <input type="radio" className="form-radio h-4 w-4 text-orange-400" name="deliveryTime" value="1 day" />
    <span className="ml-2 text-sm font-medium text-gray-700">1 day ( + 32.05$)</span>
  </label>
  <br/>
  <label className="inline-flex">
    <input type="radio" className="form-radio h-4 w-4 text-orange-400" name="deliveryTime" value="2 days" />
    <span className="ml-2 text-sm font-medium text-gray-700">2 days</span>
  </label>
</div>

                </li>
              )}
                          </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">Pay once, own it forever</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                <span className="text-5xl font-bold tracking-tight text-gray-900">75.80</span>
                  <span className="text-5xl font-semibold leading-6 tracking-wide text-gray-600">$</span>
                </p>
                <Link
                  to="/payment"
                  className="mt-10 block w-full rounded-md bg-orange-400 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Buy
                </Link>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  Invoices and receipts available for easy company reimbursement
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        </div>
        </div>



<section class="text-gray-600 body-font">
  <div class="container px-10 py-2 mx-auto">
  <h1 class="sm:text-5xl text-3xl font-bold text-center title-font text-orange-400 mb-4">{t("Product Photgraphy FAQs")}</h1>
<br></br>
<br></br>
<br></br>
     <div className="accordion" id="accordionExample">
        <div className="accordion-item bg-white border border-gray-200">
          <h2 className="accordion-header mb-0" id="headingOne">
            <button className="
        accordion-button
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
               <b> Can you create a lifestyle image for my product?</b>
            </button>
          </h2>
          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div className="accordion-body py-4 px-5">
            Absolutely, I can create a wonderful lifestyle image for you, so you can show your product-buyers that how will product look in real.            </div>
          </div>
        </div>
        <div className="accordion-item bg-white border border-gray-200">
          <h2 className="accordion-header mb-0" id="headingTwo">
            <button className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        <b>Do you provide me source files?</b></button>
          </h2>
          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div className="accordion-body py-4 px-5">
            Yes, you will get the source files as well. (Ai/EPS/PDF/PSD/Pr/SVG)

            </div>
          </div>
        </div>
        <div className="accordion-item bg-white border border-gray-200">
          <h2 className="accordion-header mb-0" id="headingThree">
            <button className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              <b>Do you understand requirements about infographics and product images on Amazon/Ebay/Ali-express etc?</b>
            </button>
          </h2>
          <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            <div className="accordion-body py-4 px-5">
            Yes, I am well-versed in the requirements for product and infographics design on various e-commerce platforms, including Amazon, Ebay, and Ali-express.
            </div>
          </div>
        </div>
        <div className="accordion-item bg-white border border-gray-200">
          <h2 className="accordion-header mb-0" id="headingFour">
            <button className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
      " type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
              <b>I do not have product PNGs but have my product's raw images, can you do background removal on these</b>
            </button>
          </h2>
          <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            <div className="accordion-body py-4 px-5">
            Yes, we can. :)            </div>
          </div>
        </div>
      </div>
    <button class="flex mx-auto mt-16 text-white bg-orange-400 border-0 py-2 px-8 focus:outline-none hover:bg-orange-600 rounded text-lg">{t("Have more Questions?")}</button>
  </div>
</section>
    <br></br>
    <br></br>
        
        </>
    )
}


export default Product_Photography;