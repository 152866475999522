import React, { useState } from 'react';
import '../browser/dark.css';
import { ScrollIndicatorPage } from '../browser//scroll';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Resizable from '../browser/logo_slider';
import comp from '../browser//images/service_icons/Amazon-Storefront-Design-icon.png';
import insert_card_desing from '../browser//images/service_icons/insert-card-icon-1a.png';
import package_icon from '../browser/images/service_icons/Packaging-icon.png';
import camera_icon from '../browser/images/service_icons/Product-Photoography-icon.png';
import video_icon from '../browser/images/service_icons/Product-Videography-icon-1a.png';
import logo_design_icon from '../browser/images/service_icons/Logo-design-icon.png';
import EBCC from '../browser/images/service_icons/EBC.png';
import roval from '../browser/images/reviews/roval.jpg';
import tamra from '../browser/images/reviews/tamra.jpeg';
import dustinf from '../browser/images/reviews/dustinf.jpeg';
import andrs from '../browser/images/reviews/andrs.jpeg';
import sam from '../browser/images/reviews/sam.jpeg';
import verified from '../browser/images/reviews/verified.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";
import { OverlayTrigger, Popover } from "react-bootstrap";
import us from './images/back/us.jpg';
import chile from './images/back/flag-chile.jpg'
import canada from './images/back/27114.jpg';
import {  LockClosedIcon } from '@heroicons/react/20/solid'
import Logo_Design from './images/logos_stats/Logo-design-icon-1.png';
import Amazon_Storefront_Design from './images/logos_stats/Amazon-Storefront-Design-icon-1.png';
import Package_Design from './images/logos_stats/Packaging-icon-1.png';
import Product_Photoography_icon from './images/logos_stats/Product-Photoography-icon-1.png';
import Product_Videography_icon from './images/logos_stats/Product-Videography-icon-1.png';
import Images_Design_Icon from './images/logos_stats/images-design-icon-1.png';
import Insert_Card_Icon from './images/logos_stats/insert-card-icon-1.png';
import EBC2 from './images/logos_stats/EBC-image-icon-1.png';
import banner_1 from './images/banner_count/banner_1.png';
import banner_2 from './images/banner_count/banner_2.png';
import banner_3 from './images/banner_count/banner_3.png';
import banner_4 from './images/banner_count/banner_4.png';
import searcher from './images/search-icon-1.png';
import logo_banner from './images/new_logo.png';
import { useEffect } from 'react';

const featuress = [
  {
    src:logo_banner
  }
]

const features = [
  {
    src:banner_2,
    id: 1,
    text:"Product Packaging"
  },
  {
    src:banner_4,
    id: 2,
    text:"Listing Images Design"

  },
  {
    src:banner_1,
    id: 3,
    text:"EBC/A+ Images"

  },
  {
    src:banner_3,
    id: 4,
    text:"Logo Design"

  },
]
const features2 = [
  {
    src:banner_2,
    id: 1,
    text:"Product Packaging"
  },
  {
    src:banner_4,
    id: 2,
    text:"Listing Images Design"

  },
]

function Home() {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    scrollToTop();
  }, []);
  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent default behavior of the right-click event
  };
  const [curr,SetCurr]=useState("currencyDollar");
  // t is used for the language translation
  const { t } = useTranslation('translation');
  const { t: currencyT } = useTranslation('currency');
  ///// Sign Out Function /////
  const navigate=useNavigate();
  const [showService1, setService1] = useState(false);
  const [showService2, setService2] = useState(false);
  const [showService3, setService3] = useState(false);
  const [showService4, setService4] = useState(false);
  const [showService5, setService5] = useState(false);
  const [showService6, setService6] = useState(false);
  const [showService7, setService7] = useState(false);
  // REVIEWS //
  const [showReview1, setshowReview1] = useState(false);
  const [showReview2, setshowReview2] = useState(false);
  const [showReview3, setshowReview3] = useState(false);
  const [showReview4, setshowReview4] = useState(false);
  const [showReview5, setshowReview5] = useState(false);

  const toggleService1 = () => {
    setService1(!showService1);
  };

  const toggleService2 = () => {
    setService2(!showService2);
  };

  const toggleService3 = () => {
    setService3(!showService3);
  };

  const toggleService4 = () => {
    setService4(!showService4);
  };

  const toggleService5 = () => {
    setService5(!showService5);
  };

  const toggleService6 = () => {
    setService6(!showService6);
  };
  const toggleService7 = () => {
    setService7(!showService7);
  };

// Review //
const toggleReview1 = () => {
  setshowReview1(!showReview1);
};

const toggleshowReview2 = () => {
  setshowReview2(!showReview2);
};

const toggleshowReview3 = () => {
  setshowReview3(!showReview3);
};

const toggleshowReview4 = () => {
  setshowReview4(!showReview4);
};

const toggleshowReview5 = () => {
  setshowReview5(!showReview5);
};

// const toggleshowReview6 = () => {
//   setshowReview6(!showReview6);
// };
  // const [hovered, setHovered] = useState(false);

  // const settings2 = {
  //   // dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   centerMode: true,
  //   centerPadding: "40px",
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //   ],
  // };
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "50px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const popover = (
    <Popover id="verified-popover">
      <Popover.Body>Verified</Popover.Body>
    </Popover>
  );
 
  
  return (
   <>
   <ScrollIndicatorPage/>
        


<div className='home_mobile'>
        {/* Section: Design Block */}
        
        <section className="mb-2">
          <div className="relative overflow-hidden bg-no-repeat bg-cover-mobile">
          <div className="hero min-h-screen text-white bg-changer">
  <div className="text-center bg-greener "style={{marginTop: '-430px'}}>
    <div className="">
      <br></br>
      <h1 className="text-4xl font-bold color_code">{t("first_heading")}</h1>
      <div className='container first-container'><p className="py-6 font-bold text-1xl text-black">{t("first_jumbotron_paragraph")}</p>
      </div>
      <form className="flex items-center">   
        <label htmlFor="simple-search" className="sr-only">Search Our Website</label>
        <div className="relative w-full">
        <input type="text" id="simple-search" className="border border-orange-600 text-gray-900 text-sm rounded-full focus:ring-orange-500 focus:border-orange-500 block w-full pl-3 p-2.5 border-width-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-orange-500 dark:focus:border-orange-500" placeholder={t("Search Our Website")} required />
        </div>
        <button type="submit" className="p-0.1 ml-1 text-sm font-medium text-white bg-white  focus:ring-1 focus:outline-none focus:ring-red-300 dark:bg-rede-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
          <img  onContextMenu={handleContextMenu} src={searcher} className='searcherr'/>
        </button>
      </form>
<div className="grid-container">
      <dl className="grid max-w-xl grid-cols-2 gap-x-6 gap-y-14 lg:max-w-none lg:grid-cols-4 lg:gap-y-48">
        {features2.map((feature) => (
          <div key={feature.id} className="relative pl-12">
            <dt className="text-base font-semibold leading-7 text-gray-900">
              <div
                className="absolute right-2 top-10 flex h-34 w-40 items-center justify-center rounded-lg"
                style={{ border: '3px solid orange' }}
              >
                <img
                  src={feature.src}
                  className="picture_start banner_image"
                  onContextMenu={handleContextMenu}
                />
                <br />
              </div>
            </dt>
          </div>
        ))}
      </dl>
    </div>
          <div className="grid-container">
          <dl className="grid max-w-xl grid-cols-2 gap-x-4 gap-y-14 lg:max-w-none lg:grid-cols-4 lg:gap-y-48">
            {features2.map((feature) => (
              <div key={feature.id} className="relative pl-12">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                <div className="absolute right-0 top-48 flex h-38 w-48 items-center justify-center rounded-lg ">
                <p>{feature.text}</p>
                    <br/>
                  </div>
                </dt>
              </div>
            ))}
          </dl>
          </div>
            
          <div className="grid-container">
          <dl className="grid max-w-xl grid-cols-1 gap-x-4 gap-y-14 lg:max-w-none lg:grid-cols-1 lg:gap-y-48">
            {featuress.map((feature) => (
              <div key={feature.id} className="relative pl-12">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                <div className="absolute left-20 top-48 flex h-48 w-56 items-center justify-center rounded-lg ">
                <img src={feature.src} className='banner_image' onContextMenu={handleContextMenu}/>
                    <br/>
                  </div>
                </dt>
              </div>
            ))}
          </dl>
          </div>
</div>
</div>
</div>
</div>  
</section>

        {/* Section: Design Block */}
      </div>
<div className="home" id="home">
<div className="hero min-h-screen text-white bg-changer">
  <div className="text-center bg-greener "style={{marginTop: '-200px'}}>
    <div className="">
      <br></br>
      <h1 className="text-6xl font-bold color_code">{t("first_heading")}</h1>
      <div className='container first-container'><p className="py-6 font-bold text-1xl text-black">{t("first_jumbotron_paragraph")}</p>
      </div>
      <form className="flex items-center">   
        <label htmlFor="simple-search" className="sr-only">Search Our Website</label>
        <div className="relative w-full">
        <input type="text" id="simple-search" className="border border-orange-600 text-gray-900 text-sm rounded-full focus:ring-orange-500 focus:border-orange-500 block w-full pl-3 p-2.5 border-width-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-orange-500 dark:focus:border-orange-500" placeholder={t("Search Our Website")} required />
        </div>
        <button type="submit" className="p-0.1 ml-1 text-sm font-medium text-white bg-white  focus:ring-1 focus:outline-none focus:ring-red-300 dark:bg-rede-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
          <img  onContextMenu={handleContextMenu} src={searcher} className='searcherr'/>
        </button>
      </form>
<div className='container corousel'>
<div className="mx-auto mt-8 max-w-7xl sm:mt-20 lg:mt-10 lg:max-w-4xl">

<div className="grid-container">
      <dl className="grid max-w-xl grid-cols-1 gap-x-6 gap-y-14 lg:max-w-none lg:grid-cols-4 lg:gap-y-48">
        {features.map((feature) => (
          <div key={feature.id} className="relative pl-12">
            <dt className="text-base font-semibold leading-7 text-gray-900">
              <div
                className="absolute right-3 top-0 flex h-34 w-40 items-center justify-center rounded-lg"
                style={{ border: '3px solid orange' }}
              >
                <img
                  src={feature.src}
                  className="picture_start banner_image"
                  onContextMenu={handleContextMenu}
                />
                <br />
              </div>
            </dt>
          </div>
        ))}
      </dl>
    </div>
          <div className="grid-container">
          <dl className="grid max-w-xl grid-cols-1 gap-x-4 gap-y-14 lg:max-w-none lg:grid-cols-4 lg:gap-y-48">
            {features.map((feature) => (
              <div key={feature.id} className="relative pl-12">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                <div className="absolute right-0 top-40 flex h-38 w-48 items-center justify-center rounded-lg ">
                <p>{feature.text}</p>
                    <br/>
                  </div>
                </dt>
              </div>
            ))}
          </dl>
          </div>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
            
          <div className="grid-container">
          <dl className="grid max-w-xl grid-cols-1 gap-x-4 gap-y-14 lg:max-w-none lg:grid-cols-1 lg:gap-y-48">
            {featuress.map((feature) => (
              <div key={feature.id} className="relative pl-12">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                <div className="absolute left-72 top-50 flex h-48 w-56 items-center justify-center rounded-lg ">
                <img src={feature.src} className='banner_image' onContextMenu={handleContextMenu}/>
                    <br/>
                  </div>
                </dt>
              </div>
            ))}
          </dl>
          </div>

        </div>
</div>

</div>

  </div>
  
</div>

</div>
<div id="clients"></div>
 <br></br>
 <br></br>
 <div className="flex " >
  <div className="grid h-20 card flex-grow clients-heading place-items-center  ">  <h1 className='text-center service-css font-bold'>{t("Client_heading")}</h1>
</div>
</div> 
<br></br>

<div className='container center-container'>
  
<Resizable/>
</div>
<br></br>
<br></br>

<hr></hr>
{/* ////////////////////SERVICES///////////// */}
<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10" id="services">
  <h1 className='text-center service-css font-bold'>{t("Service_heading")}</h1>
  <br/>
  <p className='text-center text-black font-bold'>{t("Service_paragraph")}</p>
  <br></br>
      <div className="grid gap-8 row-gap-5 lg:grid-cols-3">
        <div className="relative p-px overflow-hidden transition duration-300 transform border rounded shadow-sm hover:scale-105 group hover:shadow-xl">
          <div className="absolute bottom-0 left-0 w-full h-1 duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
          <div className="absolute bottom-0 left-0 w-1 h-full duration-300 origin-bottom transform scale-y-0 bg-deep-purple-accent-400 group-hover:scale-y-100" />
          <div className="absolute top-0 left-0 w-full h-1 duration-300 origin-right transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
          <div className="absolute bottom-0 right-0 w-1 h-full duration-300 origin-top transform scale-y-0 bg-deep-purple-accent-400 group-hover:scale-y-100" />
          <div className="relative p-5 bg-white rounded-sm">
            <div className="flex flex-col mb-2 lg:items-center lg:flex-row">
              <div className="flex items-center justify-center w-10 h-10 mb-4 mr-2 rounded-full  lg:mb-0">
                <img  onContextMenu={handleContextMenu} src={camera_icon} />
              </div>
              <h6 className="font-bold text-black font-bold">{t("Product_Photography")}</h6>
            </div>
            <p className="mb-2 text-sm text-gray-900">
              {showService1
                ? <span>{t("Product_Photography_paragraph1")}</span>
                : <span>{t("Product_Photography_paragraph2")}</span>}
            </p>
            
            <button onClick={toggleService1} className="uppercase inline-flex items-center text-sm font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
            {showService1 ? <span>{t("Read_Less")}</span> : <span>{t("Read_More")}</span>}
          </button>

          </div>
        </div>
        <div className="relative p-px overflow-hidden transition duration-300 transform border rounded shadow-sm hover:scale-105 group hover:shadow-xl">
          <div className="absolute bottom-0 left-0 w-full h-1 duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
          <div className="absolute bottom-0 left-0 w-1 h-full duration-300 origin-bottom transform scale-y-0 bg-deep-purple-accent-400 group-hover:scale-y-100" />
          <div className="absolute top-0 left-0 w-full h-1 duration-300 origin-right transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
          <div className="absolute bottom-0 right-0 w-1 h-full duration-300 origin-top transform scale-y-0 bg-deep-purple-accent-400 group-hover:scale-y-100" />
          <div className="relative p-5 bg-white rounded-sm">
            <div className="flex flex-col mb-2 lg:items-center lg:flex-row">
              <div className="flex items-center justify-center w-10 h-10 mb-4 mr-2 rounded-full  lg:mb-0">
              <img  onContextMenu={handleContextMenu} src={package_icon}/>
              </div>
              <h6 className="font-bold text-black font-bold leading-5">{t("Packaging Design")}</h6>
            </div>
            <p className="mb-2 text-sm text-gray-900">
              {showService2
                ? <span>{t("Packaging Design Paragraph1")}</span>
                : <span>{t("Packaging Design Paragraph2")}</span>}
            </p>
            
            <button onClick={toggleService2} className="uppercase inline-flex items-center text-sm font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
            {showService2 ? <span>{t("Read_Less")}</span> : <span>{t("Read_More")}</span>}
          </button>
          </div>
        </div>
        <div className="relative p-px overflow-hidden transition duration-300 transform border rounded shadow-sm hover:scale-105 group hover:shadow-xl">
          <div className="absolute bottom-0 left-0 w-full h-1 duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
          <div className="absolute bottom-0 left-0 w-1 h-full duration-300 origin-bottom transform scale-y-0 bg-deep-purple-accent-400 group-hover:scale-y-100" />
          <div className="absolute top-0 left-0 w-full h-1 duration-300 origin-right transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
          <div className="absolute bottom-0 right-0 w-1 h-full duration-300 origin-top transform scale-y-0 bg-deep-purple-accent-400 group-hover:scale-y-100" />
          <div className="relative p-5 bg-white rounded-sm">
            <div className="flex flex-col mb-2 lg:items-center lg:flex-row">
              <div className="flex items-center justify-center w-10 h-10 mb-4 mr-2 rounded-full  lg:mb-0">
                <img  onContextMenu={handleContextMenu} src={video_icon}/>
              </div>
              <h6 className="text-black font-bold leading-5">{t("Videography")}</h6>
            </div>
              
              <p className="mb-2 text-sm text-gray-900">
              {showService3
                ? <span>{t("Videography Paragraph1")}</span>
                : <span>{t("Videography Paragraph2")}</span>}
            </p>
            
            <button onClick={toggleService3} className="uppercase inline-flex items-center text-sm font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
            {showService3 ? <span>{t("Read_Less")}</span> : <span>{t("Read_More")}</span>}
          </button>
          </div>
        </div>
        <div className="relative p-px overflow-hidden transition duration-300 transform border rounded shadow-sm hover:scale-105 group hover:shadow-xl">
          <div className="absolute bottom-0 left-0 w-full h-1 duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
          <div className="absolute bottom-0 left-0 w-1 h-full duration-300 origin-bottom transform scale-y-0 bg-deep-purple-accent-400 group-hover:scale-y-100" />
          <div className="absolute top-0 left-0 w-full h-1 duration-300 origin-right transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
          <div className="absolute bottom-0 right-0 w-1 h-full duration-300 origin-top transform scale-y-0 bg-deep-purple-accent-400 group-hover:scale-y-100" />
          <div className="relative p-5 bg-white rounded-sm">
            <div className="flex flex-col mb-2 lg:items-center lg:flex-row">
              <div className="flex items-center justify-center w-10 h-10 mb-4 mr-2 rounded-full  lg:mb-0">
                <img  onContextMenu={handleContextMenu} src={EBCC} />
              </div>
              <h6 className=" text-black font-bold leading-5">{t("Enhanced Brand Content")}</h6>
            </div>
           
            <p className="mb-2 text-sm text-gray-900">
              {showService4
                ? <span>  {t("Enhanced Brand Content Paragraph1")}</span>
                : <span>  {t("Enhanced Brand Content Paragraph2")}</span>}
            </p>
            
            <button onClick={toggleService4} className="uppercase inline-flex items-center text-sm font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
            {showService4 ? <span>{t("Read_Less")}</span> : <span>{t("Read_More")}</span>}
          </button>
          </div>
        </div>
        <div className="relative p-px overflow-hidden transition duration-300 transform border rounded shadow-sm hover:scale-105 group hover:shadow-xl">
          <div className="absolute bottom-0 left-0 w-full h-1 duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
          <div className="absolute bottom-0 left-0 w-1 h-full duration-300 origin-bottom transform scale-y-0 bg-deep-purple-accent-400 group-hover:scale-y-100" />
          <div className="absolute top-0 left-0 w-full h-1 duration-300 origin-right transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
          <div className="absolute bottom-0 right-0 w-1 h-full duration-300 origin-top transform scale-y-0 bg-deep-purple-accent-400 group-hover:scale-y-100" />
          <div className="relative p-5 bg-white rounded-sm">
            <div className="flex flex-col mb-2 lg:items-center lg:flex-row">
              <div className="flex items-center justify-center w-10 h-10 mb-4 mr-2 rounded-full  lg:mb-0">
              <img  onContextMenu={handleContextMenu} src={logo_design_icon} />
              </div>
              <h6 className="text-black font-bold leading-5">{t("Brand Logo Design")}</h6>
            </div>
            <p className="mb-2 text-sm text-gray-900">

            {showService5
                ? <span> {t("Brand Logo Design Paragraph1")}</span>
                : <span> {t("Brand Logo Design Paragraph2")}</span>}
            </p>
            
            <button onClick={toggleService5} className="uppercase inline-flex items-center text-sm font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
            {showService5 ? <span>{t("Read_Less")}</span> : <span>{t("Read_More")}</span>}
          </button>
          </div>
        </div>
        <div className="relative p-px overflow-hidden transition duration-300 transform border rounded shadow-sm hover:scale-105 group hover:shadow-xl">
          <div className="absolute bottom-0 left-0 w-full h-1 duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
          <div className="absolute bottom-0 left-0 w-1 h-full duration-300 origin-bottom transform scale-y-0 bg-deep-purple-accent-400 group-hover:scale-y-100" />
          <div className="absolute top-0 left-0 w-full h-1 duration-300 origin-right transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
          <div className="absolute bottom-0 right-0 w-1 h-full duration-300 origin-top transform scale-y-0 bg-deep-purple-accent-400 group-hover:scale-y-100" />
          <div className="relative p-5 bg-white rounded-sm">
            <div className="flex flex-col mb-2 lg:items-center lg:flex-row">
              <div className="flex items-center justify-center w-10 h-10 mb-4 mr-2 rounded-full  lg:mb-0">
              <img  onContextMenu={handleContextMenu} src={comp} />
              </div>
              <h6 className="text-black font-bold leading-5">{t("Amazon Storefront Design")}</h6>
            </div>
            <p className="mb-2 text-sm text-gray-900">
            
            {showService6
                ? <span> {t("Amazon Storefront Design Paragraph1")}</span>
                : <span> {t("Amazon Storefront Design Paragraph2")}</span>}
            </p>
            
            <button onClick={toggleService6} className="uppercase inline-flex items-center text-sm font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
            {showService6 ? <span>{t("Read_Less")}</span> : <span>{t("Read_More")}</span>}
          </button>
          </div>
        </div>
        <div className="relative p-px overflow-hidden transition duration-300 transform border rounded shadow-sm hover:scale-105 group hover:shadow-xl">
          <div className="absolute bottom-0 left-0 w-full h-1 duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
          <div className="absolute bottom-0 left-0 w-1 h-full duration-300 origin-bottom transform scale-y-0 bg-deep-purple-accent-400 group-hover:scale-y-100" />
          <div className="absolute top-0 left-0 w-full h-1 duration-300 origin-right transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
          <div className="absolute bottom-0 right-0 w-1 h-full duration-300 origin-top transform scale-y-0 bg-deep-purple-accent-400 group-hover:scale-y-100" />
          <div className="relative p-5 bg-white rounded-sm">
            <div className="flex flex-col mb-2 lg:items-center lg:flex-row">
              <div className="flex items-center justify-center w-10 h-10 mb-4 mr-2 rounded-full  lg:mb-0">
              <img  onContextMenu={handleContextMenu} src={insert_card_desing} />
              </div>
              <h6 className="text-black font-bold leading-5">{t("Insert Cards/Books Design")}</h6>
            </div>
            <p className="mb-2 text-sm text-gray-900">
            
            {showService7
                ? <span> {t("Insert Cards/Books Design Paragraph1")}</span>
                : <span> {t("Insert Cards/Books Design Paragraph2")}</span>}
            </p>
            
            <button onClick={toggleService7} className="uppercase inline-flex items-center text-sm font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
            {showService7 ? <span>{t("Read_Less")}</span> : <span>{t("Read_More")}</span>}
          </button>
          </div>
        </div>
      </div>
    </div>

{/* /////////////STATISTICS//////// */}
<div className='text-center bg-warning'>
<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      {/* <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-4"> */}
       <Slider {...settings}> 
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-3 rounded-full  sm:w-20 sm:h-20">
            <img  onContextMenu={handleContextMenu} src={Package_Design} />
          </div>
          <h6 className="text-4xl font-bold text-white">
            {t("Number of Package Designed")}
          </h6>
          <p className="mb-2 font-bold text-md text-white">{t("Packaging Designed")}</p>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-3 rounded-full bg-grey-500 sm:w-20 sm:h-20">
            <img  onContextMenu={handleContextMenu} src={Images_Design_Icon}/>
          </div>
          <h6 className="text-4xl font-bold text-white">
          {t("Number of Images Designed")}
          </h6>
          <p className="mb-2 font-bold text-md text-white">{t("Images Designed")}</p>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-3 rounded-full  sm:w-20 sm:h-20">
            <img  onContextMenu={handleContextMenu} src={EBC2}/>
          </div>
          <h6 className="text-4xl font-bold text-white">{t("Number of EBC Designed")}</h6>
          <p className="mb-2 font-bold text-md text-white">{t("EBC Designed")}</p>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-3 rounded-full sm:w-20 sm:h-20">
            <img  onContextMenu={handleContextMenu} src={Product_Photoography_icon}/>
          </div>
          <h6 className="text-4xl font-bold text-white">{t("Number of Product Photoshoots")}</h6>
          <p className="mb-2 font-bold text-md text-white">{t("Product Photoshoots")}</p>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-3 rounded-full  sm:w-20 sm:h-20">
            <img  onContextMenu={handleContextMenu} src={Logo_Design}/>
          </div>
          <h6 className="text-4xl font-bold text-white">
          {t("Number of Logos Designed")}
          </h6>
          <p className="mb-2 font-bold text-md text-white">{t("Logos Designed")}</p>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-3 rounded-full  sm:w-20 sm:h-20">
            <img  onContextMenu={handleContextMenu} src={Product_Videography_icon}/>
          </div>
          <h6 className="text-4xl font-bold text-white">
          {t("Number of Product Videography")}
          </h6>
          <p className="mb-2 font-bold text-md text-white">{t("Product Videography")}</p>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-3 rounded-full  sm:w-20 sm:h-20">
            <img  onContextMenu={handleContextMenu} src={Amazon_Storefront_Design}/>
          </div>
          <h6 className="text-4xl font-bold text-white">
          {t("Number of Amazon Storefront Designed")}
          </h6>
          <p className="mb-2 font-bold text-md text-white">{t("Amazon Storefront Designed")}</p>
        </div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-3 rounded-full sm:w-20 sm:h-20">
            <img  onContextMenu={handleContextMenu} src={Insert_Card_Icon}/>
          </div>
          <h6 className="text-4xl font-bold text-white">
          {t("Number of Insert Cards/Books designed")}
          </h6>
          <p className="mb-2 font-bold text-md text-white">{t("Insert Cards/Books designed")}</p>
        </div>
        </Slider>
      {/* </div> */}
    </div>  
</div>


{/* ////////////////////BEST AMAZON CBC//////////////// */}
<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
        <div className="flex flex-col justify-center">
          <div className="max-w-xl mb-6">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight color_code sm:text-4xl sm:leading-none">
            {t("Best Amazon EBC Content Images")}
            </h2>
            <div className="flex items-center">
            <LockClosedIcon style={{ width: "50px", marginRight: "16px" }} className="color_code inline" />
            <p className="text-base text-gray-700 md:text-md">
              <b>
              {t("Best Amazon EBC Content Images Paragraph1")}
              </b>
              <br/>
              <span className="text-base text-gray-700 md:text-sm">{t("Best Amazon EBC Content Images Paragraph11")}</span>
              
            </p>
          </div>
            <div className="flex items-center">
            <LockClosedIcon style={{ width: "70px", marginRight: "16px" }} className="color_code inline" />
            <p className="text-base text-gray-700 md:text-md">
              <b>
              {t("Best Amazon EBC Content Images Paragraph2")}
              </b>
              <br/>
              <span className="text-base text-gray-700 md:text-sm">{t("Best Amazon EBC Content Images Paragraph22")}</span>
            </p>
          </div>
            <div className="flex items-center">
            <LockClosedIcon style={{ width: "100px", marginRight: "16px" }} className="color_code inline" />
            <p className="text-base text-gray-700 md:text-md">
              <b>
              {t("Best Amazon EBC Content Images Paragraph3")}
              </b>
              <br/>
              <span className="text-base text-gray-700 md:text-sm">{t("Best Amazon EBC Content Images Paragraph33")}</span>
            </p>
          </div>
            <div className="flex items-center">
            <LockClosedIcon style={{ width: "88px", marginRight: "16px" }} className="inline color_code" />
            <p className="text-base text-gray-700 md:text-md">
              <b>
              {t("Best Amazon EBC Content Images Paragraph4")}
              </b>
              <br/>
              <span className="text-base text-gray-700 md:text-sm">{t("Best Amazon EBC Content Images Paragraph44")}</span>
            </p>
          </div>
          </div>
        
        </div>
        <div>
          <img
            className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
            src="https://images.pexels.com/photos/927022/pexels-photo-927022.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
            alt=""
          />
        </div>
      </div>
    </div>
<hr></hr>


{/* ////////////PortFolio///////////// */}

<section class="text-gray-600 body-font" id="portfolio">
  <div class="container px-5 py-24 mx-auto">
    <div class="text-center mb-20">
    <h1 className='text-center service-css font-bold'>{t("Portfolio")}</h1>
      {/* <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">{t("Portfolio Paragraph")}</p> */}
    </div>
    <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="orange" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">{t("First Portfolio")}</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="orange" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">{t("Second Portfolio")}</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="orange" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">{t("Third Portfolio")}</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="orange" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">{t("Fourth Portfolio")}</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="orange" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">{t("Fifth Portfolio")}</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="orange" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">{t("Sixth Portfolio")}</span>
        </div>
      </div>
      <div class="p-2 sm:w-1/2 w-full">
        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
          <svg fill="none" stroke="orange" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
            <path d="M22 4L12 14.01l-3-3"></path>
          </svg>
          <span class="title-font font-medium">{t("Seventh Portfolio")}</span>
        </div>
      </div>
    </div>
    <button onClick={()=>{navigate('/portfolio')}} class="flex mx-auto mt-16 text-white color_code_button border-0 py-2 px-8 focus:outline-none rounded text-lg">{t("Detailed Portolio Button")}</button>
  </div>
</section>

{/* ////////////////REVIEWS////////////////// */}

        

        
    <section class="bg-warning text-white" id="reviews">
  <div class="mx-auto max-w-screen-2xl px-4 py-16 sm:px-6 lg:px-8">
    
    <div class="items-end justify-between sm:flex">
      <div class="l">
        <h3 class="text-4xl font-bold tracking-tight sm:text-5xl">
        {t("Reviews Heading")}
        </h3>

      </div>

      <button
        onClick={()=>navigate('/Reviews#reviews')}
        class="mt-8 inline-flex flex-shrink-0 items-center rounded-full border border-grey-600 px-5 py-3 font-medium text-GREY-600  hover:text-white sm:mt-0 lg:mt-8"
      >
        {t("Read all reviews")}

        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="ml-3 h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M14 5l7 7m0 0l-7 7m7-7H3"
          />
        </svg>
      </button>
    </div>

    <div class="mt-8 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
  
      <blockquote class="flex h-full flex-col justify-between bg-white p-12">
        <div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-2 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <img  onContextMenu={handleContextMenu} src={sam}/>
          </div>
        </div>
          <div class="flex gap-0.5 text-green-500 text-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
          </div>

          <div class="mt-4">
            <h3 class="text-lg font-bold text-pink-600">
            <img  onContextMenu={handleContextMenu} src={canada} style={{ width: '8%', height: '8%',whiteSpace:'nowrap', display:'inline', }}/>&nbsp;
            @sambranditt           
            &nbsp;
            <OverlayTrigger trigger="hover" placement="right" overlay={popover}>
            <img  onContextMenu={handleContextMenu} src={verified} style={{width:'6.0%',paddingBottom:'3px'}} className="verified"/>
            </OverlayTrigger>         
            </h3>
  
            <p className="mb-2 text-sm text-gray-900">
              {showReview1
                ? <span>{t("Review12")}</span>
                : <span>{t("Review11")}</span>}
            </p>
            
            <button onClick={toggleReview1} className="uppercase inline-flex items-center text-sm font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
            {showReview1 ? <span>{t("Read_Less")}</span> : <span>{t("Read_More")}</span>}
          </button>
          </div>
        </div>
        {/* <footer class="mt-8 text-gray-500"><b>Country:</b> Canada</footer> */}

      </blockquote>

      <blockquote class="flex h-full flex-col justify-between bg-white p-12">
        
        <div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-2 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <img  onContextMenu={handleContextMenu} src={tamra} />
          </div>
        </div>
          <div class="flex gap-0.5 text-green-500 text-center justify-center">
            
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
          </div>

          <div class="mt-4">
            <h3 class="text-lg font-bold text-pink-600">

            <img  onContextMenu={handleContextMenu} src={us} style={{ width: '8%', height: '8%',whiteSpace:'nowrap', display:'inline', }}/>&nbsp;
              @tamarawalker73
              &nbsp;
            <OverlayTrigger trigger="hover" placement="right" overlay={popover}>
            <img  onContextMenu={handleContextMenu} src={verified} style={{width:'6.0%',paddingBottom:'3px'}} className="verified"/>
            </OverlayTrigger>      
            </h3>

   
            <p className="mb-2 text-sm text-gray-900">
              {showReview2
                ? <span>{t("Review22")}</span>
                : <span>{t("Review21")}</span>}
            </p>
            
            <button onClick={toggleshowReview2} className="uppercase inline-flex items-center text-sm font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
            {showReview2 ? <span>{t("Read_Less")}</span> : <span>{t("Read_More")}</span>}
          </button>
          </div>
        </div>
        {/* <footer class="mt-8 text-gray-500"><b>Country:</b> United States</footer> */}

      </blockquote>

      <blockquote class="flex h-full flex-col justify-between bg-white p-12">
        <div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-2 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <img  onContextMenu={handleContextMenu} src={roval} className="review_image"/>
          </div>
        </div>
          <div class="flex gap-0.5 text-green-500 text-center justify-center">
            
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
          </div>

          <div class="mt-4">
            <h3 class="text-lg font-bold text-pink-600">

            <img  onContextMenu={handleContextMenu} src={us} style={{ width: '8%', height: '8%',whiteSpace:'nowrap', display:'inline', }}/>&nbsp;
              @royalphoxx1
              &nbsp;
            <OverlayTrigger trigger="hover" placement="right" overlay={popover}>
            <img  onContextMenu={handleContextMenu} src={verified} style={{width:'6.0%',paddingBottom:'3px'}} className="verified"/>
            </OverlayTrigger>                 </h3>


            <p className="mb-2 text-sm text-gray-900">
              {showReview3
                ? <span>{t("Review32")}</span>
                : <span>{t("Review31")}</span>}
            </p>
            
            <button onClick={toggleshowReview3} className="uppercase inline-flex items-center text-sm font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
            {showReview3 ? <span>{t("Read_Less")}</span> : <span>{t("Read_More")}</span>}
          </button>
          </div>
          {/* <footer class="mt-8 text-gray-500"><b>Country:</b> United States</footer> */}

        </div>

      </blockquote>

      <blockquote class="flex h-full flex-col justify-between bg-white p-12">
        <div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-2 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <img  onContextMenu={handleContextMenu} src={andrs} className="review_image"/>
          </div>
        </div>
          <div class="flex gap-0.5 text-green-500 text-center justify-center">
            
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
          </div>

          <div class="mt-4">
            <h3 class="text-lg font-bold text-pink-600">

            <img  onContextMenu={handleContextMenu} src={chile} style={{ width: '8%', height: '8%',whiteSpace:'nowrap', display:'inline', }}/>&nbsp;
              @andrssilva43
              &nbsp;
            <OverlayTrigger trigger="hover" placement="right" overlay={popover}>
            <img  onContextMenu={handleContextMenu} src={verified} style={{width:'6.0%',paddingBottom:'3px'}} className="verified"/>
            </OverlayTrigger>   
                           </h3>
            <br/>
            <p className="mb-2 text-sm text-gray-900">
              {showReview4
                ? <span>{t("Review42")}</span>
                : <span>{t("Review41")}</span>}
            </p>
            
            <button onClick={toggleshowReview4} className="uppercase inline-flex items-center text-sm font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
            {showReview4 ? <span>{t("Read_Less")}</span> : <span>{t("Read_More")}</span>}
          </button>
          </div>
        </div>

        {/* <footer class="mt-8 text-gray-500"><b>Country:</b> Chile</footer> */}
      </blockquote>

      <blockquote class="flex h-full flex-col justify-between bg-white p-12">
        <div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-2 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <img  onContextMenu={handleContextMenu} src={dustinf} className="review_image"/>
          </div>
        </div>
          <div class="flex gap-0.5 text-green-500 text-center justify-center">
                        <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="#C46500"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
          </div>

          <div class="mt-4">
            <h3 class="text-lg font-bold text-pink-600">

            <img  onContextMenu={handleContextMenu} src={canada} style={{ width: '8%', height: '8%',whiteSpace:'nowrap', display:'inline', }}/>&nbsp;
            @dustingilmour
            &nbsp;
            <OverlayTrigger trigger="hover" placement="right" overlay={popover}>
            <img  onContextMenu={handleContextMenu} src={verified} style={{width:'6.0%',paddingBottom:'3px'}} className="verified"/>
            </OverlayTrigger>      
            </h3>
            <br/>
            <p className="mb-2 text-sm text-gray-900">
              {showReview5
                ? <span>{t("Review52")}</span>
                : <span>{t("Review51")}</span>}
            </p>
            
            <button onClick={toggleshowReview5} className="uppercase inline-flex items-center text-sm font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
            {showReview5 ? <span>{t("Read_Less")}</span> : <span>{t("Read_More")}</span>}
          </button>
          </div>
        </div>

        {/* <footer class="mt-8 text-gray-500"><b>Country: </b>Canada</footer> */}
      </blockquote>
      <blockquote class="flex h-full flex-col justify-between bg-white p-12">
        <div>
        <div className="text-center">
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-2 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
            <img  onContextMenu={handleContextMenu} src={dustinf} className="review_image"/>
          </div>
        </div>
          <div class="flex gap-0.5 text-green-500 text-center justify-center">

          </div>

          <div class="mt-4 text-center">
            <h3 class="text-lg font-bold text-pink-600">

              
            Videos
            </h3>


          </div>
        </div>

      </blockquote>
    </div>
  </div>
  
</section>





{/* ////////////BLOGS//////////////// */}
<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20" id="blogs">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
      <h1 className='text-center service-css font-bold'>{t("Blog Heading")}</h1>


      </div>
      <div className="grid max-w-sm gap-5 mb-8 lg:grid-cols-3 sm:mx-auto lg:max-w-full">
        <div className="px-10 py-20 border rounded lg:px-5 lg:py-10 xl:py-20">
        <img  onContextMenu={handleContextMenu} className="logo-image-center" src="https://customercare.igloosoftware.com/.api2/api/v1/communities/10068556/previews/thumbnails/4fc20722-5368-e911-80d5-b82a72db46f2?width=680&height=680&crop=False"/>
<br></br>
          <p className="mb-2 text-xs text-center  font-semibold tracking-wide text-gray-600 uppercase">
          
          {t("First Blog")}
          </p>
          <a
            href="/"
            className="inline-block max-w-xs mx-auto mb-3 text-2xl font-extrabold leading-7 transition-colors duration-200 hover:text-deep-purple-accent-400"
            aria-label="Read article"
            title="Nori grape silver beet broccoli kombu beet"
          >
            {t("First Blog Title")}
        </a>
          <p className=" text-gray-700">
          {t("First Blog Paragraph")}
          </p>
          <a
            href="/"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
            {t("Read More Button")}
          </a>
        </div>
        <div className="px-10 py-20 border rounded lg:px-5 lg:py-10 xl:py-20">
        <img  onContextMenu={handleContextMenu} className="logo-image-center" src="https://customercare.igloosoftware.com/.api2/api/v1/communities/10068556/previews/thumbnails/4fc20722-5368-e911-80d5-b82a72db46f2?width=680&height=680&crop=False"/>
<br></br>
          <p className="mb-2 text-xs text-center  font-semibold tracking-wide text-gray-600 uppercase">
          {t("Second Blog")}
         </p>
          <a
            href="/"
            className="inline-block max-w-xs mx-auto mb-3 text-2xl font-extrabold leading-7 transition-colors duration-200 hover:text-deep-purple-accent-400"
            aria-label="Read article"
            title="Nori grape silver beet broccoli kombu beet"
          >
          {t("Second Blog Title")}
                     </a>
          <p className=" text-gray-700">
          {t("Second Blog Paragraph")}
          </p>
          <a
            href="/"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
            {t("Read More Button")}
          </a>
        </div>
        <div className="px-10 py-20 border rounded lg:px-5 lg:py-10 xl:py-20">
        <img  onContextMenu={handleContextMenu} className="logo-image-center" src="https://customercare.igloosoftware.com/.api2/api/v1/communities/10068556/previews/thumbnails/4fc20722-5368-e911-80d5-b82a72db46f2?width=680&height=680&crop=False"/>
<br></br>
          <p className="mb-2 text-xs text-center  font-semibold tracking-wide text-gray-600 uppercase">
          {t("Third Blog")}
                    </p>
          <a
            href="/"
            className="inline-block max-w-xs mx-auto mb-3 text-2xl font-extrabold leading-7 transition-colors duration-200 hover:text-deep-purple-accent-400"
            aria-label="Read article"
            title="Nori grape silver beet broccoli kombu beet"
          >
        {t("Third Blog Title")}
                     </a>
          <p className=" text-gray-700">
          {t("Third Blog Paragraph")}                    </p>
          <a
            href="/"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
            {t("Read More Button")}
          </a>
        </div>
      </div>
    </div>



<hr></hr>




       </>
  )
}


export default Home;