import { Link, useNavigate } from "react-router-dom";
import './signin.css';
import {db} from '../firestore.js';
import { useState } from "react";
import { getAuth,signInWithEmailAndPassword,GoogleAuthProvider,signInWithPopup, } from "firebase/auth";
import {  doc,getDoc,setDoc} from "firebase/firestore"; // import Firestore functions
import signin_picture from './images/web_images/sign-in.png';
function SignIn()
{
  
  function Letter_Image(initial)
  {
    if(initial==="A")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f4/Eo_circle_orange_letter-a.svg/2048px-Eo_circle_orange_letter-a.svg.png"
    }
    if(initial==="B")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Eo_circle_orange_letter-b.svg/1200px-Eo_circle_orange_letter-b.svg.png"
    }
    if(initial==="C")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0e/Eo_circle_orange_letter-c.svg/1200px-Eo_circle_orange_letter-c.svg.png"
    }
    if(initial==="D")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/Eo_circle_orange_letter-d.svg/1200px-Eo_circle_orange_letter-d.svg.png";
    }
    if(initial==="E")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d3/Eo_circle_orange_letter-e.svg/1200px-Eo_circle_orange_letter-e.svg.png";
    }
    if(initial==="F")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Eo_circle_orange_letter-f.svg/1200px-Eo_circle_orange_letter-f.svg.png";
    }
    if(initial==="G")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c7/Eo_circle_orange_letter-g.svg/2048px-Eo_circle_orange_letter-g.svg.png";
    }
    if(initial==="H")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Eo_circle_orange_white_letter-h.svg/1200px-Eo_circle_orange_white_letter-h.svg.png";
    }
    if(initial==="I")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7b/Eo_circle_orange_letter-i.svg/1200px-Eo_circle_orange_letter-i.svg.png";
    }
    if(initial==="J")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/Eo_circle_orange_letter-j.svg/1024px-Eo_circle_orange_letter-j.svg.png";
    }
    if(initial==="K")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/3/37/Eo_circle_orange_letter-k.svg/1200px-Eo_circle_orange_letter-k.svg.png";
    }
    if(initial==="L")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0a/Eo_circle_orange_letter-l.svg/1200px-Eo_circle_orange_letter-l.svg.png";
    }
    if(initial==="M")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/3/32/Eo_circle_orange_letter-m.svg/1200px-Eo_circle_orange_letter-m.svg.png";
    }
    if(initial==="N")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Eo_circle_orange_white_letter-n.svg/1200px-Eo_circle_orange_white_letter-n.svg.png";
    }
    if(initial==="O")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Eo_circle_orange_letter-o.svg/1200px-Eo_circle_orange_letter-o.svg.png";
    }
    if(initial==="P")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Eo_circle_orange_letter-p.svg/2048px-Eo_circle_orange_letter-p.svg.png";
    }
    if(initial==="Q")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/Eo_circle_orange_white_letter-q.svg/240px-Eo_circle_orange_white_letter-q.svg.png";
    }
    if(initial==="R")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c6/Eo_circle_orange_letter-r.svg/1200px-Eo_circle_orange_letter-r.svg.png";
    }
    if(initial==="S")
    {
      return "https://cdn.pixabay.com/photo/2022/03/17/06/08/letter-s-7073833_1280.png";
    }
    if(initial==="T")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Eo_circle_orange_letter-t.svg/1200px-Eo_circle_orange_letter-t.svg.png";
    }
    if(initial==="U")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5a/Eo_circle_orange_letter-u.svg/1024px-Eo_circle_orange_letter-u.svg.png";
    }
    if(initial==="V")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Eo_circle_orange_letter-v.svg/1200px-Eo_circle_orange_letter-v.svg.png";
    }
    if(initial==="W")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Eo_circle_orange_white_letter-w.svg/2048px-Eo_circle_orange_white_letter-w.svg.png";
    }
    if(initial==="X")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/Eo_circle_orange_letter-x.svg/1200px-Eo_circle_orange_letter-x.svg.png";
    }
    if(initial==="Y")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Eo_circle_orange_white_letter-y.svg/1024px-Eo_circle_orange_white_letter-y.svg.png";
    }
    if(initial==="Z")
    {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/8/81/Eo_circle_orange_letter-z.svg/2048px-Eo_circle_orange_letter-z.svg.png";
    }

  }


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  {/* Sign In FUNCTION */}
  const onLogin = (e) => {
    // e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user;
        if(user.emailVerified)
        {
          const docSnapshot = await getDoc(doc(db, "users", user.email));
          // console.log(docSnapshot.data());
          // const data=docSnapshot.data();
        
          if (docSnapshot.exists()) {
            const data=docSnapshot.data();
            localStorage.setItem('UserData', JSON.stringify({name:data.name,email:data.email,image:data.image,account:data.account,uid:data.uid}));
          }
          const checker=localStorage.getItem("UserData");
          navigate("/");
        }        
        else
        {
          await auth.signOut(); // wait for signOut to complete before alerting user
          alert("Please verify your email");
        }
    })
  
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage)
    });
}
const auth = getAuth();
const provider = new GoogleAuthProvider();


const onGoogleLogin = async () => {

    try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        const image=Letter_Image(user.displayName[0]);
        const docSnapshot = await getDoc(doc(db, "users", user.email));
        console.log(docSnapshot.data());
        
        if (docSnapshot.exists()) {
          console.log("User already exists");
          const data=docSnapshot.data();
          localStorage.setItem('UserData', JSON.stringify({name:data.name,email:data.email,image:data.image,account:data.account,uid:data.uid}));
          const checker=localStorage.getItem("UserData");
          navigate('/')
        }
        else{
      const docRef = doc(db, "users", user.email);
      await setDoc(docRef, {
        name: user.displayName,
        email: user.email,
        image: image,
        uid: user.uid,
        account:"gmail"
      });
      localStorage.setItem('UserData', JSON.stringify({uid:user.uid,name:user.name,email:user.email,image:image,account:user.account}));
      const checker=localStorage.getItem("UserData");
      // alert(checker);
      navigate('/');
    }
   } catch (error) {
      console.log(error.message);
    }
  };


    return(
        <>

{/* The button to open modal */}

{/* Put this part before </body> tag */}

     <section className="h-screen">
        <div className="px-6 h-full">
          <div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">
            <div className="grow-0 shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0">
              <img src={signin_picture} className="w-full" alt="Sample image" />
            </div>
            <div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
              <form>




                {/* Email input */}
                <div className="mb-6">
                  <input  type="text" className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleFormControlInput1" placeholder="Email address"
                  value={email}
                  onChange={(e)=>{setEmail(e.target.value)}}
                   />
                </div>
                {/* Password input */}
                <div className="mb-6">
                  <input type="password" className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleFormControlInput2" placeholder="Password" 
                  value={password}
                  onChange={(e)=>{setPassword(e.target.value)}}/>
                </div>
                <div className="flex justify-between items-center mb-6">
    
                  <a href="#!" className="text-gray-800">Forgot password?</a>
                </div>

                <div className="text-center lg:text-left">
                  <button onClick={()=>{onLogin()}} type="button" className="color_code_button inline-block px-7 py-3 font-medium text-sm leading-snug uppercase rounded shadow-md  hover:shadow-lg  active:shadow-lg transition duration-150 ease-in-out">
                    Login
                  </button>
                  <p className="text-sm font-semibold mt-2 pt-1 mb-0">
                    Don't have an account?
                    <br></br>
                    <Link to="/registration" className="text-red-600 hover:text-red-700 focus:text-red-700 transition duration-200 ease-in-out">Register</Link>
                  </p>
                </div>
                <div className="flex items-center my-4 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5">
                  <p className="text-center font-semibold mx-4 mb-0">Or</p>
                </div>
                              {/* Google */}
              <div class="button-container">
              <a onClick={()=>{onGoogleLogin()}} class="google-btn olor_code_button ">
                <p class="btn-text"><b>Sign in with Google</b></p>
                <div class="google-icon-wrapper">
                  <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
                </div>
              </a>
            </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <br></br>
</>
    )
}

export default SignIn;