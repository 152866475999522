import React from 'react';

const downloadImage = async (imageUrl, cssProperties) => {
  try {
    const image = new Image();
    image.crossOrigin = 'anonymous';

    // Create a promise to track the image loading
    const imageLoadPromise = new Promise((resolve, reject) => {
      image.onload = resolve;
      image.onerror = reject;
    });

    image.src = imageUrl;

    await imageLoadPromise;

    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const ctx = canvas.getContext('2d');

    applyCssProperties(ctx, cssProperties);

    ctx.drawImage(image, 0, 0);

    canvas.toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'image.jpg';
      link.click();

      URL.revokeObjectURL(url);
    }, 'image/jpeg');
  } catch (error) {
    console.error('Error downloading image:', error);
  }
};

const applyCssProperties = (ctx, cssProperties) => {
  const { filter, opacity } = cssProperties;

  if (filter) {
    ctx.filter = filter;
  }

  if (opacity) {
    ctx.globalAlpha = parseFloat(opacity);
  }
};

const App = () => {
  const handleClickDownload = () => {
    const imageUrl = 'https://t2.gstatic.com/licensed-image?q=tbn:ANd9GcRZcmlaOZxV_nbS5cWjzzCB1i3PnTRqawOv0-EzUWZ15gTTCyaua_Ngv5hutODmYy7y';
    const cssProperties = {
      filter: 'grayscale(100%)',
      opacity: '0.5',
      // Add any other custom CSS properties you want to apply
    };

    downloadImage(imageUrl, cssProperties);
  };

  return (
    <div>
      <button onClick={handleClickDownload}>Download Image</button>
    </div>
  );
};

export default App;
