import logo from '../browser/images/logo.png';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { CurrencyDollarIcon, BellIcon,ChevronDownIcon,LanguageIcon,ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from "react";
import{auth} from '../firestore.js';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { changeLanguage,changeCurrency } from '../i18n';
import { useState,useEffect } from "react";
import {   
  serverTimestamp,
  onValue,
  ref,
  get,
  update,
  child,

} from 'firebase/database';
import { dbreal } from '../firestore.js';
import emailjs from 'emailjs-com';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
function Navbar()
{




   const data = localStorage.getItem('UserData');
  const data2 = JSON.parse(data);
  const imageSrc = data2.image;
  const email = data2.email;
  const uid=data2.uid;
  const [usersWithMessages, setUsersWithMessages] = useState([]);
  const [userImages, setUserImages] = useState({});
  const [userEmail, setUseremail] = useState({});
  const [userUnreadCounts, setUserUnreadCounts] = useState({});
  const [messages, setMessages] = useState([]);  useEffect(() => {
    
    const fetchUsersWithMessages = async () => {
      const messagesData = [];
      const userUnreadCountsObj = {};

        try {
          const messagesRef = ref(dbreal, "users");
          const messagesSnapshot = await get(messagesRef);

          if (messagesSnapshot.exists()) {
            const promises = []; // Array to store promises
  
            messagesSnapshot.forEach((childSnapshot) => {
              const userId = childSnapshot.key;
      const messagesRef = ref(dbreal, `users/${uid}/messages`);
    
      onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          Object.keys(data).forEach((key) => {
            if(data[key].email!=email){
            if(key==="images"){
              Object.keys(data[key]).forEach((key2) => {
                // if(key2!=="readbyadmin"){
                //   console.log(data[key][key2]);
                //   messagesData.push({ id: key2, ...data[key][key2] });
                // }
            })
            }
            if(key==="offer"){
              Object.keys(data[key]).forEach((key2) => {
                // if(key2!=="readbyadmin"){
                //   console.log(data[key][key2]);
                //   messagesData.push({ id: key2, ...data[key][key2] });
                // }
            })
            }
            else{
              
            messagesData.push({ id: key, ...data[key] });
            // console.log(key ," ", data[key].email);
            if (data[key].readbyuser!==true) {
              userUnreadCountsObj[key] = 1;
            } else {
              // Add the key with an initial value of 1
              userUnreadCountsObj[key] = 0;

            }

            }
            }
          });
        }
        // Sort the messages by createdAt timestamp
 
      });
    })
  }
    messagesData.sort((a, b) => a.createdAt - b.createdAt);
    const latestMessages = messagesData.slice(-5);
    const reversedMessages = latestMessages.reverse();
    setMessages(reversedMessages);
    setUserUnreadCounts(userUnreadCountsObj);

}
catch(error)
{

}
}      
const interval = setInterval(() => {
  fetchUsersWithMessages();
  
}, 3000); // Check for new messages every 3 seconds (adjust the interval as needed)

return () => {
  clearInterval(interval); // Clear the interval when the component unmounts
};
}, []);
  // useEffect(() => {
  //   const fetchUsersWithMessages = async () => {
  //     try {
  //       const messagesRef = ref(dbreal, 'users');
  //       const messagesSnapshot = await get(messagesRef);
  
  //       const usersData = [];
  //       const userUnreadCountsObj = {};
  //       const image = {};
  //       const email = {};
  
  //       if (messagesSnapshot.exists()) {
  //         const promises = []; // Array to store promises
  
  //         messagesSnapshot.forEach((childSnapshot) => {
  //           const userId = childSnapshot.key;
  //           const userMessageRef = ref(dbreal, `users/${userId}/messages/`);
  //           usersData.push({ id: userId});

  //           const promise = get(userMessageRef)
  //             .then((snapshot) => {
  //               if (snapshot.exists()) {
  //                 const messagesData = snapshot.val();
  //                 const nestedCollectionKeys = Object.keys(messagesData);
  //                 for (const key of nestedCollectionKeys) {
                                          
  //                     const collection1Data = messagesData[key];
  //                     const readByAdmin = collection1Data.readbyuser;
  //                     const userImage = collection1Data.image;
  //                     const userEmail = collection1Data.email;
  //                     const sender = collection1Data.user;
  //                     if (sender === "sender") {
  //                       image[userId] = userImage;
  //                       email[userId] = userEmail;
  //                     }
  
  //                     if (readByAdmin !== true) {
  //                       if (userUnreadCountsObj.hasOwnProperty(userId)) {
  //                         // Increment the existing value
  //                         userUnreadCountsObj[userId] += 1;
  //                       } else {
  //                         // Add the key with an initial value of 1
  //                         userUnreadCountsObj[userId] = 1;

  //                       }
  //                     }
  //                   // }
  //                 }
  //               } else {
  //                 console.log("No data available");
  //               }
  //             })
  //             .catch((error) => {
  //               console.error(error);
  //             });
  
  //           promises.push(promise); 
  //         });
  
  //         await Promise.all(promises);
  //       }
  
  //       setUseremail(email);
  //       setUserImages(image);
  //       setUserUnreadCounts(userUnreadCountsObj);
  //       setUsersWithMessages(usersData);
  //       setUserUnreadCounts(userUnreadCountsObj);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };
  
  //   const interval = setInterval(() => {
  //     fetchUsersWithMessages();
  //     }, 3000); // Check for new messages every 5 seconds (adjust the interval as needed)
    
  //     return () => {
  //       clearInterval(interval); // Clear the interval when the component unmounts
  //     };  }, []);

  
  const message=()=>
      {
        navigate('/Messages')
      }
  
  

////////// THIS IS THE QUOTATION ///////////

const [Quote_Name, setQuoteName] = useState('');
const [Quote_Email, setQuoteEmail] = useState('');
const [Quote_Requirements, setQuoteRequirements] = useState('');
const [imageUrl, setImageUrl] = useState('');
const [file, setFile] = useState(null);

function handleFileInputChange(event) {
    setFile(event.target.files[0]);
  alert(file);
}


const send_quote = async (event)=>{
  
  event.preventDefault(); // Prevent the page from reloading
  const templateParams = {
    Quote_Name: Quote_Name,
    Quote_Email: Quote_Email,
    Quote_Requirements:Quote_Requirements,
    image: JSON.stringify(file),


  };
  const response = await emailjs.send(
    'service_4jypwyh',
    'template_e862x6t',
    templateParams,
    'TZfgN4-LQgxLSQvLG'
  );
  console.log(response);
  
} 


    const [curr,SetCurr]=useState("currencyDollar");
    // t is used for the language translation
    const { t } = useTranslation('translation');
    const { t: currencyT } = useTranslation('currency');
    const navigate=useNavigate();
    const handleLogout = async () => {
      try {
        await auth.signOut();
        console.log('User logged out successfully');
        navigate('/');
      } catch (error) {
        console.log(error.message);
      }
    };


//////// MENU ITEM ////////////////
  
    const menuItems = [
      { label: 'Profile', link: '/profile' },
      { label: 'Past Projects', link: '/past-projects' },
      { label: 'Messages', link: '/messages' },
    ];

    ////////// THE CODE /////////////////
    return(
      <div className="navbar bg-white px-5 sm:px-5 py-3 text-gray-900 fixed w-full z-20 top-1 left-0 border-b border-gray-300 dark:border-gray-600">
      <div className="navbar-start">
    {/* START OF MOBILE NAVBAR */}
    <div className="dropdown">
      <label tabIndex={0} className="btn btn-ghost lg:hidden">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
      </label>
      <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-white text-gray-900 rounded-box w-52">
      <li><HashLink to="/#home">Home</HashLink></li>
      <li tabIndex={0}>
        <HashLink to="/#services">
        {t("Services")}
          <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"/></svg>
        </HashLink>
        <ul className="p-2 bg-white">
        <li><HashLink to="/productphotography">{t("Product Photography")}</HashLink></li>
          <li><HashLink to="/packagingdesign">{t("Packaging Design")}</HashLink></li>
          <li><a>{t("Videography")}</a></li>
          <li><HashLink to="/ebc">{t("Enhanced Brand Content")}</HashLink></li>
          <li><HashLink to="/brandlogodesign">{t("Brand Logo Design")}</HashLink></li>
        </ul>
      </li>
      <li tabIndex={0}>
      <HashLink to="">
        {t("Portfolio")}
          <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"/></svg>
        </HashLink>
        <ul className="p-2 bg-white">
          <li><HashLink to="/portfolio#PPD">{t("Product Packaging Designs")}</HashLink></li>
          <li><HashLink to="/portfolio#ICD">{t("Insert/thank you card designs")}</HashLink></li>
          <li><HashLink to="/portfolio#LIC">{t("Listing Images Creations")}</HashLink></li>
          <li><HashLink to="/portfolio#EACI">{t("EBC/A+ Content Images")}</HashLink></li>
          <li><HashLink to="/portfolio#">{t("Products Photography")}</HashLink></li>
          <li><HashLink to="/portfolio#BLD">{t("Brand Logo Design")}</HashLink></li>
          <li><HashLink to="/portfolio#ASD">{t("Amazon Storefront Designs")}</HashLink></li>
        </ul>
      </li>
      <li><HashLink to="/#reviews">{t("Reviews")}</HashLink></li>
      <li><HashLink to="/#blogs" href="#blogs">{t("Blogs")}</HashLink></li>
      <li><Link to="/about">{t("About Us")}</Link></li>
      <li><label htmlFor="my-modal-4" className=" text-white bg-warning border-0 focus:outline-none hover:bg-orange-600 rounded text-md">{t("Get a Quote")}</label></li>
      
      </ul>
    </div>

    {/* END OF MOBILE NAVBAR */}
          <HashLink to='/' className="flex items-center">
            <img src={logo} className="h-6 mr-3 sm:h-9 logo" alt="Flowbite Logo" />
          </HashLink>  
          </div>
  <div className="navbar-center hidden lg:flex">
    <ul className="flex gap-x-0 menu menu-horizontal text-sm " >
    <li><HashLink to="/#home">{t("Home")}</HashLink></li>
      <li tabIndex={0}>
      <HashLink to="/#services">
        {t("Services")}
          <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
        </HashLink>
        <ul className="p-2 bg-white">
        <li><HashLink to="/productphotography">{t("Product Photography")}</HashLink></li>
          <li><HashLink to="/packagingdesign">{t("Packaging Design")}</HashLink></li>
          <li><a>{t("Videography")}</a></li>
          <li><HashLink to="/ebc">{t("Enhanced Brand Content")}</HashLink></li>
          <li><HashLink to="/brandlogodesign">{t("Brand Logo Design")}</HashLink></li>
        </ul>
      </li>
      <li tabIndex={0}>
        <HashLink to="/#portfolio">
        {t("Portfolio")}
          <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
        </HashLink>
        <ul className="p-2 bg-white">
          <li><HashLink to="/portfolio#PPD">{t("Product Packaging Designs")}</HashLink></li>
          <li><HashLink to="/portfolio#ICD">{t("Insert/thank you card designs")}</HashLink></li>
          <li><HashLink to="/portfolio#LIC">{t("Listing Images Creations")}</HashLink></li>
          <li><HashLink to="/portfolio#EACI">{t("EBC/A+ Content Images")}</HashLink></li>
          <li><HashLink to="/portfolio#">{t("Products Photography")}</HashLink></li>
          <li><HashLink to="/portfolio#BLD">{t("Brand Logo Design")}</HashLink></li>
          <li><HashLink to="/portfolio#ASD">{t("Amazon Storefront Designs")}</HashLink></li>
        </ul>
      </li>
      
      <li><HashLink to="/#reviews">{t("Reviews")}</HashLink></li>
      <li><HashLink to="/#blogs" href="#blogs">{t("Blogs")}</HashLink></li>
      <li><Link to="/about">{t("About Us")}</Link></li>
      <li><label htmlFor="my-modal-4" className=" text-white bg-warning border-0 focus:outline-none hover:bg-orange-600 rounded text-md">{t("Get a Quote")}</label>
</li>
    </ul>
  </div>
   <div className="navbar-end ">
   <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-0.5 rounded-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
        <CurrencyDollarIcon className="h-5 w-4" aria-hidden="true" />
        {/* {currencyT('currency')} */}
          <ChevronDownIcon className="-mr-1 h-5 w-3 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-4 mt-2 w-26 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-0">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                  onClick={() => {changeCurrency('currencyDollar');SetCurr("currencyDollar")}}
                >
                  USD
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm'
                )}
                onClick={() => {changeCurrency('currencyPound');SetCurr("currencyPound")}}

                >
                  Euro
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
    &nbsp;
    &nbsp;
  <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-0.5 rounded-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
        <LanguageIcon className="h-5 w-4" aria-hidden="true" />
        {t('language_option')}
          <ChevronDownIcon className="-mr-1 h-5 w-3 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-4 mt-2 w-26 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-0">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                  onClick={() => changeLanguage('en')}
                >
                  English
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                  onClick={() => changeLanguage('fr')}
                >
                  French
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm'
                )}
                onClick={() => changeLanguage('de')}

                >
                  German
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm'
                )}
                onClick={() => changeLanguage('it')}

                >
                  Italian
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm'
                )}
                onClick={() => changeLanguage('es')}

                >
                  Spanish
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
    &nbsp;

                {/* FOR MESSAGE */}

                <Menu as="div" className="relative">
            <div>
              <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-1 focus:ring-orange-400 focus:ring-offset-2 focus:ring-offset-orange-400">
                <span className="sr-only">View notifications</span>
                {/* <ChatBubbleLeftEllipsisIcon className="h-6 w-6 text-orange-500" aria-hidden="true" /> */}
                <BellIcon className="h-6 w-6 text-orange-500" aria-hidden="true" />
                {Object.values(userUnreadCounts).some((count) => count > 0) && (
                  <span className="flex items-center justify-center mr-2 h-2 w-2 rounded-full bg-red-500 text-white text-xs">
                  </span>
                )}
              </Menu.Button>
            </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
          <Menu.Items
          static
          className="absolute right-0 z-10 mt-4 w-64 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
            <Menu.Item>
          <div className="px-4 py-2 font-bold text-gray-700">Inbox</div>
        </Menu.Item>
        {messages.filter(user => user.id !== 'images' && user.id !== 'offer').map((user) => (
  <Menu.Item key={user.id}>
    {({ active }) => (
      <>
        <button
          onClick={() => message(user.uid)}
          className={classNames(
            active ? " text-gray-900 w-64" : "text-gray-700 w-64",
            "flex flex-row items-center  hover:bg-gray-100  p-2 border-b"
          )}
        >
          <div className="flex h-8 w-10 rounded-full">
            <img
              src={user.image}
              alt="User"
              className="h-8 w-8 rounded-full"
            />
          </div>
          <div className="text-sm">
          {user.text.length > 18 ? (
          <div>
            <div className="font-bold">{user.email}</div>
            <div className="message" style={{ textAlign: "left" }}>{user.text.slice(0, 18) + "..."}</div>
          </div>
        ) : (
          <div>
            <div className="font-bold">{user.email}</div>
            <div className="message" style={{ textAlign: "left" }}>{user.text}</div>
          </div>
        )}

          </div>
          {userUnreadCounts[user.id] > 0 && (
            <div className="flex items-center justify-center ml-auto text-xs text-white bg-red-500 h-4 w-4 rounded leading-none">
            </div>
          )}
        </button>
      </>
    )}
  </Menu.Item>
))}


        </Menu.Items>
                  </Transition>
                </Menu>


                {/* FOR NOTIFICATIONS

            <Menu as="div" className="relative">
                  <div>
                    <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-1 focus:ring-orange-400 focus:ring-offset-2 focus:ring-offset-orange-400">
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6 text-orange-500" aria-hidden="true" />
                    {Object.values(userUnreadCounts).some((count) => count > 0) && (
                  <span className="flex items-center justify-center mr-2 h-2 w-2 rounded-full bg-red-500 text-white text-xs">
                  </span>
                )}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
          <Menu.Items
          static
          className="absolute right-0 z-10 mt-4 w-64 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
            <Menu.Item>
          <div className="px-4 py-2 font-bold text-gray-700">Inbox</div>
        </Menu.Item>
        {usersWithMessages.map((user) => (
  <Menu.Item key={user.id}>
    {({ active }) => (
      <>
      <button
      onClick={()=>message()}
        className={classNames(
          active ? ' text-gray-900 w-64' : 'text-gray-700 w-64',
          'flex flex-row items-center  hover:bg-gray-100  p-2 border-b',
        )}
      >
        <div className="flex h-8 w-12 rounded-full">
          <img src={userImages[user.id]} alt="User" className="h-8 w-8 rounded-full" />
        </div>
        <div className="mr-2 text-sm font-semibold">{userEmail[user.id]}</div>
        {userUnreadCounts[user.id] > 0 && (
          <div className="flex items-center justify-center ml-auto text-xs text-white bg-red-500 h-4 w-4 rounded leading-none">
            {userUnreadCounts[user.id]}
          </div>
        )}
      </button>
      </>
    )}
    
  </Menu.Item>
))}

        </Menu.Items>
                  </Transition>
                </Menu> */}

                &nbsp;

            {/* MENU FOR ACCOUNT */}
              <Menu as="div" className="relative">
                  <div>
                    <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={imageSrc}
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-0 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/profile"
                            className={classNames(
                              active ? 'bg-white' : '',
                              'block px-4 py-2 text-sm text-gray-700',
                              'hover:bg-gray-400 hover:border-orange-500 hover:text-white'
                            )}>
                            Profile
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/Past Projects"
                            className={classNames(
                              active ? 'bg-white' : '',
                              'block px-4 py-2 text-sm text-gray-700',
                              'hover:bg-gray-400 hover:border-orange-500 hover:text-white'
                            )}>
                            Past Projects
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/Messages"
                            className={classNames(
                              active ? 'bg-white' : '',
                              'block px-4 py-2 text-sm text-gray-700',
                              'hover:bg-gray-400 hover:border-orange-500 hover:text-white'
                            )}>
                            Messages
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-white' : '',
                              'block px-4 py-2 text-sm text-gray-700',
                              'hover:bg-gray-400 hover:border-orange-500 hover:text-white'
                            )}                            
                            onClick={()=>{handleLogout()}}
                          >
                            Sign out
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
                
                <input type="checkbox" id="my-modal-4" className="modal-toggle" />
<label htmlFor="my-modal-4" className="modal cursor-pointer">
  <label className="modal-box bg-gray-100 relative" htmlFor="">
  <form onSubmit={(event)=>send_quote(event)} className="w-full max-w-lg bg-gray-100">
  <div class="flex flex-wrap -mx-3 mb-6 bg-gray-100">
  <h1 class="sm:text-3xl text-2xl font-bold text-center title-font text-orange-400 mb-4">{t("Send us a Quotation")}</h1>

    <div class="w-full md:w-2/2 px-3 mb-6 md:mb-0">

      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
      {t("Full Name")}
      </label>
      <input onChange={(e) => setQuoteName(e.target.value)} class="appearance-none block w-full bg-white-700 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white " id="grid-first-name" type="text" placeholder="Jane"/>
    </div>
    <div class="w-full md:w-2/2 px-3">
      <label class="block uppercase tracking-wide text-white-700 text-xs font-bold mb-2" for="grid-last-email">
      {t("Email")}
      </label>
      <input onChange={(e) => setQuoteEmail(e.target.value)} class="appearance-none block w-full bg-white-700 text-gray-700 border border-red-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Doe"/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full px-3 ">

      <div>
                    <label htmlFor="about" className="block text-sm font-medium text-gray-700 border-red-500">
                    {t("Requirements")}
                    </label>
                    <div className="mt-1">
                      <textarea
                                        onChange={(e) => setQuoteRequirements(e.target.value)}
                        id="about"
                        name="about"
                        rows={3}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder={t("Enlighten us")}
                        defaultValue={''}
                      />
                    </div>
                    </div>
                    
                          <p className="text-gray-600 text-xs italic">{t("Explain to us as much as you can so we may help you in the best way.")}</p>
    </div>
  </div>

  <br></br>
  <div className='bg-gray-100 text-center'>
  <input type="file"         onChange={(event)=>handleFileInputChange(event)}  className=" bg-gray-100 file-input file-input-bordered file-input-error w-full max-w-xs" />
  </div>
  <button onClick={(event)=>{send_quote(event)}} class="flex mx-auto mt-16 text-white bg-orange-400 border-0 py-2 px-8 focus:outline-none hover:bg-orange-600 rounded text-lg">{t("Send Button")}</button>

</form>
  </label>
</label>
   </div> 
</div>
    )
}

export default Navbar;