
import React from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import {
  Square3Stack3DIcon,
  UserCircleIcon,
  Cog6ToothIcon,
  ChatBubbleBottomCenterIcon
} from "@heroicons/react/24/solid";
import './dashboard.css'; 
import UserProfile from "./profile";
import Past_Projects from "./past_projects";

function Dashboard() {
  const data = [

    {
      label: "Profile",
      value: "profile",
      icon: UserCircleIcon,
      desc: <UserProfile/>,
    },
    {
        label: "Past Projects",
        value: "Past Projects",
        icon: Square3Stack3DIcon,
        desc: <Past_Projects/>,
      },
    {
      label: "Messages",
      value: "Messages",
      icon: ChatBubbleBottomCenterIcon,
      desc: `We're not always in the position that we want to be at.
      We're constantly growing. We're constantly making mistakes. We're
      constantly trying to express ourselves and actualize our dreams.`,
    },
  ];
  return (
    <Tabs value="dashboard">
      <TabsHeader indicatorProps={{
          className: "bg-orange-500/10 shadow-none text-blue-500",
        }}>
        {data.map(({ label, value, icon }) => (
          <Tab key={value} value={value} >
            <div className="flex items-center gap-2 oranger">
              {React.createElement(icon, { className: "w-5 h-5 " })}
              {label}
            </div>
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        {data.map(({ value, desc }) => (
          <TabPanel key={value} value={value} >
            {desc}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}

export default Dashboard;