import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en.json';
import translationFR from './locales/fr.json';
import translationDE from './locales/de.json';
import translationIT from './locales/it.json';
import translationES from './locales/es.json';
import translationUS from './locales/us_currency.json';
import translationUK from './locales/uk_currency.json';

const resources = {
  en: {
    translation: translationEN,
    currency: {
      currencyDollar: translationUS,
      currencyPound: translationUK,
    },
    
  },
  fr: {
    translation: translationFR,
    currency: {
      currencyDollar: translationUS,
      currencyPound: translationUK,
    },
  },
  de: {
    translation: translationDE,
    currency: {
      currencyDollar: translationUS,
      currencyPound: translationUK,
    },
  },
  it: {
    translation: translationIT,
    currency: {
      currencyDollar: translationUS,
      currencyPound: translationUK,
    },
  },
  es: {
    translation: translationES,
    currency: {
      currencyDollar: translationUS,
      currencyPound: translationUK,
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug:true,
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
};
export const changeCurrency = (currency) => {
  const language = i18n.language;
  i18n.changeLanguage(`${language}-${currency}`);
};

export default i18n;
