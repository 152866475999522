import React, { Component } from "react";
import Slider from "react-slick";
import aleandaury from './images/company_logo/aleandaury.png';
import blitzbottle from './images/company_logo/blitzbottle.png';
import DesignA from './images/company_logo/Design-A.png';
import Eldotrado from './images/company_logo/Eldotrado.png';
import elgetec from './images/company_logo/elgetec.png';
import emytan from './images/company_logo/emytan.png';
import motiongrey from './images/company_logo/motiongrey.png';
import logo02 from './images/company_logo/logo-02.png';
import joavani from './images/company_logo/joavani.png';
import ignimi from './images/company_logo/ignimi.png';
import gruttz from './images/company_logo/gruttz.png';
import essager from './images/company_logo/essager.png';
import sheepwell from './images/company_logo/sheepwell.png';
import './logoclient.css';
export default class Resizable extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "linear"
    };
    return (
        <>
      <div>
        <Slider className="items-center justify-center background-client-logo" {...settings}>
          <div >
            <img className="logoclient1" src={aleandaury}/>
          </div>
          <div>
          <img className="logoclient2" src={blitzbottle}/>
          </div>
          <div>
          <img className="logoclient3" src={motiongrey}/>
          </div>
          <div>
          <img className="logoclient4" src={joavani}/>
          </div>
          <div>
          <img className="logoclient5" src={ignimi}/>
          </div>
          <div>
          <img className="logoclient6" src={gruttz}/>
          </div>
          <div>
          <img className="logoclient7" src={essager}/>
          </div>
          <div>
          <img className="logoclient8" src={Eldotrado}/>
          </div>
          <div>
          <img className="logoclient9" src={emytan}/>
          </div>
          <div>
          <img className="logoclient10" src={elgetec}/>
          </div>
 
          <div>
          <img className="logoclient11" src={logo02}/>
          </div>

          <div>
          <img className="logoclient12" src={sheepwell}/>
          </div>
          <div>
          <img className="logoclient13" src={DesignA}/>
          </div>
        </Slider>
      </div>
      </>
    );
  }
}