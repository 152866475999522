import React, { useState, useEffect } from 'react';
import { db } from '../firestore.js';
import { useCollectionData } from 'react-firebase-hooks/firestore';

const UserForm = () => {
  const [text, setText] = useState('');
  const [messages, loading] = useCollectionData(''
    // db.collection('messages').orderBy('createdAt')
  );

  const sendMessage = async () => {
    if (text.trim() === '') return;

    await db.collection('messages').add({
      text,
    //   createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

    setText('');
  };

//   useEffect(() => {
//     const chatWindow = document.getElementById('chat-window');
//     chatWindow.scrollTop = chatWindow.scrollHeight;
//   }, [messages]);

  return (
    <div>
      <div id="chat-window">
        {loading ? (
          <p>Loading messages...</p>
        ) : (
          messages.map((message) => (
            <p key={message.id}>{message.text}</p>
          ))
        )}
      </div>
      <input
        type="text"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <button onClick={sendMessage}>Send</button>
    </div>
  );
};

export default UserForm;
