import { useEffect, useState } from 'react';
import { Route, Routes} from 'react-router-dom';
import './App.css';
//LOGGED OUT ROUTES
import Home from './browser/home';
import About from './browser/about.js';
import Navbar from './navbar/navbar_logged_in.js';
import Navbar_Admin from './navbar/navbar_logged_in_admin.js';
import ScrollToTop from "react-scroll-to-top";
import SignIn from './browser/signin';
import Registration from './browser/signup';
import Success from './browser/sucess.js';
import {auth} from './firestore';
//LOGGED IN ROUTES
import Navbar_Logged_out from './navbar/navbar_logged_out';
import Portfolio from './browser/portfolio';
import Footer from './browser/footer';
import Product_Photography from './browser/product_photography';
import Slideshow from './browser/test.js';
import Packaging_Design from './browser/packaging_desing';
import Enhaned_Brand_Content from './browser/enhanced_brand_content';
import Brand_Logo_Design from './browser/brand_logo_design';
import Dashboard from './loggedin_browser/dashboard';
import UserForm from './loggedin_browser/test_form';
import ProfilePage from './loggedin_browser/profile';
import Past_Projects from './loggedin_browser/past_projects';
import Messages from './loggedin_browser/messages';
import Messages_Admin from './loggedin_browser/messages_admin';
import Filter from './browser/filter';
import Converter from './browser/converter';
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import logo from './browser/images/logo.jpg';
import Reviews from './browser/reviews';
const HeaderIconWithBorderRadius = () => (
  <div style={{ borderRadius: "10px", overflow: "hidden" }}>
    {/* Replace "logo" with your actual logo */}
    <img src={logo} alt="Logo" />
  </div>
);
function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [email,setEmail]=useState("");
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setLoggedIn(true);
        setEmail(user.email);
        // alert("Logged IN");
      } else {
        setLoggedIn(false);
        // alert("Logged OUT");
      }
    });

    return unsubscribe;
  }, []);



  if (loggedIn) {
    if(email==='behrozgraphics@gmail.com')
    {
      return (
        <>
        
          <div className={`App`}>
            <div style={{ marginTop: "0.2vh" }} />
            <ScrollToTop smooth width='40px' color='orange' className='' />
                      
            <Navbar_Admin />
  
            <br></br>
            <br></br> 
            
            <br></br>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/about' element={<About/>} />
              <Route path='/success' element={<Success/>} />
              <Route path='/portfolio' element={<Portfolio/>} />
              <Route path='/productphotography' element={<Product_Photography/>} />
              <Route path='/packagingdesign' element={<Packaging_Design/>} />
              <Route path='/ebc' element={<Enhaned_Brand_Content/>} />
              <Route path='/brandlogodesign' element={<Brand_Logo_Design/>} />
              <Route path='/dashboard' element={<Dashboard/>} />
              <Route path='*' element={<Home/>} />
              <Route path='/test2' element={<UserForm/>} />
              <Route path='/profile' element={<ProfilePage/>} />
              <Route path='/Past Projects' element={<Past_Projects/>} />
              <Route path='/Messages' element={<Messages_Admin/>} />
              <Route path='/Reviews' element={<Reviews/>} />
              <Route path='*' element={<Home to='/' />} /> {/* fallback route */}
  
            </Routes>
            <Footer/>
          </div>
        </>
      );
    }
    else{

    console.log("Logged IN");
    return (
      <>
      
        <div className={`App`}>
          <div style={{ marginTop: "0.2vh" }} />
          <ScrollToTop smooth width='40px' color='orange' className='' />
                    
          <Navbar />

          <br></br>
          <br></br> 
          
          <br></br>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About/>} />
            <Route path='/success' element={<Success/>} />
            <Route path='/portfolio' element={<Portfolio/>} />
            <Route path='/productphotography' element={<Product_Photography/>} />
            <Route path='/packagingdesign' element={<Packaging_Design/>} />
            <Route path='/ebc' element={<Enhaned_Brand_Content/>} />
            <Route path='/brandlogodesign' element={<Brand_Logo_Design/>} />
            <Route path='/dashboard' element={<Dashboard/>} />
            <Route path='*' element={<Home/>} />
            <Route path='/test2' element={<UserForm/>} />
            <Route path='/profile' element={<ProfilePage/>} />
            <Route path='/Past Projects' element={<Past_Projects/>} />
            <Route path='/Messages' element={<Messages/>} />
            <Route path='/Reviews' element={<Reviews/>} />
            <Route path='*' element={<Home to='/' />} /> {/* fallback route */}


          </Routes>
          <Footer/>
        </div>
        {/* <WhatsAppWidget
			phoneNo="923370780562"
			position="left"
			iconSize="60"
			iconColor="white"
			iconBgColor="tomato"
			headerIcon={logo}
			headerIconColor="orange"
			headerTxtColor="white"
			headerBgColor="tomato"
			headerTitle="John Doe"
			headerCaption="Online"
			bodyBgColor="gray"
			chatPersonName="Growphics"
			chatMessage={<>Hi there 👋 <br /><br /> How can I help you?</>}
			footerBgColor="grey"
			btnBgColor="tomato"
			btnTxtColor="white"
		/> */}
      </>
    );
    }
  } else {
    console.log("Logged OUT");
    return (
      <>
        <div className={`App`}>
          <div style={{ marginTop: "0.2vh" }} />
          <ScrollToTop smooth width='40px' color='orange' className='pp' />
          
          <Navbar_Logged_out />

          <br></br>
          <br></br> 
          <br></br>
          <Routes>
          <Route path='/test2' element={<UserForm/>} />
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About/>} />
            <Route path='/signin' element={<SignIn/>} />
            <Route path='/registration' element={<Registration/>} />
            <Route path='/success' element={<Success/>} />
            <Route path='/portfolio' element={<Portfolio/>} />
            <Route path='/productphotography' element={<Product_Photography/>} />
            <Route path='/packagingdesign' element={<Packaging_Design/>} />
            <Route path='/ebc' element={<Enhaned_Brand_Content/>} />
            <Route path='/brandlogodesign' element={<Brand_Logo_Design/>} />
            <Route path='/filter' element={<Filter/>} />
            <Route path='/videoconverter' npmelement={<Converter/>} />
            <Route path='/test' element={<Slideshow/>} />
            <Route path='*' element={<Home/>} />
            <Route path='/Reviews' element={<Reviews/>} />
            <Route path='*' element={<Home to='/' />} /> {/* fallback route */}

          </Routes>
          <Footer/>

        </div>
        {/* <WhatsAppWidget
			phoneNo="923370780562"
			position="left"
			iconSize="60"
			iconColor="white"
			iconBgColor="tomato"
			headerIcon={logo}
			headerIconColor="orange"
			headerTxtColor="white"
			headerBgColor="tomato"
			headerTitle="John Doe"
			headerCaption="Online"
			bodyBgColor="gray"
			chatPersonName="Growphics"
			chatMessage={<>Hi there 👋 <br /><br /> How can I help you?</>}
			footerBgColor="grey"
			btnBgColor="tomato"
			btnTxtColor="white"
		/> */}
      </>
    );
  }
}

export default App;
