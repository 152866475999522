import logo from './images/logo.jpg';
import './footer.css';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from '../i18n';

function Footer  () {
  const { t } = useTranslation();

    return (
      <>
      <footer className="footer p-10 bg-warning text-white">
      <div className="md:max-w-md lg:col-span-2">
              <a
                href="/"
                aria-label="Go home"
                title="Company"
                className="inline-flex items-center"
              >
<img src={logo} className="logoer2"></img>
              </a>
              <div className="mt-4 lg:max-w-sm">
                <ul>
                  <li className='footer-logo'>{t("WE COMMIT!")}</li>
                  <br></br>
                  <li className='footer-logo'>{t("WE DESIGNE!")}</li>
                  <br></br>

                  <li className='footer-logo'>{t("WE DELIVER!")}</li>
                </ul>
              </div>
            </div> 
  <div>
    <span className="footer-title">{t("Quick Link")}</span> 
    <a className="link link-hover">{t("Partners")}</a> 
    <a className="link link-hover">{t("Products")}</a> 
    <a className="link link-hover">{t("About")}</a> 
    <a className="link link-hover">{t("Blogs")}</a>
    <a className="link link-hover">{t("Contact")}</a>
    <a className="link link-hover">{t("Career")}</a>
    <a className="link link-hover">{t("FAQs")}</a>
  </div> 
  <div>
    <span className="footer-title">{t("Services")}</span> 
    <a className="link link-hover">{t("Packaging Design")}</a> 
    <a className="link link-hover">{t("Listing Images")}</a> 
    <a className="link link-hover">{t("Products Photography")}</a> 
    <a className="link link-hover">{t("Enhanced Brand Content")}</a>
    <a className="link link-hover">{t("Logo Design")}</a>
  </div> 
  <div>
                <p className="font-semibold tracking-wide text-teal-accent-400 font-bold footer-headings">
                {t("STAY IN TOUCH")}
                </p>
                <br></br>
               <p className='font-bold'>{t("Our Social Links for Insiprational Content")}</p>
               <br></br>
               <div className="flex items-center mt-4 space-x-4 sm:mt-0">
              <a
                href="/"
                className="transition-colors duration-300 text-deep-purple-100 hover:text-teal-accent-400"
              >
                <svg viewBox="0 0 30 30" fill="currentColor" className="h-6 social-hover">
                  <circle cx="15" cy="15" r="4" />
                  <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                </svg>
              </a>
              <a
                href="/"
                className="transition-colors duration-300 text-deep-purple-100 hover:text-teal-accent-400"
              >
                <svg viewBox="0 0 24 24" fill="currentColor" className="h-5 social-hover">
                  <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
                </svg>
              </a>
              <a
                href="/"
                className="transition-colors duration-300 text-deep-purple-100 hover:text-teal-accent-400"
              >
                <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="22" fill="currentColor" className="bi bi-linkedin social-hover" viewBox="0 0 16 16"> <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" fill="white"></path> </svg>
              </a>
            </div>
              </div>
</footer>

      </>
    );
  };

  export default Footer;