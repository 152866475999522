import React, { useState, useEffect } from 'react';
import {
  ref,
  onValue,
  push,
  serverTimestamp,
  get,child,update,set
  
} from 'firebase/database';
import { dbreal } from '../firestore.js';
import { Fragment } from 'react';
import { CheckIcon,CalendarIcon, } from '@heroicons/react/20/solid';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import {  uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firestore.js";
import { ref as storageRef2 } from 'firebase/storage';
import whatsapp_logo from '../browser/images/whatsapp.png';
import { ScrollIndicatorPage } from '../browser/scroll';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import logo from '../browser/images/logo.jpg';
function Messages() {
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState('');
  const [file, setFile] = useState("");
  
//////////////////////////////// CODE FOR IMAGE UPLOAD //////////////////////////////// 
const handleChange = async (event) => {
  try {
    setFile(event.target.files[0]);

    const storageRef = storageRef2(storage, `/users/${uid}/${event.target.files[0]}`);

    const snapshot = await uploadBytes(storageRef, event.target.files[0]);
    console.log('File uploaded successfully');

    // Get the download URL of the uploaded file
    const downloadURL = await getDownloadURL(snapshot.ref);
    console.log('Download URL:', downloadURL);

    const messagesRef = ref(dbreal, `users/${uid}/messages/images`);
    await push(messagesRef, {
      image: downloadURL,
      picture_id: "images",
      createdAt: serverTimestamp(),
      readbyadmin: false,
      readbyuser: true,
      user: "sender"
    });

  } catch (error) {
    console.error('Error:', error);
  }
};

  const data = localStorage.getItem('UserData');
  const data2 = JSON.parse(data);
  const email = data2.email;
  const userPictureLink = data2.image;
  const name = data2.name;
  const uid = data2.uid;
  useEffect(()  => {
    const messagesRef = ref(dbreal, `users/${uid}/messages`);
  
    const unsubscribe = onValue(messagesRef, async (snapshot) => {
      const messagesData = [];
      const promises = [];

      const data = snapshot.val();
      if (data) {

        Object.keys(data).forEach((key) => {
          console.log(key.email);
          if(key==="images"){
            Object.keys(data[key]).forEach((key2) => {
              if(key2!=="readbyadmin"){
                messagesData.push({ id: key2, ...data[key][key2] });
              }
          })
          }
          else{
          messagesData.push({ id: key, ...data[key] });
          }
        const documentRef = child(messagesRef, key);
        const promise = update(documentRef, { readbyuser: true })
          .catch((error) => {
            console.error("Error updating 'readbyadmin' field:", error);
          });

        promises.push(promise);
        
        });
        
        await Promise.all(promises);

      }
      // Sort the messages by createdAt timestamp
      messagesData.sort((a, b) => a.createdAt - b.createdAt);
      console.log(messagesData);
      setMessages(messagesData);
    
    });
  
    return () => unsubscribe();
  }, [uid]);
 



  const sendMessage = async () => {
    if (text.trim() === '') return;
    setText('');
    const message = {
      text,
      createdAt: serverTimestamp(),
      user: 'sender',
      image:userPictureLink,
      readbyadmin: false,
      readbyuser: true,
      email:email,
      uid:uid
    };

    try {
      const messagesRef = ref(dbreal, `users/${uid}/messages`);

      await push(messagesRef, message);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

////////////////////////////////IMAGE FUNCTION ////////////////////////////////

const [open5, setOpen5] = useState(false);
const [imageLink, setImageLink] = useState('');
const image_fun =async(image)=>
{
  setOpen5(true);
  setImageLink(image);
}

////////////////////////////////OFFER LETTER FUNCTION ////////////////////////////////
const [requirements_data2, setrequirements_data2] = useState([]);
const [open4, setOpen4] = useState(false);

const accept = async (event) => {
  // event.preventDefault();

  try {
    const messagesRef = ref(dbreal, `users/${uid}/messages`);
    const messageId = "offer";
  
    const messageSnapshot = await get(child(messagesRef, messageId));
    if (messageSnapshot.exists()) {
      const offerData = messageSnapshot.val();
      offerData.accept = true; // Update the 'accept' variable inside the 'offer' document

      await update(child(messagesRef, messageId), offerData);
      console.log('Acceptance status updated successfully!');
      setOpen4(false);
    } else {
      console.log('Offer document does not exist.');
      setOpen4(false);
    }
  } catch (error) {
    console.error('Error updating acceptance status:', error);
  }
}

const offer_letter=()=>
{

  for (let i = 0; i < messages.length; i++) {

    if (messages[i].id === 'offer') {


      const newData = {
        image:messages[i].image,
        service:messages[i].service,
        requirements_text:messages[i].requirements_text,
        revisions: messages[i].revisions,
        delivery:messages[i].delivery,
        price:messages[i].price,
        number_of_image:messages[i].number_of_image,
        checked_box:messages[i].checked_box,
        readbyuser:messages[i].readbyuser,
        accept:messages[i].accept,
        selectedCoupen:messages[i].selectedCoupen,
        requirements_checked:messages[i].requirements_checked
      };
      
      setrequirements_data2(newData);
      setOpen4(true);

      
      
      
      break;
    }
  }
}
return(
    <>
       <ScrollIndicatorPage/>

  {/* component */}
  <div className="flex h-screen antialiased text-gray-800">
    <div className="flex flex-row h-full w-full overflow-x-hidden">
      <div className="flex flex-col py-2 pl-6 pr-2 w-64 bg-white flex-shrink-0">
        <div className="flex flex-row items-center justify-center h-12 w-full">
          <div className="flex items-center justify-center rounded-2xl text-indigo-700 bg-indigo-100 h-10 w-10">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="orange"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
              />
            </svg>
          </div>
          <div className="ml-2 font-bold text-2xl">Messages</div>
        </div>
        <div className="flex flex-col items-center bg-orange-100 border border-gray-200 mt-4 w-full py-6 px-4 rounded-lg">
          <div className="h-20 w-20 rounded-full border overflow-hidden">
            <img
              src={userPictureLink}
              alt="Avatar"
              className="h-full w-full"
            />
          </div>
          <div className="text-sm font-semibold mt-2">{name}</div>
          <div className="text-xs text-white-500">{email}</div>
        </div>
      </div>
      <div className="flex flex-col py-6 flex-auto height-box p-8">
        <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full p-2">
          <div className="flex flex-col h-full overflow-x-auto mb-4">
            <div className="flex flex-col h-full">
              <div className="grid grid-cols-12 gap-y-2">
                {messages.map((message) => {
                  // console.log(message);
                  if (message.id === 'offer') {
                    return (
                      <div key={message.id} className="col-start-1 col-end-8 p-3 rounded-lg">
                  <div className="flex flex-row items-center">
                  <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                      <img className='className="flex items-center justify-center h-10 w-10 rounded-full bg-white flex-shrink-0' src={logo}/>

                      </div>
                    <div className="relative ml-3 text-sm bg-white  py-2 px-4 shadow rounded-xl">
                    <div>
                      {message.accept ? (
                        <>
                       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <button   className="btn bg-gray-200 text-black hover:bg-orange-200"  onClick={()=>offer_letter()} style={{ display: 'flex', alignItems: 'center' }}>
                        <CheckIcon className="h-5 w-5 flex-none text-orange-400" aria-hidden="true" />&nbsp;
                        <b>Offer Accepted</b>
                      </button>
                      <br/>
                      <span>Click to open</span>
                    </div>


                        </>
                      ) : (
                        <>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <button onClick={()=>offer_letter()} className="btn bg-gray-200 text-black hover:bg-orange-200"  style={{ display: 'flex', alignItems: 'center' }}>
                        <b>Offer Received</b>
                      </button>
                      <br/>
                      <span>Click to open</span>
                    </div>
                        </>
                      )}
                      </div>
                    </div>
                  </div>
                </div>
                    );
                  } 
                  else if (message.picture_id === 'images') {
                    if (message.user === 'user') {
  
                    return (
                      <div key={message.id} className="col-start-1 col-end-8 p-3 rounded-lg">
                    <div className="flex flex-row items-center">
                    <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                        <img className='className="flex items-center justify-center h-10 w-10 rounded-full bg-white flex-shrink-0' src={logo}/>
                        </div>
                        <div className="relative ml-3 text-sm py-2 px-2 shadow rounded-xl hover:scale-90 transition-transform duration-300">
                        <img className='flex items-center justify-center h-28 w-36 bg-white flex-shrink-0' src={message.image} onClick={()=>image_fun(message.image)}/>
                          </div>
                      
                    </div>
                  </div>
                    );
                    }
                    else if (message.user === 'sender') {
  
                    return (
                      <div key={message.id} className="col-start-6 col-end-13 p-3 rounded-lg">
                      <div className="flex items-center justify-start flex-row-reverse">
                        <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                        <img className='className="flex items-center justify-center h-10 w-10 rounded-full bg-white flex-shrink-0' src={userPictureLink}/>
  
                        </div>
                        <div className="relative mr-3 text-sm py-2 px-2 shadow rounded-xl hover:scale-90 transition-transform duration-300">
                        <img className='flex items-center justify-center h-28 w-36 bg-white flex-shrink-0' src={message.image} onClick={()=>image_fun(message.image)}/>
                        
                          </div>
                      </div>
                    </div>
                    );
                    }
                  } 
                  else if (message.user === 'sender') {
                    return (
                      <div key={message.id} className="col-start-6 col-end-13 p-3 rounded-lg">
                    <div className="flex items-center justify-start flex-row-reverse">
                      <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                      <img className='className="flex items-center justify-center h-10 w-10 rounded-full bg-white flex-shrink-0' src={userPictureLink}/>

                      </div>
                      <div className="relative mr-3 text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl">
                        <div>{message.text}</div>
                      </div>
                    </div>
                  </div>
                    );
                  }
                 else if (message.user === 'receiver') {
                  return (
                    <div key={message.id} className="col-start-1 col-end-8 p-3 rounded-lg">
                  <div className="flex flex-row items-center">
                  <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                      <img className='className="flex items-center justify-center h-10 w-10 rounded-full bg-white flex-shrink-2' src={logo}/>

                      </div>
                    <div className="relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl">
                    <div>{message.text}</div>
                    </div>
                  </div>
                </div>
                  );
                }
              })}


              </div>
            </div>
          </div>
          <div className="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4">
            <div>
            <div className="relative">
            <input
              type="file"
              onChange={handleChange}
              accept="image/*"
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            />
            <button
              className="flex items-center justify-center text-gray-400 hover:text-gray-600"
              style={{ maxWidth: "90%" }}
            >
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="orange"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                />
              </svg>
            </button>
          </div>
            </div>
            &nbsp;
            <img onClick={()=>{
                  var phoneNumber = '923370780562'; // Replace with the desired phone number
                  var encodedMessage = encodeURIComponent('Hello there!'); // Replace with the desired message
              
                  var url = 'https://api.whatsapp.com/send?phone=' + phoneNumber + '&text=' + encodedMessage;
                  window.open(url, '_blank');
            }} src={whatsapp_logo} style={{width:"1.5%"}}/> 
            <div className="flex-grow ml-4">
              <div className="relative w-full">
                <input
                  type="text"
                  className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      // Call the function to send the message
                      sendMessage();
                    }
                  }}
                />
                <button className="absolute flex items-center justify-center h-full w-12 right-0 top-0 text-gray-400 hover:text-gray-600">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="orange"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="ml-4">
              <button onClick={sendMessage} className="flex items-center justify-center bg-orange-400 hover:bg-orange-600 rounded-xl text-white px-4 py-1 flex-shrink-0">
                <span>Send</span>
                <span className="ml-2">
                  <svg
                    className="w-4 h-4 transform rotate-45 -mt-px"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <Transition.Root show={open4} as={Fragment}>
             <Dialog as="div" className="relative z-10" onClose={setOpen4}>
               <Transition.Child
                 as={Fragment}
                 enter="ease-out duration-300"
                 enterFrom="opacity-0"
                 enterTo="opacity-100"
                 leave="ease-in duration-200"
                 leaveFrom="opacity-100"
                 leaveTo="opacity-0"
               >
                 <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
               </Transition.Child>
       
               <div className="fixed inset-0 z-10 overflow-y-auto">
                 <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                   <Transition.Child
                     as={Fragment}
                     enter="ease-out duration-300"
                     enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                     enterTo="opacity-100 translate-y-0 md:scale-100"
                     leave="ease-in duration-200"
                     leaveFrom="opacity-100 translate-y-0 md:scale-100"
                     leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                   >
                     <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-2xl">
                       <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                         <button
                           type="button"
                           className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                           onClick={() => {
                              setrequirements_data2([]);
                              setOpen4(false);
                           }}
                         >
                           <span className="sr-only">Close</span>
                           <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                         </button>
                        {/* THE Start OF CREATE OFFER BOX */}
                         <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-1 lg:gap-x-8">
                           
                         <>

                         <div className="flex flex-col mt-0 overflow-auto">
                          <div className="flex flex-row items-center justify-between">
                            
                            <h1 className="font-bold text-gray-900">Offer</h1>
                          </div>
                          
                          <div className='overflow-y-auto max-h-96'>
                          <div className="flex flex-col space-y-1 mt-4 ">
                          <div className="flex">
                          <span className='text-black text-sm'><b>Here is the offer for you:</b></span>
                            </div>
                            <hr/>
                            <div className="flex">
                          <span className='text-black text-sm'><b>Name: {requirements_data2.service}</b></span>
                            </div>
                            <hr/>
                            <div className="flex">
                          <span className='text-black text-sm'><b>Price: {requirements_data2.price} $ </b></span>
                            </div>
                            <hr/>
                            <div className="flex">
                              <span className='text-black text-sm'>{requirements_data2.requirements_text}</span>
                          </div>
                          </div>
                          <br/>
                          <hr/>
                          {requirements_data2.selectedCoupen !== '0' && (
                            <div className="flex flex-col space-y-1 mt-4">
                              <div className="flex">
                              <span className='text-black font-bold text-sm'>Note: </span>&nbsp;
                                <span className='text-black text-sm'>Congratulations You have a coupen of: <b>{requirements_data2.selectedCoupen} %</b> </span>
                              </div>
                              <br/>
                              <hr/>
                            </div>
                          )}
                          {requirements_data2.requirements_checked !== false && (
                            <div className="flex flex-col space-y-1 mt-4">
                              <div className="flex">
                                <span className='text-black font-bold text-sm'>Note: </span>&nbsp;
                                <span className='text-black  text-sm'>You are requested to provide your requirements. </span>
                              </div>
                              <br/>
                              <hr/>
                            </div>
                            
                          )}

                          <div className="flex flex-col space-y-1 mt-4 ">
                          <div className="flex">
                          <span className='text-black font-bold text-sm'>Your offer includes</span>

                            </div>
                            <div className="bg-white">
                              <div className="mt-2 grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-4">
                              <div className="mt-4 flex justify-between">
                                          <div>
                                          <div className="flex items-center">
                                          <CheckIcon className="h-5 w-5 flex-none text-orange-400" aria-hidden="true" />&nbsp;
                                            <h3 className="text-sm text-gray-700">Number of Revisions:<b> {requirements_data2.revisions}</b></h3>
                                        </div>
                              </div>
                              </div>
                              <div className="mt-4 flex justify-between">
                                          <div>
                                          <div className="flex items-center">
                                          <CalendarIcon className="h-5 w-5 flex-none text-orange-400" aria-hidden="true" />&nbsp;
                                            <h3 className="text-sm text-gray-700">Delivery: <b>{requirements_data2.delivery} Days</b> </h3>
                                        </div>
                              </div>
                              </div>
                              <div className="mt-4 flex justify-between">
                                          <div>
                                          <div className="flex items-center">
                                          <CheckIcon className="h-5 w-5 flex-none text-orange-400" aria-hidden="true" />&nbsp;
                                            <h3 className="text-sm text-gray-700">Number of images: <b>{requirements_data2.number_of_image}</b>  </h3>
                                        </div>
                              </div>
                              </div>
                              </div>
                            </div>
                                <div className="bg-white">
                                  <div className="mt-2 grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-4">
                                    {requirements_data2.checked_box?.map((product) => (
                                      <div key={product.id} className="group relative">
                                        <div className="mt-4 flex justify-between">
                                          <div>
                                          <div className="flex items-center">
                                            <CheckIcon className="h-6 w-5 flex-none text-orange-400" aria-hidden="true" />&nbsp;
                                            <h3 className="text-sm text-gray-700"><b>{product}</b></h3>
                                          </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              {/* </div> */}

                          </div>
                         
      

                             {requirements_data2.accept===false && (
                              <>
                               <br/>
                              <br/>
                              <hr/>
                              <br/>
                              <button onClick={(event)=>accept(event)} className="btn bg-gray-200 text-black hover:text-white hover:bg-orange-400">Accept</button>
                              <br/>
                              </>
                             )}
                             <br/>
                              <hr/>



                        {/* END */}
                        </div>    
                        </div>
                          </>

                         </div>
                    
                        {/* THE END OF CREATE OFFER BOX */}

                       </div>
                     </Dialog.Panel>
                   </Transition.Child>
                 </div>
               </div>
             </Dialog>
           </Transition.Root>
  <Transition.Root show={open5} as={Fragment}>
             <Dialog as="div" className="relative z-10" onClose={setOpen5}>
               <Transition.Child
                 as={Fragment}
                 enter="ease-out duration-300"
                 enterFrom="opacity-0"
                 enterTo="opacity-100"
                 leave="ease-in duration-200"
                 leaveFrom="opacity-100"
                 leaveTo="opacity-0"
               >
                 <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
               </Transition.Child>
       
               <div className="fixed inset-0 z-10 overflow-y-auto">
                 <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                   <Transition.Child
                     as={Fragment}
                     enter="ease-out duration-300"
                     enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                     enterTo="opacity-100 translate-y-0 md:scale-100"
                     leave="ease-in duration-200"
                     leaveFrom="opacity-100 translate-y-0 md:scale-100"
                     leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                   >
                     <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-0 lg:max-w-3xl">
                       <div className="relative flex w-full items-center overflow-hidden px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                         <button
                           type="button"
                           className="absolute right-4 top-4 text-white hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                           onClick={() => {
                              setOpen5(false);
                           }}
                         >
                           <span className="sr-only">Close</span>
                           <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                         </button>
                        {/* THE Start OF CREATE OFFER BOX */}
                         <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-1 lg:gap-x-8">
                           
                         <>

                         <div className="flex flex-col mt-0 overflow-auto">
                          <div className="flex flex-row items-center justify-between">
                            
                            <img src={imageLink}/>
                          </div>
                          
                        
                        </div>
                          </>

                         </div>
                    
                        {/* THE END OF CREATE OFFER BOX */}

                       </div>
                     </Dialog.Panel>
                   </Transition.Child>
                 </div>
               </div>
             </Dialog>
           </Transition.Root>
</>

)

}
  

  


export default Messages;