import './profile.css';
import React from 'react';
import { useState, useEffect } from 'react';
import { db } from "../firestore.js";
import {setDoc,doc } from "firebase/firestore";
import { storage } from "../firestore.js";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
 
export default function ProfilePage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [uid, setUserUid] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [userPictureLink, setUserPictureLink] = useState('');
    const data = localStorage.getItem('UserData');
    const data2 = JSON.parse(data);
    const account=data2.account;
    const emaill = data2.email;
    const userPictureLinkk = data2.image;
    const namee = data2.name;
    useEffect(() => {

    }, []);


  ///////////// THE CODE FOR UPLOADING PHOTOGRAPH FOR DP//////////

// State to store uploaded file
    const [file, setFile] = useState("");


  const [percent, setPercent] = useState(0);

  // Handle file upload event and update state
    function handleChange(event) {
      setFile(event.target.files[0]);
  }
    
    const handleUpload = async() => {

    const storageRef = ref(storage, `/users/${uid}/profile_picture`);

        // progress can be paused and resumed. It also exposes progress updates.
        // Receives the storage reference and the file to upload.
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
 
                // update progress
                setPercent(percent);
            },
            (err) => console.log(err),
            () => {
            // download url
            if(file){
            getDownloadURL(uploadTask.snapshot.ref).then(async (url)  => {
                console.log(url);
                const docRef = doc(db, "users", emaill);
                await setDoc(docRef, {
                  image: url,
                }, { merge: true });
                  setUserPictureLink(url);
                  localStorage.setItem('UserData', JSON.stringify({name:namee,email:emaill,image:url,account:account}));

              });
          }
        }
        );

                // const docRef2 = doc(db, "users", userEmail);
                // await setDoc(docRef2, {
                //   name: name,
                // }, { merge: true });
        
        // toggle back to "view" mode
        setIsEditing(false);
    };



    const handleNameChange = (event) => {
        // setName(event.target.value);
      };
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
      };

      const handlePasswordChange = (event) => {
        setPassword(event.target.value);
      };




  const handleToggleEdit = () => {
    setIsEditing(!isEditing);
  };


  if(account=="gmail"){
    if (isEditing) {
    return (
        <>
<section>
<div className="flex">
  <div className="w-1/4 p-4">
  <div className="p-4 bg-white rounded-md shadow-md">
  <div className="flex flex-col items-center">
      <img
        src={userPictureLinkk}
        alt="Avatar"
        className="w-32 h-32 rounded-full mb-4"
      />
      <input
        type="file"
        onChange={handleChange}
        accept="/image/*"
        className="border-2 text-orange-500 hover:text-black px-3 py-2 rounded-md text-sm focus:outline-none focus:border-orange-600"
        style={{ maxWidth: "90%" }}
      />
      <br/>
      {/* <button
        type="button"
        className="border-2 border-orange-500 hover:border-orange-600 text-orange-500 hover:text-black px-4 py-2 rounded-md"
        onClick={handleUpload}
      >
        Change Avatar
      </button> */}
      {/* <p>{percent} % done</p> */}
    </div>
  </div>
  </div>
  <div className="flex-1 p-4">
  <div className="p-6 bg-white rounded-md shadow-md" >
  {/* <form onSubmit={handleUpload} className="p-4 rounded-lg"> */}
  <div className="flex flex-row items-center">
  <p className="text-1xl font-bold mb-4 mr-8 w-32">Name:</p>
  <input
    id="name"
    type="text"
    value={namee}
    onChange={handleNameChange}
    className="border p-1 rounded-md flex-grow"
    placeholder={namee}
    disabled
  />
</div>
<div className="flex flex-row items-center">
  <p className="text-1xl font-bold mb-4 mr-8 w-32">Email:</p>
  <input
    id="email"
    type="text"
    value={email}
    onChange={handleEmailChange}
    className="border p-1 rounded-md flex-grow"
    placeholder={emaill}
      disabled
  />
</div>
{/* <div className="flex flex-row items-center">
  <p className="text-1xl font-bold mb-4 mr-8 w-32">Password:</p>
  <input
    id="password"
    type="password"
    value={password}
    onChange={handlePasswordChange}
    className="border p-1 rounded-md flex-grow"
  />
</div> */}

    <button type="submit" 
    className="border-2 border-orange-500 hover:border-orange-600 text-orange-500 hover:text-black px-4 py-2 rounded-md"
    onClick={handleUpload}
    >
          Save
        </button>
    {/* </form> */}

  </div>
  </div>
</div>

    </section>


      </>
    );
  }
  return (
    <section>
<div className="flex">
  <div className="w-1/4 p-4">
  <div className="p-4 bg-white rounded-md shadow-md">
    <div className="flex flex-col items-center">
      <img
        src={userPictureLinkk}
        alt="Avatar"
        className="w-32 h-32 rounded-full mb-4"
      />
    </div>
  </div>
  </div>
  <div className="flex-1 p-4">
  <div className="p-6 bg-white rounded-md shadow-md" >
  <div className="flex flex-row items-center">
  <h3 className="text-1xl font-bold mb-4 mr-8 w-32">Name:</h3>
  <p className='text-black mb-3 mr-8 w-32'>{namee}</p>
</div>
<div className="flex flex-row items-center">
  <h3 className="text-1xl font-bold mb-4 mr-8 w-32">Email:</h3>
  <p className='text-black mb-3 mr-8 w-32'>{emaill}</p>

</div>


{/* </div> */}

    <button
    onClick={handleToggleEdit}
    className="border-2 border-orange-500 hover:border-orange-600 text-orange-500 hover:text-black px-4 py-2 rounded-md"
    >
      
    Edit
  </button>
  
  </div>
  </div>
</div>
    </section>
  );
}
if (account=="email"){
  if (isEditing) {
    return (
        <>
<section>
<div className="flex">
  <div className="w-1/4 p-4">
  <div className="p-4 bg-white rounded-md shadow-md">
  <div className="flex flex-col items-center">
      <img
        src={userPictureLinkk}
        alt="Avatar"
        className="w-32 h-32 rounded-full mb-4"
      />
      <input
        type="file"
        onChange={handleChange}
        accept="/image/*"
        className="border-2 text-orange-500 hover:text-black px-3 py-2 rounded-md text-sm focus:outline-none focus:border-orange-600"
        style={{ maxWidth: "90%" }}
      />
      <br/>
      {/* <button
        type="button"
        className="border-2 border-orange-500 hover:border-orange-600 text-orange-500 hover:text-black px-4 py-2 rounded-md"
        onClick={handleUpload}
      >
        Change Avatar
      </button> */}
      {/* <p>{percent} % done</p> */}
    </div>
  </div>
  </div>
  <div className="flex-1 p-4">
  <div className="p-6 bg-white rounded-md shadow-md" >
  {/* <form onSubmit={handleUpload} className="p-4 rounded-lg"> */}
  <div className="flex flex-row items-center">
  <p className="text-1xl font-bold mb-4 mr-8 w-32">Name:</p>
  <input
    id="name"
    type="text"
    value={namee}
    onChange={handleNameChange}
    className="border p-1 rounded-md flex-grow"
    placeholder={namee}
    disabled
  />
</div>
<div className="flex flex-row items-center">
  <p className="text-1xl font-bold mb-4 mr-8 w-32">Email:</p>
  <input
    id="email"
    type="text"
    value={emaill}
    onChange={handleEmailChange}
    className="border p-1 rounded-md flex-grow"
    placeholder={emaill}
    disabled
  />
</div>
<div className="flex flex-row items-center">
  <p className="text-1xl font-bold mb-4 mr-8 w-32">Password:</p>
  <input
    id="password"
    type="password"
    value={password}
    onChange={handlePasswordChange}
    className="border p-1 rounded-md flex-grow"
  />
</div>

    <button type="submit" 
    className="border-2 border-orange-500 hover:border-orange-600 text-orange-500 hover:text-black px-4 py-2 rounded-md"
    onClick={handleUpload}
    >
          Save
        </button>
    {/* </form> */}

  </div>
  </div>
</div>

    </section>


      </>
    );
  }
  return (
    <section>
<div className="flex">
  <div className="w-1/4 p-4">
  <div className="p-4 bg-white rounded-md shadow-md">
    <div className="flex flex-col items-center">
      <img
        src={userPictureLinkk}
        alt="Avatar"
        className="w-32 h-32 rounded-full mb-4"
      />
    </div>
  </div>
  </div>
  <div className="flex-1 p-4">
  <div className="p-6 bg-white rounded-md shadow-md" >
  <div className="flex flex-row items-center">
  <h3 className="text-1xl font-bold mb-3 mr-8 w-32">Name:</h3>
  <p className='text-black mb-3 mr-8 w-32'>{namee}</p>
</div>
<div className="flex flex-row items-center">
  <h3 className="text-1xl font-bold mb-4 mr-8 w-32">Email:</h3>
  <p className='text-black mb-3 mr-8 w-32'>{emaill}</p>

</div>
<div className="flex flex-row items-center">
  <h3 className="text-1xl  font-bold mb-4 mr-8 w-32">Password:</h3>
  <p className='text-black mb-3 mr-8 w-32'>*******</p>

</div>

    <button
    onClick={handleToggleEdit}
    className="border-2 border-orange-500 hover:border-orange-600 text-orange-500 hover:text-black px-4 py-2 rounded-md"
    >
      
    Edit
  </button>
  
  </div>
  </div>
</div>
    </section>
  );
}
}